/* eslint-disable react/prop-types */
import { Typography, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'

const TitleIdentification = () => {
  const theme = useTheme()
  const matchXs = useMediaQuery(theme.breakpoints.only('xs'))
  if (!!matchXs) {
    return <></>
  }
  return (
    <>
      <Typography
        sx={{
          marginTop: 5,
          fontSize: 14,
          textAlign: 'center',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        Identification and supporting documentation Rent then Buy needs to
        verify your identity.
      </Typography>
      <Typography
        variant="body2"
        sx={{
          fontSize: 14,
          marginTop: 1,
          textAlign: 'center',
          fontWeight: 'normal',
          color: 'secondary.contrastText',
          marginBottom: 3,
        }}
      >
        To help your application, add supporting documentation including your
        drivers license (Front and back), motor vehicle registration, Bank
        account, credit card and medicare card
      </Typography>
    </>
  )
}
export default TitleIdentification
