import { Grid } from '@mui/material'
import React, { FC, useEffect } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { AdminApprovalFlowProps } from '../../../../../store/slices/step/interface'
import { ApproveContractDto } from '../../../../../store/types'
import { DocumentTitles } from '../../../../../utils/values'
import { UploadComponent } from '../../../../components/Upload'
import TitleIdentification from '../../components/TitleIdentification'

export const Step6AdminManualFlow: FC<AdminApprovalFlowProps> = ({
  actions,
  state,
}) => {
  const dispatch = useDispatch()
  const { requestingGoTo } = state

  const {
    trigger,
    control,
    formState: { errors },
  } = useFormContext<ApproveContractDto>()
  useEffect(() => {
    if (!requestingGoTo) {
      return
    }
    trigger(['customer.documents']).then(canGoNext =>
      dispatch(actions.answerGoTo(canGoNext)),
    )
  }, [actions, dispatch, requestingGoTo, trigger])

  const { fields: documents } = useFieldArray({
    control,
    name: 'customer.documents',
  })

  return (
    <Grid container spacing={2}>
      <TitleIdentification />
      {documents.map((doc, index) => (
        <Grid item xs={12} md={6} lg={4} key={index}>
          <Controller
            name={
              `customer.documents.${index}.docUrl` as 'customer.documents.0.docUrl'
            }
            control={control}
            rules={{ required: 'Required' }}
            render={({ field: { value, onChange } }) => (
              <UploadComponent
                errors={errors}
                name={`customer.documents.${index}.docUrl`}
                title={DocumentTitles[doc.docType]}
                {...{ value, onChange }}
              />
            )}
          />
        </Grid>
      ))}
    </Grid>
  )
}
