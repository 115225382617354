import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import React from 'react'
import { useAppSelector } from '../../../../../store/hooks'
import { CustomerFlowProps } from '../../../../../store/slices/step/interface'
import { Step2CustomerFlow } from './Step2'

export const WrapperStripeCustomer = (props: CustomerFlowProps) => {
  const key = useAppSelector(state => state.adminManualFlowSteps.public_key)
  const STRIPE_PUBLIC_KEY =
    'pk_test_51KIoSGKvwz5eYS6jwSOx2qx2snJDWqosjH4kT18RfrGW7jyU0CDQxH2cNfwOqAPqlDU2yeYbSgouCrkYJmg3dpvK00AaMutxbB'
  return (
    <Elements stripe={loadStripe(key || STRIPE_PUBLIC_KEY)}>
      <Step2CustomerFlow {...props} />
    </Elements>
  )
}
