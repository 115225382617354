import { createSlice } from '@reduxjs/toolkit'
import { getTokens } from '../../../utils/localStorage'

export type AuthState = {
  authorized: boolean
}

const { accessToken } = getTokens()

const initialState: AuthState = {
  authorized: !!accessToken,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signIn: state => {
      state.authorized = true
    },
    signOut: state => {
      state.authorized = false
    },
  },
})

const { actions, reducer: authReducer } = authSlice

export { actions }

export const useAuthSlice = () => {
  // useInjectReducer({ key: name, reducer: authReducer })
  return { actions }
}

export default authReducer
