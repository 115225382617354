import {
  createTheme,
  ThemeProvider as ThemeMUIProvider,
} from '@mui/material/styles'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { ThemeProvider as OriginalThemeProvider } from 'styled-components'
import SFProDisplayBold from '../font/SFpro/SFPRODISPLAYBOLD.OTF'
import SFProDisplayMedium from '../font/SFpro/SFPRODISPLAYMEDIUM.OTF'
import SFProDisplay from '../font/SFpro/SFPRODISPLAYREGULAR.OTF'
import { useThemeSlice } from './slice'
import { selectTheme } from './slice/selectors'
import { themes } from './themes'

const theme = themes.light
const fontFamily = `
@font-face {
  font-family: "SF ProDisplay";
  src: local("SF ProDisplay"),  url(${SFProDisplay});
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "SF ProDisplay";
  src:local("SF ProDisplay"), url(${SFProDisplayBold});
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "SF ProDisplay";
  src: local("SF ProDisplay"),url(${SFProDisplayMedium});
  font-weight: 500;
  font-style: normal;
}
`
export const themeMaterial = createTheme({
  palette: {
    primary: {
      main: theme.primary,
      dark: theme.primaryDark,
      light: theme.primaryLight,
    },
    background: {
      // paper: theme.background,
      default: theme.background,
    },
    secondary: {
      main: theme.primary,
      light: theme.backgroundLightBlue,
      contrastText: theme.textSecondary,
    },
    divider: theme.border,
    text: {
      primary: theme.text,
      secondary: theme.colorOffBlue,
    },
    success: {
      main: theme.colorGreen,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 750,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: ['SF ProDisplay', 'Opens Sans', 'serif'].join(','),
    button: {
      textTransform: 'inherit',
      fontWeight: 500,
      boxShadow: 'unset',
    },
    h1: {
      fontSize: '3.125rem',
    },
    h2: {
      fontSize: '2rem',
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.Mui-error': {
            background: 'rgba(255, 0, 0, 0.1)',
            color: theme.textSecondary,
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          '&.MuiMenu-list': {
            paddingTop: 0,
            paddingBottom: 0,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: theme.colorOffBlue,
          padding: '12px 16px',
          borderBottom: '1px solid #DEE7FA',
          '&:last-child': {
            borderBottom: '1px solid white',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          marginRight: '10px',
          '&:hover': {
            // background: theme.primary,
          },
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          color: theme.text,
          backgroundColor: 'white',
          border: '1px solid black',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: theme.colorGreen,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: 'inherit',
          opacity: 0.8,
          marginLeft: 0,
          marginTop: '8px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          ':hover': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        input: {},
        root: {
          borderColor: theme.colorGreen,
          borderRadius: '7px',
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.colorGreen,
            borderWidth: '1px',
          },
        },
      },
    },

    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily,
          height: '-webkit-fill-available',
        },
      },
    },
  },
  shape: {
    borderRadius: 4,
  },
})

export const ThemeProvider = (props: { children: React.ReactChild }) => {
  useThemeSlice()
  const theme = useSelector(selectTheme)

  return (
    <OriginalThemeProvider theme={theme}>
      <ThemeMUIProvider theme={themeMaterial}>
        {React.Children.only(props.children)}
      </ThemeMUIProvider>
    </OriginalThemeProvider>
  )
}
