import {
  FirstPage as FirstPageIcon,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage as LastPageIcon,
} from '@mui/icons-material'
import {
  Box,
  IconButton,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import commonConstant from '../../../constants/common.constant'
import { useSearchParams } from '../../../hooks/useSearchParams'
import { formatToNumber } from '../../../utils/format.util'
import { StyledTableCell } from '../../pages/HomePage/components/styleTable'
import { LoadingIndicator } from '../LoadingIndicator'

export const useGetPaginationInfo = () => {
  const searchParams = useSearchParams()
  const page = useMemo(() => +searchParams.get('page') ?? 0, [searchParams])
  const itemsPerPage = useMemo(
    () => formatToNumber(+searchParams.get('itemsPerPage'), 10),
    [searchParams],
  )
  const search = useMemo(() => searchParams.get('search') || '', [searchParams])
  const status = useMemo(() => searchParams.get('status') || '', [searchParams])
  const type = useMemo(() => searchParams.get('type') || '', [searchParams])

  return { page, itemsPerPage, search, status, type }
}

const TablePaginationActions = ({ total }: { total: number }) => {
  const theme = useTheme()
  const history = useHistory()

  const searchParams = useSearchParams()
  const { page, itemsPerPage } = useGetPaginationInfo()

  const handlePageChange = (newPage: number) => {
    searchParams.set('page', `${newPage}`)
    history.replace({
      search: `?${searchParams.toString()}`,
    })
  }

  const handleFirstPageButtonClick = () => {
    handlePageChange(0)
  }

  const handleBackButtonClick = () => {
    handlePageChange(+page - 1)
  }

  const handleNextButtonClick = () => {
    handlePageChange(+page + 1)
  }

  const handleLastPageButtonClick = () => {
    handlePageChange(Math.max(0, Math.ceil(total / +itemsPerPage) - 1))
  }

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={+page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={+page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={+page >= Math.ceil(total / +itemsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={+page >= Math.ceil(total / +itemsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  )
}

type DashboardProps<T> = {
  data: T[]
  columns: ColumnDashboard[]
  renderValue: (id: keyof T, row: T) => React.ReactNode
  total: number
  isLoading?: boolean
  noDataText?: string
  showPagination?: boolean
}

const Dashboard = <T,>({
  data,
  columns,
  renderValue,
  total,
  isLoading,
  noDataText = 'No Data Available',
  showPagination = true,
}: DashboardProps<T>) => {
  const history = useHistory()

  const searchParams = useSearchParams()
  const page = useMemo(
    () => formatToNumber(searchParams.get('page'), 0),
    [searchParams],
  )
  const itemsPerPage = useMemo(
    () => formatToNumber(searchParams.get('itemsPerPage'), 10),
    [searchParams],
  )

  const rowPerPageOptions = commonConstant.ROW_PER_PAGE_OPTIONS
  return (
    <Paper
      sx={{
        boxShadow: '0px 0px 5px rgba(83, 95, 120, 0.1)',
      }}
    >
      <TableContainer
        sx={{
          backgroundColor: 'white',
          position: 'relative',
        }}
      >
        {isLoading ? (
          <LoadingContainer>
            <LoadingIndicator />
          </LoadingContainer>
        ) : (
          <Table
            sx={{
              minWidth: 1080,
            }}
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead>
              <TableRow>
                {columns.map((column, index: number) => (
                  <StyledTableCell
                    key={index}
                    style={{
                      ...column.headerStyle,
                    }}
                    onClick={column?.onClick}
                  >
                    {column.label}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.length === 0 && !isLoading && (
                <TableRow role="checkbox" tabIndex={-1}>
                  <StyledTableCell
                    colSpan={columns.length ?? 1}
                    sx={{ textAlign: 'center' }}
                  >
                    {noDataText}
                  </StyledTableCell>
                </TableRow>
              )}
              {data?.length > 0 &&
                renderValue &&
                data?.map((row: T, indexRow: number) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={indexRow}
                    >
                      {columns.map((column, indexColumn: number) => {
                        return (
                          <TableCell
                            key={indexColumn}
                            sx={{
                              fontSize: '14px',
                              fontWeight: 500,
                              '& p': {
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                              },
                              '& p:hover': {
                                textOverflow: 'clip',
                                whiteSpace: 'normal',
                                wordBreak: 'break-all',
                              },
                              ...column.cellStyle,
                            }}
                          >
                            {renderValue(column.id as keyof T, row)}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      {showPagination ? (
        <TablePagination
          component="div"
          rowsPerPageOptions={rowPerPageOptions}
          count={total}
          rowsPerPage={+itemsPerPage}
          page={+page}
          onPageChange={(_, newPage) => {
            searchParams.set('page', `${newPage}`)
            history.replace({
              search: `?${searchParams.toString()}`,
            })
          }}
          onRowsPerPageChange={e => {
            searchParams.set('page', '0')
            searchParams.set('itemsPerPage', `${parseInt(e.target.value, 10)}`)
            history.replace({
              search: `?${searchParams.toString()}`,
            })
          }}
          ActionsComponent={() => <TablePaginationActions total={total} />}
        />
      ) : null}
    </Paper>
  )
}

const LoadingContainer = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
`

export default Dashboard
