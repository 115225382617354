import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

interface ReadOnlyURLSearchParams extends URLSearchParams {
  append: never
  delete: never
  sort: never
}

export function useSearchParams() {
  const { search } = useLocation()

  return useMemo(
    () => new URLSearchParams(search) as ReadOnlyURLSearchParams,
    [search],
  )
}
