import { CreateDocumentDto } from '../store/types'

export const DocumentTitles: Record<CreateDocumentDto['docType'], string> = {
  DRIVER_LICENSE_FRONT: 'Driver License Front',
  DRIVER_LICENSE_BACK: 'Driver License Back',
  BANK_ACCOUNT: 'Bank Account',
  CREDIT_CARD: 'Credit Card',
  MEDICARE_CARD: 'Medicare Card',
  MOTOR_VEHICLE_REGO: 'Motor Vehice Rego',
}
