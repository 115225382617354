import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  styled,
  Typography,
} from '@mui/material'
import React, { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../../../store/hooks'
import { AdminManualFlowProps } from '../../../../../store/slices/step/interface'
import { ManualContractDto } from '../../../../../store/types'

export const Step8AdminManualFlow: FC<AdminManualFlowProps> = ({ actions }) => {
  const dispatch = useDispatch()
  const { watch } = useFormContext<ManualContractDto>()
  const checkbox =
    useAppSelector(state => state.adminManualFlowSteps?.confirmation) || {}
  const customOrder = watch('customOrder')

  const handleChangeTerm = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      actions.updateConfirmation({
        term: event.target.checked,
        errorTerm: !event.target.checked,
      }),
    )
  }
  const handleChangeReturnable = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    dispatch(
      actions.updateConfirmation({
        returnable: event.target.checked,
        errorReturnable: !event.target.checked,
      }),
    )
  }

  return (
    <Grid container spacing={2} sx={{ mt: 0 }}>
      <Grid item xs={12}>
        <Box
          sx={{
            ...boxCheckboxSx,
            borderColor: checkbox.errorTerm ? 'primary.main' : 'transparent',
          }}
        >
          <FormControl required error={!checkbox?.term}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    // defaultChecked
                    checked={checkbox?.term}
                    onChange={handleChangeTerm}
                    name="term"
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }}
                  />
                }
                label={
                  <Typography variant="body2" sx={{ ml: 1 }}>
                    I have read and understood and agreed to the &nbsp;
                    <SpanTerm
                      target="_blank"
                      href="https://rentthenbuy.au/terms-conditions/"
                    >
                      Terms &amp; Conditions
                    </SpanTerm>
                  </Typography>
                }
              />
            </FormGroup>
          </FormControl>
        </Box>
      </Grid>
      {!!customOrder && (
        <Grid item xs={12}>
          <Box
            sx={{
              ...boxCheckboxSx,
              borderColor: checkbox.errorReturnable
                ? 'primary.main'
                : 'transparent',
            }}
          >
            <FormControl required error={!checkbox?.errorReturnable}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      // defaultChecked
                      checked={checkbox?.returnable}
                      onChange={handleChangeReturnable}
                      name="term"
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }}
                    />
                  }
                  label={
                    <Typography variant="body2" sx={{ ml: 1 }}>
                      This trailer is a special custom order and as per the
                      &nbsp;
                      <SpanTerm
                        target="_blank"
                        href="https://rentthenbuy.au/terms-conditions/"
                      >
                        Terms &amp; Conditions
                      </SpanTerm>{' '}
                      I agree it is not returnable
                    </Typography>
                  }
                />
              </FormGroup>
            </FormControl>
          </Box>
        </Grid>
      )}
    </Grid>
  )
}
export const boxCheckboxSx = {
  backgroundColor: '#F0F3FC',
  px: 4,
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'transparent',
  minWidth: '100%',
}
export const SpanTerm = styled('a')(({ theme }) => ({
  color: theme.palette.primary.main,
  cursor: 'poiter',
  display: 'inline-block',
  textDecoration: 'none',
}))
