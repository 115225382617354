import { Grid, Typography } from '@mui/material'
import React, { FC, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { CustomerFlowProps } from '../../../../../store/slices/step/interface'
import { FulfillCustomerDto } from '../../../../../store/types'
import {
  defaultListReference,
  ListReferenceType,
} from '../../admin-approval/steps/Step5'
import { ControlledTextField } from '../../components/ControlledInput'
import SelectState from '../../components/SelectState'
import { defaultRulesOptional } from '../../defaultRules'

export const Step3CustomerFlow: FC<CustomerFlowProps> = ({
  actions,
  state,
}) => {
  const dispatch = useDispatch()
  const { requestingGoTo } = state
  const { trigger, watch } = useFormContext<FulfillCustomerDto>()
  const [listReference, setListReference] = React.useState<ListReferenceType[]>(
    [...defaultListReference],
  )
  const setRequireReference = ({ name, isRequired }) => {
    const newListReference = [...listReference]
    const index = newListReference.findIndex(i => i.name === name)
    newListReference[index].required = isRequired
    setListReference(newListReference)
  }
  useEffect(() => {
    if (!requestingGoTo) {
      return
    }
    const listFieldOptional: any[] = [listReference[1], listReference[3]]
    listFieldOptional.map(field => {
      const everyNameField = field.field.map(i => {
        return `${field.name.split('customer.')[1]}.${i.name}`
      })
      const valueEveryField = watch(everyNameField)

      //if all field is fill except homeAddress
      const firstCondition = valueEveryField.every((item, index) =>
        index === 5 ? true : !!item,
      )

      //if no field is fill
      const secondCondition = valueEveryField.every(item => !item)
      const totalCondition = firstCondition || secondCondition
      if (!totalCondition) {
        toast.error(`Please fill all of ${field.title}`)
      }

      setRequireReference({ name: field.name, isRequired: !totalCondition })
      return !totalCondition
    })
    trigger(['references'])
      .then(canGoNext => dispatch(actions.answerGoTo(canGoNext)))
      .catch(e => {
        console.error(e)
        dispatch(actions.answerGoTo(false))
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions, dispatch, requestingGoTo, trigger, watch])

  // const { fields: references } = useFieldArray({ control, name: 'references' })

  return (
    <>
      <Grid container spacing={2} sx={{ p: 0, pt: 3 }}>
        {listReference.map((mainReference, indexMainReference) => {
          return (
            <>
              <Grid item xs={12} sx={{ mb: -1 }}>
                <Typography>{mainReference.title}</Typography>
              </Grid>
              {mainReference.field.map((reference, index) => {
                const {
                  md = 12,
                  name,
                  rules = defaultRulesOptional,
                  ...restReference
                } = reference
                const fieldName = `${
                  mainReference.name.split('customer.')[1]
                }.${name}`
                if (name === 'state') {
                  return (
                    <Grid item xs={12} md={md} sx={{ pt: 0 }} key={index}>
                      <SelectState
                        name={fieldName}
                        required={mainReference?.required ?? false}
                      />
                    </Grid>
                  )
                }
                return (
                  <Grid item xs={12} md={md} sx={{ pt: 0 }} key={index}>
                    <ControlledTextField
                      name={fieldName}
                      autoFocus={indexMainReference === 0 && index === 0}
                      rules={
                        mainReference?.required &&
                        reference.name !== 'homeAddress'
                          ? { required: mainReference?.required, ...rules }
                          : { required: false, ...rules }
                      }
                      {...restReference}
                    />
                  </Grid>
                )
              })}
            </>
          )
        })}
      </Grid>
    </>
  )
}
