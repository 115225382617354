import React from 'react'
import NumberFormat from 'react-number-format'

const TargetRateInput = React.forwardRef(function TargetRateInput(
  props: any,
  ref,
) {
  const { value, onChange, onBlur, ...rest } = props

  return (
    <NumberFormat
      getInputRef={ref}
      value={value * 100}
      onValueChange={({ value }) => {
        onChange(value ? +value / 100 : 0)
      }}
      onBlur={onBlur}
      thousandSeparator
      suffix="%"
      decimalScale={2}
      allowNegative={false}
      allowLeadingZeros={false}
      {...rest}
    />
  )
})

export default TargetRateInput
