import { AxiosResponse } from 'axios'
import { MutationFunction, QueryFunction } from 'react-query'
import {
  CustomerContractResponse,
  CustomerEntity,
  FulfillCustomerDto,
  IdParam,
  TokenContractParam,
} from '../../store/types'
import { buildQueryKeys } from '../queryUltils'
import { request } from '../request'
import { ContractsResponses } from './contracts'

export const CUSTOMER_URL = '/customers'

export type CustomerResponses = {
  getTokenForCustomerFlow: AxiosResponse<CustomerContractResponse>
  updateCustomerContract: AxiosResponse<CustomerEntity>
}

type CustomerEndpoints = {
  getTokenForCustomerFlow: QueryFunction<
    CustomerResponses['getTokenForCustomerFlow'],
    [string, TokenContractParam]
  >
  updateCustomerContract: MutationFunction<
    CustomerResponses['updateCustomerContract'],
    FulfillCustomerDto & TokenContractParam
  >
  updateRiskScore: MutationFunction<ContractsResponses['getById'], IdParam>
}

export const Customers: CustomerEndpoints = {
  getTokenForCustomerFlow: ({ queryKey: [, token] }) =>
    request.get(`${CUSTOMER_URL}/token-contract`, { params: token }),
  updateCustomerContract: ({ token, ...customerDto }) => {
    delete customerDto?.bankAccountInfo.paymentMethodId
    return request.put(`${CUSTOMER_URL}?token=${token}`, customerDto, {
      timeout: 15000,
    })
  },
  updateRiskScore: ({ id }) =>
    request.put(`${CUSTOMER_URL}/update-risk-score/${id}`, null, {
      timeout: 0,
    }),
}

export const CustomersQueryKeys = buildQueryKeys(Customers)
