import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import InfoIcon from '@mui/icons-material/Info'
import { Skeleton, styled, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone'
import { FieldErrors, get } from 'react-hook-form'
import { useMutation } from 'react-query'
import { Link } from 'react-router-dom'
import { toast, ToastPosition } from 'react-toastify'
import { API, ErrorResponse } from '../../../apis'
import {
  GetPresignUrlDto,
  MediaResponses,
  UploadDto,
} from '../../../apis/endpoints/media'
import AddIcon from './add.svg'
import DocIcon from './doc.svg'
import {
  Container,
  DeleteButton,
  Description,
  InfoBar,
  LinkButton,
  Preview,
  Thumbnail,
  Title,
} from './style'

const ASSETS_HOST = process.env.REACT_APP_ASSETS_HOST

export const UploadComponent: React.FC<{
  title: string
  name: string
  errors: FieldErrors
  onChange?: (value: string | null) => void
  value?: string
}> = ({ title, onChange, value, name, errors, ...inputProp }) => {
  const theme = useTheme()
  const matchXs: boolean = useMediaQuery(theme.breakpoints.only('xs'))
  const position: ToastPosition = matchXs ? 'top-center' : 'top-right'
  const [previewSrc, setPreviewSrc] = React.useState<string>(
    value?.endsWith('.pdf') ? DocIcon : value,
  )
  React.useEffect(() => {
    setPreviewSrc(
      value?.endsWith('.pdf')
        ? DocIcon
        : value && value.includes('?')
        ? value
        : previewSrc,
    )
  }, [value, previewSrc])
  const onError = error => {
    const errorMessage = error?.response?.data?.message
    if (!error?.response?.data?.message) {
      toast.error('Unsuccessful file upload', {
        position,
      })
      setPreviewSrc(null)
    } else {
      toast.error(errorMessage, {
        position,
      })
    }
  }
  const { mutate: getSignedUrl } = useMutation<
    MediaResponses['upload'],
    ErrorResponse,
    GetPresignUrlDto
  >(API.Medias.getSignedUrl, {
    onSuccess: data => {
      // @ts-ignore
      setPreviewSrc(data.data.url)
      // @ts-ignore
      onChange(data.data.url)
    },
    onError,
  })

  const { mutate: upload, isLoading: loadingUpload } = useMutation<
    MediaResponses['upload'],
    ErrorResponse,
    UploadDto
  >(API.Medias.upload, {
    onSuccess: (_, { pathname, file }) => {
      // @ts-ignore
      if (file.type === 'application/pdf') {
        setPreviewSrc(DocIcon)
      } else {
        // setPreviewSrc(`${ASSETS_HOST}${pathname}`)
        // @ts-ignore
        getSignedUrl({ fileName: pathname })
      }
      onChange(`${ASSETS_HOST}${pathname}`)
    },
    onError,
  })

  const { mutate: getUploadUrl, isLoading } = useMutation<
    MediaResponses['getUploadUrl'],
    ErrorResponse,
    GetPresignUrlDto
  >(API.Medias.getUploadUrl, {
    onSuccess: ({ data: { url, pathname } }, { file }) => {
      upload({ url, pathname, file })
    },
    onError,
  })
  const onDropRejected: (
    fileRejections: FileRejection[],
    event: DropEvent,
  ) => void = fileRejections => {
    fileRejections.map(error => {
      return error.errors.map(errorMess => {
        return toast.error(errorMess.message, { position })
      })
    })
  }
  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: 'application/pdf, image/png, image/jpg, image/jpeg',
    multiple: false,
    onDrop: ([file]) => {
      if (!file) {
        return
      }

      if (file.type === 'application/pdf') {
        setPreviewSrc(DocIcon)
      } else {
        setPreviewSrc(URL.createObjectURL(file))
      }
      const uploadUrl: GetPresignUrlDto = {
        type: 'DOCUMENT_INFO',
        fileName: file.name.split(' ').join(''),
        contentType: file.type,
        file,
      }
      getUploadUrl(uploadUrl)
    },
    onDropRejected,
  })

  const onDelete = () => {
    setPreviewSrc(undefined)
    onChange(null)
  }
  return (
    <Container>
      {previewSrc && (
        <DeleteButton aria-label="delete" onClick={onDelete}>
          <DeleteOutlineIcon />
        </DeleteButton>
      )}
      {previewSrc ? (
        <Link
          to={{ pathname: `${value}` }}
          replace
          target="_blank"
          rel="noopener noreferrer"
        >
          <Preview source={previewSrc} matchXs={matchXs} />
        </Link>
      ) : (
        <>
          <Thumbnail {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} {...inputProp} />
            <img src={AddIcon} alt="add" />
          </Thumbnail>
        </>
      )}
      <InfoBar>
        {isLoading && loadingUpload ? (
          <>
            <Skeleton variant="text" width="100%" />
            <Skeleton variant="text" width="85%" />
          </>
        ) : (
          <>
            <Title>{title}</Title>
            {onChange &&
              (previewSrc ? (
                <div {...getRootProps({ className: 'dropzone' })}>
                  <input {...getInputProps()} {...inputProp} />
                  <LinkButton>Change</LinkButton>
                </div>
              ) : (
                <BoxFlex>
                  <Description>PDF, PNG, JPG </Description>
                  {(fileRejections[0] || get(errors, name)) && (
                    <InfoIconStyle />
                  )}
                </BoxFlex>
              ))}
          </>
        )}
      </InfoBar>
    </Container>
  )
}
export const BoxFlex = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
}))
export const InfoIconStyle = styled(InfoIcon)(({ theme }) => ({
  color: theme.palette.primary.dark,
}))
