import { TextField, TextFieldProps } from '@mui/material'
import React, { FC } from 'react'
import {
  Controller,
  ControllerProps,
  get,
  useFormContext,
} from 'react-hook-form'

type Props = TextFieldProps & {
  name: string
  rules?: ControllerProps['rules']
}

export const ControlledTextField: FC<Props> = ({
  name,
  rules,
  ...textFieldProps
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()
  const validStreed = () => {
    let val = {}
    if (['customer.streetAddress', 'streetAddress'].includes(name)) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const reg = /^(\d[0-9]{0}).+\s*([A-Za-z])/
      val = {
        validate: v => {
          return reg.test((v || '').trim()) ? true : 'ex: 10 street'
        },
      }
    }
    return val
  }
  const error = get(errors, name)
  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={{
          ...rules,
          ...validStreed(),
        }}
        render={({ field: { value, onChange, onBlur } }) => (
          <TextField
            {...{
              ...textFieldProps,
              value: value ?? '',
              onChange,
              onBlur,
            }}
            error={!!error}
            margin="normal"
            fullWidth
            name={name}
            autoComplete={name}
          />
        )}
      />
      {/* <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <FormHelperText component="div">{message}</FormHelperText>
        )}
      /> */}
    </>
  )
}
