/* eslint-disable react/prop-types */
import { Typography, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'

const TitleCreditScore = (props: { name: string }) => {
  const theme = useTheme()
  const matchXs = useMediaQuery(theme.breakpoints.only('xs'))
  if (!!matchXs) {
    return <></>
  }
  return (
    <Typography
      sx={{
        marginTop: 5,
        fontSize: 14,
        textAlign: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%',
      }}
    >
      This is the credit rating for “{props.name}”
    </Typography>
  )
}
export default TitleCreditScore
