import { TextFieldProps } from '@mui/material'
import React, { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { StyleText } from '../../DetailContract/styleDetail'

type Props = TextFieldProps & {
  name: string
}

const ReadOnlyInput: FC<Props> = ({ name }) => {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange, onBlur } }) => (
        <StyleText
          {...{
            value: value.trim() ?? '',
            onChange,
            onBlur,
          }}
          margin="normal"
          fullWidth
          name={name}
          autoComplete={name}
          label="Email address"
        />
      )}
    />
  )
}

export default ReadOnlyInput
