import { AxiosResponse } from 'axios'
import { MutationFunction, QueryFunction } from 'react-query'
import {
  AddNoteContractDto,
  ApproveContractDto,
  BuyoutSchedule,
  CalculationBuyoutEntity,
  ContractCollection,
  ContractEntity,
  ContractResponse,
  CreateContractDto,
  CustomerEntity,
  EstimateBuyout,
  GetContractsDto,
  GetPaymentsContractDto,
  IdParam,
  ManualContractDto,
  ManualHalfContractDto,
  NoteContractEntity,
  NotesContractCollection,
  PaginationQuery,
  PaymentsContractCollection,
  TokenContractParam,
  UpdateInterestRate,
} from '../../store/types'
import { buildQueryKeys } from '../queryUltils'
import { request } from '../request'
import { EstimateBuyoutDto } from './../../store/types/models'
import { CUSTOMER_URL } from './customer'

const BASE_URL = '/contracts'

export type ContractsResponses = {
  list: AxiosResponse<ContractCollection>
  getNotesContract: AxiosResponse<NotesContractCollection>
  getPaymentsContract: AxiosResponse<PaymentsContractCollection>
  getById: AxiosResponse<ContractResponse>
  updateAdminApproval: AxiosResponse<ContractEntity>
  createAdminManual: AxiosResponse<ContractEntity>
  createAdminFlowContract: AxiosResponse<ContractEntity>
  updateAdminFlow: AxiosResponse<ContractEntity>
  getTokenForCustomerFlow: AxiosResponse<CustomerEntity>
  activatePayment: AxiosResponse<ContractEntity>
  retryPayment: AxiosResponse<ContractEntity>
  buyout: AxiosResponse<BuyoutSchedule>
  pausePayment: AxiosResponse<ContractEntity>
  editManual: AxiosResponse<ContractEntity>
  exportPdf: AxiosResponse
  addNoteContract: AxiosResponse<NoteContractEntity>
  getCalculationBuyout: AxiosResponse<CalculationBuyoutEntity>
  commencePaymentSchedule: AxiosResponse<void>
  cancelContract: AxiosResponse<void>
  estimateBuyout: AxiosResponse<EstimateBuyout[]>
}

type ContractsEndpoints = {
  list: QueryFunction<ContractsResponses['list'], [string, GetContractsDto]>
  getNotesContract: QueryFunction<
    ContractsResponses['getNotesContract'],
    [string, PaginationQuery & IdParam]
  >
  addNoteContract: MutationFunction<
    ContractsResponses['addNoteContract'],
    AddNoteContractDto & IdParam
  >
  getPaymentsContract: QueryFunction<
    ContractsResponses['getPaymentsContract'],
    [string, GetPaymentsContractDto & IdParam]
  >
  getById: QueryFunction<ContractsResponses['getById'], [string, IdParam]>
  updateAdminApproval: MutationFunction<
    ContractsResponses['updateAdminApproval'],
    ApproveContractDto & IdParam
  >
  createAdminManual: MutationFunction<
    ContractsResponses['createAdminManual'],
    ManualContractDto
  >
  createAdminFlowContract: MutationFunction<
    ContractsResponses['createAdminFlowContract'],
    CreateContractDto
  >
  updateAdminFlow: MutationFunction<
    ContractsResponses['updateAdminFlow'],
    CreateContractDto & IdParam
  >
  updateAdminFlowSendMail: MutationFunction<
    ContractsResponses['updateAdminFlow'],
    CreateContractDto & IdParam
  >
  getTokenForCustomerFlow: QueryFunction<
    ContractsResponses['getTokenForCustomerFlow'],
    [string, TokenContractParam]
  >
  activatePayment: MutationFunction<
    ContractsResponses['activatePayment'],
    IdParam
  >
  retryPayment: MutationFunction<
    ContractsResponses['retryPayment'],
    IdParam & { paymentId: string }
  >
  buyout: MutationFunction<
    ContractsResponses['buyout'],
    IdParam & { paymentMethodId?: string }
  >
  pausePayment: MutationFunction<ContractsResponses['pausePayment'], IdParam>
  editManual: MutationFunction<
    ContractsResponses['editManual'],
    IdParam & ManualContractDto
  >
  exportPdf: QueryFunction<ContractsResponses['exportPdf'], [string, IdParam]>

  updateHalfManual: MutationFunction<
    ContractsResponses['updateAdminFlow'],
    ManualHalfContractDto & IdParam
  >
  createAndApprove: MutationFunction<
    ContractsResponses['createAdminManual'],
    ManualContractDto
  >
  getCalculationBuyout: QueryFunction<
    ContractsResponses['getCalculationBuyout'],
    [string, IdParam & UpdateInterestRate]
  >
  getEstimateBuyout: QueryFunction<
    ContractsResponses['estimateBuyout'],
    [string, EstimateBuyoutDto]
  >
  commencePaymentSchedule: MutationFunction<
    ContractsResponses['commencePaymentSchedule'],
    IdParam & { startDate?: number }
  >
  cancelContract: MutationFunction<
    ContractsResponses['cancelContract'],
    IdParam & { paymentMethodId?: string; isCashRefund?: boolean }
  >
}

export const Contracts: ContractsEndpoints = {
  getCalculationBuyout: ({ queryKey: [, { id, interestRate }] }) =>
    request.post(`${BASE_URL}/${id}/calculate-buyout`, { interestRate }),
  getEstimateBuyout: ({ queryKey: [, payload] }) =>
    request.post(`${BASE_URL}/estimate-buyout`, payload),
  list: ({ queryKey: [, params] }) => request.get(`${BASE_URL}`, { params }),
  getNotesContract: ({ queryKey: [, { id, ...rest }] }) =>
    request.get(`${BASE_URL}/${id}/notes`, { params: rest }),
  addNoteContract: ({ id, ...payload }) =>
    request.post(`${BASE_URL}/${id}/notes`, payload),
  getPaymentsContract: ({ queryKey: [, { id, ...rest }] }) =>
    request.get(`${BASE_URL}/${id}/payments`, { params: rest }),
  getById: ({ queryKey: [, { id }] }) => request.get(`${BASE_URL}/${id}`),
  updateAdminApproval: ({ id, ...payload }) => {
    if (payload?.customer?.bankAccountInfo) {
      //@ts-ignore
      delete payload?.customer?.bankAccountInfo?.paymentMethodId
    }
    return request.put(`${BASE_URL}/${id}/approve`, payload)
  },
  createAdminFlowContract: payload => request.post(`${BASE_URL}`, payload),
  updateAdminFlow: ({ id, ...payload }) =>
    request.put(`${BASE_URL}/${id}`, payload),
  commencePaymentSchedule: ({ id, startDate }) =>
    request.put(`${BASE_URL}/${id}/activate-payment?startDate=${startDate}`),
  updateAdminFlowSendMail: ({ id, ...payload }) => {
    payload.isSendEmailNotification = true
    return request.put(`${BASE_URL}/${id}`, payload)
  },
  getTokenForCustomerFlow: ({ queryKey: [, token] }) =>
    request.get(`${CUSTOMER_URL}/token-contract`, { params: token }),
  createAdminManual: payload => {
    if (payload?.customer?.creditCardInfo) {
      //@ts-ignore
      delete payload?.customer?.creditCardInfo?.expiry
    }
    if (payload?.customer?.bankAccountInfo) {
      //@ts-ignore
      delete payload?.customer?.bankAccountInfo?.paymentMethodId
    }
    return request.post(`${BASE_URL}/manual`, payload, {
      timeout: 10000,
    })
  },
  activatePayment: ({ id }) =>
    request.put(`${BASE_URL}/${id}/activate-payment`),
  cancelContract: ({ id, paymentMethodId, isCashRefund }) =>
    request.put(`${BASE_URL}/${id}/cancel`, {
      ...(paymentMethodId ? { paymentMethodId } : {}),
      ...(isCashRefund ? { isCashRefund } : {}),
    }),
  retryPayment: ({ id, paymentId }) =>
    request.put(`${BASE_URL}/${id}/retry-payment/${paymentId}`),
  buyout: ({ id, ...payload }) =>
    request.put(`${BASE_URL}/${id}/buyout`, payload),
  pausePayment: ({ id }) => request.put(`${BASE_URL}/${id}/pause-payment`),
  editManual: ({ id, ...updateManualDto }) => {
    if (updateManualDto?.customer?.creditCardInfo) {
      //@ts-ignore
      delete updateManualDto?.customer?.creditCardInfo?.expiry
    }
    if (updateManualDto?.customer.bankAccountInfo) {
      delete updateManualDto?.customer.bankAccountInfo?.paymentMethodId
    }
    return request.put(`${BASE_URL}/${id}/manual`, updateManualDto, {
      timeout: 10000,
    })
  },
  exportPdf: ({ queryKey: [, { id }] }) =>
    request.get(`${BASE_URL}/${id}/export-pdf`, {
      responseType: 'blob',
      timeout: 100000,
    }),
  updateHalfManual: ({ id, ...updateDto }) =>
    request.put(`${BASE_URL}/half/manual/${id}`, updateDto),
  createAndApprove: payload => {
    if (payload?.customer?.creditCardInfo) {
      //@ts-ignore
      delete payload?.customer?.creditCardInfo?.expiry
    }
    if (payload?.customer?.bankAccountInfo) {
      //@ts-ignore
      delete payload?.customer?.bankAccountInfo?.paymentMethodId
    }
    return request.post(`${BASE_URL}/manual-approve/`, payload, {
      timeout: 10000,
    })
  },
}

export const ContractsQueryKeys = buildQueryKeys(Contracts)
