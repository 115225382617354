import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from '@mui/material'
import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../../../store/hooks'
import { CustomerFlowProps } from '../../../../../store/slices/step/interface'
import { boxCheckboxSx, SpanTerm } from '../../admin-manual/steps/Step8'

export const Step6CustomerFlow: FC<CustomerFlowProps> = ({
  actions,
  state,
}) => {
  const dispatch = useDispatch()
  const customOrder =
    useAppSelector(state => state.customerFlowSteps?.contract?.customOrder) ||
    false
  const checkbox = state.confirmation

  const handleChangeTerm = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      actions.updateConfirmation({
        term: event.target.checked,
        errorTerm: !event.target.checked,
      }),
    )
  }
  const handleChangeReturnable = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    dispatch(
      actions.updateConfirmation({
        returnable: event.target.checked,
        errorReturnable: !event.target.checked,
      }),
    )
  }

  return (
    <Grid container spacing={2} sx={{ mt: 0 }}>
      <Grid item xs={12}>
        <Box
          sx={{
            ...boxCheckboxSx,
            borderColor: checkbox.errorTerm ? 'primary.main' : 'transparent',
          }}
        >
          <FormControl required error={!checkbox.term}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    // defaultChecked
                    checked={checkbox.term}
                    onChange={handleChangeTerm}
                    name="term"
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }}
                  />
                }
                label={
                  <Typography variant="body2" sx={{ ml: 1 }}>
                    I have read and understood and agreed to the &nbsp;
                    <SpanTerm
                      target="_blank"
                      href="https://rentthenbuy.au/terms-conditions/"
                    >
                      Terms &amp; Conditions
                    </SpanTerm>
                  </Typography>
                }
              />
            </FormGroup>
          </FormControl>
        </Box>
      </Grid>
      {!!customOrder && (
        <Grid item xs={12}>
          <Box
            sx={{
              ...boxCheckboxSx,
              borderColor: checkbox.errorReturnable
                ? 'primary.main'
                : 'transparent',
            }}
          >
            <FormControl required error={!checkbox.returnable}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      // defaultChecked
                      checked={checkbox.returnable}
                      onChange={handleChangeReturnable}
                      name="term"
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }}
                    />
                  }
                  label={
                    <Typography variant="body2" sx={{ ml: 1 }}>
                      This trailer is a special custom order and as per the
                      &nbsp;
                      <SpanTerm
                        target="_blank"
                        href="https://rentthenbuy.au/terms-conditions/"
                      >
                        Terms &amp; Conditions
                      </SpanTerm>{' '}
                      I agree it is not returnable
                    </Typography>
                  }
                />
              </FormGroup>
            </FormControl>
          </Box>
        </Grid>
      )}
    </Grid>
  )
}
