import { Grid } from '@mui/material'
import React, { FC, useCallback, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { request } from 'src/apis/request'
import { CustomerFlowProps } from '../../../../../store/slices/step/interface'
import { FulfillCustomerDto } from '../../../../../store/types'
import { ControlledTextField } from '../../components/ControlledInput'
import SelectState from '../../components/SelectState'
import {
  abnBusinessRule,
  defaultEmailRulesOptional,
  defaultPostalCodeInputOptional,
  defaultRulesOptional,
  phoneRuleOption,
} from '../../defaultRules'

export const Step1CustomerFlow: FC<CustomerFlowProps> = ({
  actions,
  state,
}) => {
  const dispatch = useDispatch()
  const { requestingGoTo } = state

  const { trigger } = useFormContext<FulfillCustomerDto>()

  const handleGotoNext = useCallback(async () => {
    try {
      const res = await request.get(`/payment/credential`)
      dispatch(actions.setPublicKey(res.data.publicKey))
    } catch (error) {
      const position = 'top-right'
      toast.error('Server Error. Please try again later.', { position })
    } finally {
      dispatch(actions.answerGoTo(true))
    }
  }, [actions, dispatch])

  useEffect(() => {
    if (!requestingGoTo) {
      return
    }

    trigger([
      'businessName',
      'businessABN',
      'businessPhone',
      'businessEmail',
      'businessStreetAddress',
      'businessAddress',
      'businessCity',
      'businessState',
      'businessPostCode',
    ]).then(() => handleGotoNext())
  }, [actions, dispatch, requestingGoTo, trigger, handleGotoNext])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <ControlledTextField
          name="businessName"
          rules={defaultRulesOptional}
          label="Business name"
          autoFocus
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <ControlledTextField name="businessABN" {...abnBusinessRule} />
      </Grid>
      <Grid item xs={12} md={6}>
        <ControlledTextField name="businessPhone" {...phoneRuleOption} />
      </Grid>
      <Grid item xs={12} md={6}>
        <ControlledTextField
          name="businessEmail"
          rules={defaultEmailRulesOptional}
          label="Email"
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField
          name="businessStreetAddress"
          rules={defaultRulesOptional}
          label="Street address"
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField
          name="businessAddress"
          rules={defaultRulesOptional}
          label="Apt, suite, etc (Optional)"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <ControlledTextField
          name="businessCity"
          rules={defaultRulesOptional}
          label="City"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <SelectState name="businessState" required={false} />
      </Grid>
      <Grid item xs={12} md={4}>
        <ControlledTextField
          name="businessPostCode"
          {...defaultPostalCodeInputOptional}
        />
      </Grid>
    </Grid>
  )
}
