import { styled } from '@mui/material'
import { Button } from '../../../components/Button'

export const ExitButton = styled(Button)(() => ({
  backgroundColor: '#ececec',
  color: '#171717',
  borderRadius: '100px',
  height: '31px',
  padding: '0px 42px',
  marginRight: '8px',
}))
export const SaveAndExitButton = styled(Button)(({ theme }) => ({
  borderRadius: '100px',
  height: '31px',
  padding: '0px 42px',

  backgroundColor: theme.palette.success.main,
  color: 'white',
}))

export const BoxExitButton = styled('div')(() => ({
  position: 'absolute',
  top: '24px',
  right: '48px',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
}))
