import { Box } from '@mui/material'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import * as React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { toast } from 'react-toastify'
import { API, ErrorResponse } from '../../../../apis'
import {
  ContractsQueryKeys,
  ContractsResponses,
} from '../../../../apis/endpoints/contracts'
import { useAppSelector } from '../../../../store/hooks'
import {
  createRenderAdminManualFlow,
  isBankAccountDirty,
  isChangeFormDirty,
  isCreditCardDirty,
  setFormValues,
} from '../../../../store/slices/step/values'
import {
  ApproveContractDto,
  ExpiryAdminManualDto,
  IdParam,
  ManualContractDto,
  ManualHalfContractDto,
} from '../../../../store/types'
import {
  clearHalfContract,
  getHalfContract,
  setHalfContract,
} from '../../../../utils/localStorage'
import { routerAdminFlow } from '../../../../utils/routerPath'
import { Button } from '../../../components/Button'
import StepBar from '../../../components/StepBar'
import TitleMenu from '../../../components/TitleMenu'
import HalfLayout from '../../../layout/HalfLayout'
import { response2AdminApproval } from '../admin-approval/convertResponse'
import EditModal from '../components/EditModal'
import {
  BoxExitButton,
  ExitButton,
  SaveAndExitButton,
} from '../components/ExitButton'
import ExitEditModal from '../components/ExitEditModal'
import ExitModal from '../components/ExitModal'
import { BottomBar } from '../components/Styles'
import SuccessModal from '../components/SuccessModal'
import { defaultValuesAdminManual } from '../defaultValue'
import { dummieManualContractDto } from '../dummieValue'
import { useStepAdminManualFlowSlice } from './../../../../store/slices/step/admin-manual'
import {
  clearEmptyBusinessDetails,
  convertCreditCard,
  isNotEmptyReference,
  onError,
  onExitBrowser,
} from './../../../../utils/helperFunction'

const { useEffect, useState } = React

const AdminManualFlow = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { actions } = useStepAdminManualFlowSlice()

  const [isPending, setPending] = useState(false)
  const [openModal, setOpenModal] = useState({
    exit: false,
    success: false,
    edit: false,
    saveAndExit: false,
  })
  const [half, setHalf] = useState({
    create: false,
    edit: false,
    isSaveAndExit: false,
  })

  const state = useAppSelector(state => state.adminManualFlowSteps)
  const {
    current: step,
    requestingGoTo,
    latestStep,
    confirmation,
    paymentType,
  } = state || {}

  const validating = requestingGoTo !== undefined

  const STEP_ADMIN_MANUAL = createRenderAdminManualFlow({
    actions,
    state,
    isPending,
  })

  const formMethods = useForm<ExpiryAdminManualDto>({
    defaultValues: {
      ...defaultValuesAdminManual,
    },
    mode: 'onSubmit',
  })

  const {
    handleSubmit,
    formState: { dirtyFields },
    watch,
    reset,
    setValue,
  } = formMethods

  const isDirty = Object.keys(dirtyFields).length > 0

  const { contractId } = useParams<{ contractId?: string }>()

  const id = contractId && parseInt(contractId, 10)

  useEffect(() => {
    onExitBrowser(isDirty)
  }, [isDirty])

  useEffect(() => {
    if (state.bankInfoCanBeSaved === true) {
      updateAndSave()
    }
  }, [state.bankInfoCanBeSaved])

  const { refetch } = useQuery<
    ContractsResponses['getById'],
    ErrorResponse,
    ContractsResponses['getById'],
    [string, IdParam]
  >([ContractsQueryKeys.getById, { id }], API.Contracts.getById, {
    enabled: false,
    onSuccess: data => {
      if (data?.data?.status === 'DRAFT') {
        toast.error('Customer have not finish customer contract flow yet')
        toast.error('Please view more info in detail page', { delay: 2000 })

        history.push(`${routerAdminFlow.contract}/${id}`)
      } else {
        const adminApprovalData = response2AdminApproval(data.data)

        reset({
          ...defaultValuesAdminManual,
          ...adminApprovalData,
        })
      }
    },
  })

  useEffect(() => {
    id && refetch()
  }, [id, refetch])

  const { mutate: updateRiskScore } = useMutation<
    ContractsResponses['getById'],
    ErrorResponse,
    IdParam
  >(API.Customers.updateRiskScore, {
    onSuccess: data => {
      setPending(false)
      const result = data.data as any

      setHalfContract({
        ...getHalfContract(),
      })
      setFormValues(result, setValue)

      toast('Information successfully processed')
    },
    onError: error => {
      setPending(false)
      onError(error)
    },
  })

  const { mutate: updateHalfManual } = useMutation<
    ContractsResponses['editManual'],
    ErrorResponse,
    ManualHalfContractDto & IdParam
  >(API.Contracts.updateHalfManual, {
    onSuccess: () => {
      setHalf(prev => ({ ...prev, create: false, edit: false }))
      toast('Saved contract successfully')
      handleExit()
    },
    onError,
  })

  const { mutate: approve, isLoading: isApprovalInProgress } = useMutation<
    ContractsResponses['updateAdminApproval'],
    ErrorResponse,
    ApproveContractDto & IdParam
  >(API.Contracts.updateAdminApproval, {
    onSuccess: async () => {
      setHalfContract({
        contractId: id,
        name: '',
      })
      if (half.isSaveAndExit) {
        handleExit()
        return
      }
      isChangeFormDirty(dirtyFields) && (await updateRiskScore({ id }))
      toast('Approved contract successfully')
      handleExit()
    },
    onError: error => {
      handleClear()
      clearHalfContract()
      setPending(false)
      onError(error)
    },
  })

  const handleScore = id => {
    if (half.isSaveAndExit) {
      handleExit()
      return
    }

    updateRiskScore({ id })
    handleExit()
  }

  const handleClear = () => {
    setHalf(prev => ({ ...prev, isSaveAndExit: false }))
  }

  const handleError = error => {
    setPending(false)

    handleClear()
    onError(error)
  }

  const { mutate: createAndApprove, isLoading: isCreatingAndApproving } =
    useMutation<
      ContractsResponses['createAdminManual'],
      ErrorResponse,
      ManualContractDto
    >(API.Contracts.createAndApprove, {
      onSuccess: async data => {
        const res = data?.data as any

        setHalfContract({
          contractId: res?.id,
          name: `${res?.firstName} ${res?.middleName} ${res?.lastName}`,
        })

        if (res?.id) {
          handleScore(res.id)
        }

        toast('Contract created successfully')
        toast('Approved contract successfully')
      },
      onError: error => {
        handleError(error)
      },
    })

  const { mutate: create, isLoading: updating } = useMutation<
    ContractsResponses['createAdminManual'],
    ErrorResponse,
    ManualContractDto
  >(API.Contracts.createAdminManual, {
    onSuccess: async data => {
      const res = data?.data as any

      setHalfContract({
        contractId: res?.id,
        name: `${res?.firstName} ${res?.middleName} ${res?.lastName}`,
      })

      handleScore(res?.id)
      toast('Contract created successfully')
    },
    onError: error => {
      handleError(error)
    },
  })

  const { mutate: editManual, isLoading: editing } = useMutation<
    ContractsResponses['editManual'],
    ErrorResponse,
    ManualContractDto & IdParam
  >(API.Contracts.editManual, {
    onSuccess: async () => {
      setHalfContract({
        contractId: id,
        name: '',
      })

      isChangeFormDirty(dirtyFields) && (await handleScore(id))
      toast('Saved contract successfully')
      dispatch(actions.setBankInfoCanBeSaved(false))
      dispatch(actions.requestSaveBankInfo(false))
      handleExit()
    },
    onError: error => {
      handleError(error)
    },
  })

  const handleSubmitForm = handleSubmit(
    async (adminManualDto: ExpiryAdminManualDto) => {
      const {
        customer: { creditCardInfo, references, ...restCustomerDto },
      } = adminManualDto

      const updatePayload = {
        ...adminManualDto,
        startDate: new Date(
          adminManualDto.startDate.toUTCString(),
        ).toISOString(),
      }

      if (paymentType === 'creditCard') {
        updatePayload.customer.creditCardInfo =
          convertCreditCard(creditCardInfo)

        delete updatePayload.customer.bankAccountInfo
      } else if (paymentType === 'bankAccount') {
        delete updatePayload.customer.creditCardInfo
      }

      const referencesValue = references.map(ref => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { middleName, driversLicense, ...rest } = ref
        return rest
      })

      updatePayload.customer = {
        ...restCustomerDto,
        creditCardInfo: isCreditCardDirty(dirtyFields.customer?.creditCardInfo)
          ? updatePayload.customer.creditCardInfo
          : undefined,
        bankAccountInfo: isBankAccountDirty(
          dirtyFields.customer?.bankAccountInfo,
        )
          ? adminManualDto.customer.bankAccountInfo
          : undefined,
        emailAddress: updatePayload.customer?.emailAddress?.toLowerCase(),
        businessEmail: updatePayload.customer?.businessEmail?.toLowerCase(),
        references: referencesValue.filter(ref => isNotEmptyReference(ref)),
      }

      clearEmptyBusinessDetails(updatePayload.customer)

      // Fetch contract ID from local storage.
      const { contractId } = getHalfContract()

      // When ID derived from the URL and contract ID from local storage exists.
      if (id && contractId) {
        // Execute API request to manually approve a customer contract.
        approve({ ...updatePayload, id })

        clearHalfContract()
      }

      // When there is no ID derived from the URL but contract ID from local storage exists.
      if (!id && contractId) {
        updateHalfManual({
          id: contractId,
          interestRate: updatePayload.interestRate,
        })
      } else {
        // Important:
        // The following logic falls under the "on-premise" customer contract creation.
        // First, the contract is created.
        // Finally, the approval process is triggered.

        setHalf(prev => ({ ...prev, create: true, edit: false }))
        setPending(true)

        // Execute API request to create a new customer contract at the same time approve it.
        createAndApprove(updatePayload)
      }
    },
  )

  const updateAndSave = handleSubmit((adminManualDto: ExpiryAdminManualDto) => {
    return new Promise<void>(async (resolve, reject) => {
      const {
        customer: { creditCardInfo, references, ...restCustomerDto },
      } = adminManualDto

      const updatePayload = {
        ...adminManualDto,
        startDate: new Date(
          adminManualDto.startDate.toUTCString(),
        ).toISOString(),
      }

      if (paymentType === 'creditCard') {
        updatePayload.customer.creditCardInfo =
          convertCreditCard(creditCardInfo)

        delete updatePayload.customer.bankAccountInfo
      } else if (paymentType === 'bankAccount') {
        delete updatePayload.customer.creditCardInfo
      }

      const referencesValue = references.map(ref => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { middleName, driversLicense, ...rest } = ref
        return rest
      })

      updatePayload.customer = {
        ...restCustomerDto,
        creditCardInfo: isCreditCardDirty(dirtyFields.customer?.creditCardInfo)
          ? updatePayload.customer.creditCardInfo
          : undefined,
        bankAccountInfo: isBankAccountDirty(
          dirtyFields.customer?.bankAccountInfo,
        )
          ? adminManualDto.customer.bankAccountInfo
          : undefined,
        emailAddress: updatePayload.customer?.emailAddress?.toLowerCase(),
        businessEmail: updatePayload.customer?.businessEmail?.toLowerCase(),
        references: referencesValue.filter(ref => isNotEmptyReference(ref)),
      }

      clearEmptyBusinessDetails(updatePayload.customer)

      // When there is no assigned customer contract ID yet.
      if (!id) {
        setHalf(prev => ({ ...prev, create: true, edit: false }))
        setPending(true)

        // Execute API request to create a brand new customer contract.
        await create(updatePayload)
        dispatch(actions.setBankInfoCanBeSaved(false))
        dispatch(actions.requestSaveBankInfo(false))
        return resolve()
      }

      setHalf(prev => ({ ...prev, create: false, edit: true }))
      isChangeFormDirty(dirtyFields) && setPending(true)

      // Otherwise, execute API request to save the data on the existing customer contract.
      await editManual({ ...updatePayload, id })
      return resolve()
    })
  })

  const approveManually = handleSubmit(
    (adminManualDto: ExpiryAdminManualDto) => {
      const {
        customer: { creditCardInfo, references, ...restCustomerDto },
      } = adminManualDto

      const updatePayload = {
        ...adminManualDto,
        startDate: new Date(
          adminManualDto.startDate.toUTCString(),
        ).toISOString(),
      }

      if (paymentType === 'creditCard') {
        updatePayload.customer.creditCardInfo =
          convertCreditCard(creditCardInfo)

        delete updatePayload.customer.bankAccountInfo
      } else if (paymentType === 'bankAccount') {
        delete updatePayload.customer.creditCardInfo
      }

      const referencesValue = references.map(ref => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { middleName, driversLicense, ...rest } = ref
        return rest
      })

      updatePayload.customer = {
        ...restCustomerDto,
        creditCardInfo: isCreditCardDirty(dirtyFields.customer?.creditCardInfo)
          ? updatePayload.customer.creditCardInfo
          : undefined,
        bankAccountInfo: isBankAccountDirty(
          dirtyFields.customer?.bankAccountInfo,
        )
          ? adminManualDto.customer.bankAccountInfo
          : undefined,
        emailAddress: updatePayload.customer?.emailAddress?.toLowerCase(),
        businessEmail: updatePayload.customer?.businessEmail?.toLowerCase(),
        references: referencesValue.filter(ref => isNotEmptyReference(ref)),
      }

      clearEmptyBusinessDetails(updatePayload.customer)

      // Execute API request to approve a customer contract.
      approve({ ...updatePayload, id })

      clearHalfContract()
    },
  )

  const handleExit = () => {
    clearHalfContract()
    handleClear()

    setOpenModal(prev => ({ ...prev, success: false }))
    dispatch(actions.exitStep())

    id ? history.push(`/contract/${id}`) : history.push('/')
  }

  const onClickNext = async () => {
    if (step + 1 === 7 && !getHalfContract().contractId) {
      isChangeFormDirty(dirtyFields) && toast('Checking information')
    }

    dispatch(actions.requestNext())
  }

  const onClickExit = () => {
    if (isDirty) {
      setOpenModal(prev => ({ ...prev, exit: true }))
    } else {
      handleExit()
    }
  }

  const onClickExportPDF = () => {
    const header = document.getElementById('trailer-buyout-pdf-header')
    header.style.display = 'block'

    const d = document.getElementById('trailer-buyout-pdf')
    html2canvas(d).then(canvas => {
      // @ts-ignore
      var img = canvas.toDataURL('image/png')
      var doc = new jsPDF('portrait', 'pt', 'A4')

      // @ts-ignore
      var width = doc.internal.pageSize.getWidth()
      var height = doc.internal.pageSize.getHeight()

      let widthRatio = width / canvas.width
      let heightRatio = height / canvas.height

      let ratio = widthRatio > heightRatio ? heightRatio : widthRatio

      doc.addImage(
        img,
        'PNG',
        50,
        50,
        canvas.width * ratio - 100,
        canvas.height * ratio - 100,
      )

      doc.save('trailer-buy-out-schedule.pdf')
      header.style.display = 'none'
    })
  }

  const onOpenSuccessModal = () => {
    const customOrder = watch('customOrder')
      ? !!confirmation?.returnable
        ? true
        : false
      : true
    if (!!confirmation?.term && customOrder) {
      if (id) {
        setOpenModal(prev => ({ ...prev, edit: true }))
      } else {
        setOpenModal(prev => ({ ...prev, success: true }))
      }
    } else {
      dispatch(
        actions.updateConfirmation({
          errorTerm: !confirmation?.term,
          errorReturnable: !confirmation?.returnable,
        }),
      )
    }
  }

  const handleKeyUp = event => {
    if (event.key === 'Enter' && step !== STEP_ADMIN_MANUAL.length - 1) {
      onClickNext()
    }
  }
  return (
    <FormProvider {...formMethods}>
      <HalfLayout
        title={
          STEP_ADMIN_MANUAL[step]?.leftTitle
            ? STEP_ADMIN_MANUAL[step]?.leftTitle
            : `Add our customer
        ${STEP_ADMIN_MANUAL[step]?.title?.toLowerCase()}`
        }
      >
        <BoxExitButton>
          {/* todo: delete it when app is done  */}
          <Button
            variant="contained"
            sx={{ opacity: 0, cursor: 'default' }}
            onDoubleClick={() => reset(dummieManualContractDto)}
          >
            dummie data
          </Button>
          {step === 1 ? (
            <ExitButton onClick={onClickExportPDF} type="button">
              Export PDF
            </ExitButton>
          ) : (
            ''
          )}
          <ExitButton onClick={onClickExit} type="button">
            Exit
          </ExitButton>
          <SaveAndExitButton
            loading={validating || state.requestingSaveBankInfo}
            sx={{ backgroundColor: 'success.main', color: 'white' }}
            onClick={async () => {
              if (isPending) {
                return
              }
              setHalf(prev => ({ ...prev, isSaveAndExit: true }))

              if (step === 5) {
                dispatch(actions.requestSaveBankInfo(true))
              } else {
                await updateAndSave()
              }
            }}
          >
            Save &amp; Exit
          </SaveAndExitButton>
        </BoxExitButton>

        <Box
          component="form"
          onSubmit={handleSubmitForm}
          display="flex"
          flex={1}
          flexDirection="column"
        >
          <TitleMenu
            steps={STEP_ADMIN_MANUAL}
            current={step}
            latestStep={latestStep}
            onGoTo={index => dispatch(actions.requestGoTo(index))}
          />

          <Box
            component="div"
            flex={1}
            px={3}
            onKeyUp={handleKeyUp}
            sx={{ maxHeight: '70vh', overflowY: 'auto' }}
          >
            {STEP_ADMIN_MANUAL[step]?.component}
          </Box>
          <BottomBar>
            <StepBar current={step} step={STEP_ADMIN_MANUAL} />
            <Box
              display="flex"
              flex={1}
              justifyContent="space-between"
              mt={2}
              px={6}
            >
              {step !== 0 ? (
                <Button
                  variant="outlined"
                  onClick={() => dispatch(actions.backStep())}
                >
                  Back
                </Button>
              ) : (
                <div />
              )}
              {step !== STEP_ADMIN_MANUAL.length - 1 ? (
                <Button
                  variant="contained"
                  loading={validating}
                  onClick={onClickNext}
                >
                  Next
                </Button>
              ) : (
                <Button variant="contained" onClick={onOpenSuccessModal}>
                  Approve contract
                </Button>
              )}
            </Box>
          </BottomBar>
        </Box>
      </HalfLayout>
      <ExitModal
        open={openModal.exit}
        onExit={handleExit}
        handleClose={() => setOpenModal(prev => ({ ...prev, exit: false }))}
      />
      <SuccessModal
        open={openModal.success}
        handleClose={() => setOpenModal(prev => ({ ...prev, success: false }))}
        onSuccess={handleSubmitForm}
        loading={isApprovalInProgress || isCreatingAndApproving}
      />
      <ExitEditModal
        open={openModal.saveAndExit}
        onExit={handleExit}
        handleClose={() =>
          setOpenModal(prev => ({ ...prev, saveAndExit: false }))
        }
        onSubmit={() => handleSubmitForm()}
        loading={updating || editing}
      />
      <EditModal
        open={openModal.edit}
        handleClose={() => setOpenModal(prev => ({ ...prev, edit: false }))}
        onSuccess={async e => {
          e.preventDefault()
          await approveManually()
        }}
        loading={updating}
      />
    </FormProvider>
  )
}

export default AdminManualFlow
