import { ConfigValues, ContractEntity } from '../store/types'

export type Buyout = {
  buyout: number
  rebate: number
}

export type YearSchedule = {
  daily: number
  weekly: number
  monthly: number
  fortnightly: number
}

export type BuyoutSchedule = {
  firstPayment: number
  firstYear: YearSchedule
  laterYear: YearSchedule
  buyouts: Buyout[]
  retailValue: number
}

export class CalculateUtil {
  configs: ConfigValues

  constructor(configs: ConfigValues) {
    this.configs = configs
  }

  private calFirstYear(totalRetailValue: number): YearSchedule {
    const { annualFee, allowance } = this.configs
    const monthly =
      (((annualFee / 12) * 23.66 + totalRetailValue) / 23.66) *
      allowance *
      1.015
    const daily = (monthly * 12) / 365
    const weekly = daily * 7
    const fortnightly = daily * 14

    return { monthly, daily, weekly, fortnightly }
  }

  private calLaterYear(
    firstYear: YearSchedule,
    registrationFee?: number,
  ): YearSchedule {
    const { yearly } = this.configs // YEARLY REGISTRATION FEE
    const yearlyFee = registrationFee || yearly
    const extraDaily = yearlyFee / 365
    const extraMonthly = (extraDaily * 365) / 12
    const monthly = firstYear.monthly * 0.5 + extraMonthly
    const daily = (monthly * 12) / 365
    const weekly = daily * 7
    const fortnightly = daily * 14
    return { monthly, daily, weekly, fortnightly }
  }

  private calFirstYearWithDamageWaiver(
    firstYearWithoutDamageWaiver: YearSchedule,
  ): YearSchedule {
    let { daily } = firstYearWithoutDamageWaiver
    const damageWaiver = daily * 0.15
    daily += damageWaiver
    const weekly = daily * 7
    const monthly = (daily * 365) / 12
    const fortnightly = daily * 14
    return { monthly, daily, weekly, fortnightly }
  }

  private calLaterYearWithDamageWaiver(
    laterYearWithoutDamageWaiver: YearSchedule,
    firstYearWithoutDamageWaiver: YearSchedule,
  ): YearSchedule {
    const { daily: firstYearTotalDailyRent } = firstYearWithoutDamageWaiver
    let { daily } = laterYearWithoutDamageWaiver
    daily += firstYearTotalDailyRent * 0.15
    const monthly = (daily * 365) / 12
    const weekly = daily * 7
    const fortnightly = daily * 14
    return { monthly, daily, weekly, fortnightly }
  }

  private calYearlyTotal(daily: number, firstPayment?: number): number {
    return firstPayment ? firstPayment + daily * 7 * 48 : daily * 365
  }

  getSchedule({
    retailPrice,
    registrationFee,
    interestRate = this.configs?.interestRate,
  }: Pick<
    ContractEntity,
    'retailPrice' | 'registrationFee' | 'interestRate'
  >): BuyoutSchedule {
    const retailValue = retailPrice + registrationFee
    const firstYearWithoutDamageWaiver = this.calFirstYear(retailValue)
    const laterYearWithoutDamageWaiver = this.calLaterYear(
      firstYearWithoutDamageWaiver,
      registrationFee,
    )
    const firstYear = this.calFirstYearWithDamageWaiver(
      firstYearWithoutDamageWaiver,
    )
    const laterYear = this.calLaterYearWithDamageWaiver(
      laterYearWithoutDamageWaiver,
      firstYearWithoutDamageWaiver,
    )
    const firstPayment = firstYear.weekly * 4 + 30

    const firstYearTotal = this.calYearlyTotal(
      firstYearWithoutDamageWaiver.daily,
      firstYearWithoutDamageWaiver.weekly * 4,
    )
    const laterYearTotal = this.calYearlyTotal(
      laterYearWithoutDamageWaiver.daily,
    )

    const firstYearBuyout: Buyout = {
      buyout: retailValue - firstYearTotal * interestRate,
      rebate: firstYearTotal * interestRate,
    }
    let lastBuyout = firstYearBuyout.buyout
    const laterYearBuyouts: Buyout[] = Array(4)
      .fill(null)
      .map(() => {
        const buyout = lastBuyout - laterYearTotal * 0.28
        const rebate = retailValue - buyout
        lastBuyout = buyout
        return { buyout, rebate }
      })

    return {
      retailValue,
      firstPayment,
      firstYear,
      laterYear,
      buyouts: [firstYearBuyout, ...laterYearBuyouts],
    }
  }
}

export const money = (value: number): string => value && `$${value.toFixed(2)}`
