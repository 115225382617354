import { PhoneNumberUtil } from 'google-libphonenumber'
import { ControllerProps } from 'react-hook-form'
import PhoneInput from '../../components/Input/PhoneInput'
import PostCodeInput from '../../components/Input/PostCodeInput'
import AbnInput from './../../components/Input/AbnInput'

//main rules
export const defaultRulesOptional: ControllerProps['rules'] = {
  maxLength: 250,
  pattern: /\S/,
}
export const defaultRules: ControllerProps['rules'] = {
  maxLength: 250,
  pattern: /\S/,
  required: true,
}

export const aptRules: ControllerProps['rules'] = defaultRulesOptional

export const nameRulesOptional: ControllerProps['rules'] = {
  ...defaultRulesOptional,
  maxLength: 40,
}
export const nameRules: ControllerProps['rules'] = {
  ...nameRulesOptional,
  required: true,
}
const emailRule =
  /^\s?(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s?$/
export const defaultEmailRules: ControllerProps['rules'] = {
  ...defaultRules,
  pattern: emailRule,
}
export const defaultEmailRulesOptional: ControllerProps['rules'] = {
  ...defaultRulesOptional,
  pattern: emailRule,
}

export const postalCodeRulesOptional: ControllerProps['rules'] = {
  pattern: /^[0-9]*$/,
  maxLength: 4,
  minLength: 4,
}
export const postalCodeRules: ControllerProps['rules'] = {
  required: true,
  ...postalCodeRulesOptional,
}
export const defaultPostalCodeInput = {
  rules: postalCodeRules,
  label: 'Post Code',
  InputProps: { inputComponent: PostCodeInput },
}
export const defaultPostalCodeInputOptional = {
  rules: postalCodeRulesOptional,
  label: 'Post Code',
  InputProps: { inputComponent: PostCodeInput },
}

export const CostRule: ControllerProps['rules'] = {
  required: true,
  maxLength: 250,
  validate: {
    positiveNumber: value => value > 0 || false,
  },
}
const phoneUtil = PhoneNumberUtil.getInstance()
const isValidPhoneNumber = (
  value?: string,
  required: boolean = true,
): boolean => {
  try {
    const phoneNumber = phoneUtil.parse(value, 'AU')
    const isValid = phoneUtil.isValidNumber(phoneNumber)
    return isValid
  } catch (e) {
    return !required
  }
}
export const phoneRuleOption = {
  rules: {
    minLength: 8,
    validate: value => {
      return isValidPhoneNumber(value, false) || false
    },
  },
  label: 'Phone number',
  InputProps: { inputComponent: PhoneInput },
}
export const phoneRule = {
  ...phoneRuleOption,
  rules: {
    required: true,
    ...phoneRuleOption.rules,
  },
}
// old abn business rule
// export const abnBusinessRule = {
//   rules: {
//     required: 'Required',
//     maxLength: { value: 11, message: 'Max 11 Digits' },
//     minLength: { value: 11, message: 'Min 11 Digits' },
//     pattern: {
//       value: /^[0-9]*$/,
//       message: 'Must be number only',
//     },
//   },
//   label: 'ABN',
//   InputProps: { inputComponent: AbnInput },
// }
export const abnBusinessRule = {
  rules: {
    require: true,
    maxLength: 11,
    minLength: 11,
    pattern: /^[0-9]*$/,
  },
  label: 'ABN',
  InputProps: { inputComponent: AbnInput },
}
