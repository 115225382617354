import { Box, Button, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import axios from 'axios'
import fileDownload from 'js-file-download'
import React, { FC, useEffect } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { API, ErrorResponse } from '../../../../../apis'
import {
  ContractsQueryKeys,
  ContractsResponses,
} from '../../../../../apis/endpoints/contracts'
import { AdminApprovalFlowProps } from '../../../../../store/slices/step/interface'
import { setFormValues } from '../../../../../store/slices/step/values'
import { ApproveContractDto, IdParam } from '../../../../../store/types'
import { getHalfContract } from '../../../../../utils/localStorage'
import CircularProgressWithLabel from '../../components/CircularProgress'
import ItemCredit from '../../components/ItemCredit'
import { Whitespace } from '../../components/Styles'
import TitleCreditScore from '../../components/TitleCreditScore'

const useStyles = makeStyles({
  button: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '17px',
    color: '#FBFCFE',
    borderRadius: '100px',
    padding: '7px 24px',
  },
  box: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

export const Step7AdminApprovalFlow: FC<
  AdminApprovalFlowProps & { isPending?: boolean }
> = ({ actions, state, isPending }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const params = useParams<{ id?: string; contractId?: string }>()
  const id =
    parseInt(params.id || params.contractId, 10) || getHalfContract().contractId
  const { requestingGoTo } = state

  const { trigger, control, getValues, setValue } =
    useFormContext<ApproveContractDto>()
  useEffect(() => {
    if (!requestingGoTo) {
      return
    }
    trigger(['customer.documents']).then(canGoNext =>
      dispatch(actions.answerGoTo(canGoNext)),
    )
  }, [actions, dispatch, requestingGoTo, trigger])
  const values = getValues()
  const { customer } = values
  let riskScore = customer.riskScore || 0
  const firstName = customer.firstName || ''
  const lastName = customer.lastName || ''
  const middleName = customer.middleName || ''
  const altRiskScore = Number.isNaN(getHalfContract().riskScore)
    ? 0
    : getHalfContract().riskScore
  const name = getHalfContract().name
  riskScore = (Number.isNaN(riskScore) ? 0 : riskScore) || altRiskScore

  const data = [
    {
      value: isPending ? '' : customer.defaults || 0,
      title: 'Defaults',
      isBoolean: customer.defaults >= 3 ? false : true,
    },
    {
      value: isPending ? '' : customer.judgement || 0,
      title: 'Judgements',
      isBoolean: customer.judgement <= 1 ? true : false,
    },
    {
      value: isPending ? '' : customer.recentApplications || 0,
      title: 'Recent Applications',
      isBoolean: undefined,
    },
    {
      value: isPending ? '' : customer.bankrupt ? 'Yes' : 'No',
      title: 'Bankrupt?',
      isBoolean: undefined,
    },
  ]

  const { refetch } = useQuery<
    ContractsResponses['getById'],
    ErrorResponse,
    ContractsResponses['getById'],
    [string, IdParam]
  >([ContractsQueryKeys.getById, { id }], API.Contracts.getById, {
    enabled: false,
    onSuccess: data => {
      setFormValues(data.data.customer, setValue)
    },
  })

  const exportScorePdf = () => {
    const fileName = `${customer.firstName}-${customer.lastName}-score.pdf`
    axios
      .get(customer.riskScoreUrl, {
        responseType: 'blob',
      })
      .then(res => {
        const blob = new Blob([res.data], { type: 'application/octetstream' })
        fileDownload(blob, fileName)
      })
  }

  useFieldArray({ control, name: 'customer.documents' })

  const cicleNum: number = (riskScore || 0) / 10
  return (
    <Grid container spacing={3}>
      <TitleCreditScore
        name={name || `${firstName} ${middleName} ${lastName}`}
      />
      <Whitespace height={27} />
      <CircularProgressWithLabel
        size={244}
        value={cicleNum}
        title={`${riskScore || 0}`}
        isBoolean={(riskScore || 0) >= 400 ? true : false}
        isPending={isPending}
      />
      <Whitespace height={50} />
      {data.map((value, index) => (
        <Grid item xs={12} md={6} lg={3} key={index}>
          <ItemCredit
            value={value.value}
            title={value.title}
            isBoolean={value.isBoolean}
          />
        </Grid>
      ))}
      <Whitespace height={24} />
      <Box className={classes.box}>
        <Button
          variant="text"
          onClick={exportScorePdf}
          disabled={customer.riskScoreUrl ? false : true}
        >
          Export PDF Report
        </Button>
      </Box>
      <Whitespace height={38} />
      <Box className={classes.box}>
        <Button
          variant="contained"
          color="success"
          className={classes.button}
          onClick={() => refetch()}
        >
          Refresh
        </Button>
      </Box>
      <Whitespace height={30} />
    </Grid>
  )
}
