import { Grid } from '@mui/material'
import React, { FC, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { AdminApprovalFlowProps } from '../../../../../store/slices/step/interface'
import { ApproveContractDto } from '../../../../../store/types'
import { ControlledTextField } from '../../components/ControlledInput'
import SelectState from '../../components/SelectState'
import {
  abnBusinessRule,
  defaultEmailRulesOptional,
  defaultPostalCodeInputOptional,
  defaultRulesOptional,
  phoneRuleOption,
} from '../../defaultRules'

export const Step1AdminApprovalFlow: FC<AdminApprovalFlowProps> = ({
  actions,
  state,
}) => {
  const dispatch = useDispatch()
  const { requestingGoTo } = state

  const { trigger } = useFormContext<ApproveContractDto>()

  useEffect(() => {
    if (!requestingGoTo) {
      return
    }

    trigger([
      'customer.businessName',
      'customer.businessABN',
      'customer.businessPhone',
      'customer.businessEmail',
      'customer.businessStreetAddress',
      'customer.businessAddress',
      'customer.businessCity',
      'customer.businessState',
      'customer.businessPostCode',
    ]).then(canGoNext => dispatch(actions.answerGoTo(canGoNext)))
  }, [actions, dispatch, requestingGoTo, trigger])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <ControlledTextField
          name="customer.businessName"
          rules={defaultRulesOptional}
          label="Business name"
          autoFocus
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <ControlledTextField name="customer.businessABN" {...abnBusinessRule} />
      </Grid>
      <Grid item xs={12} md={6}>
        <ControlledTextField
          name="customer.businessPhone"
          {...phoneRuleOption}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <ControlledTextField
          name="customer.businessEmail"
          rules={defaultEmailRulesOptional}
          label="Email"
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField
          name="customer.businessStreetAddress"
          rules={defaultRulesOptional}
          label="Street address"
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField
          name="customer.businessAddress"
          rules={defaultRulesOptional}
          label="Apt, suite, etc (Optional)"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <ControlledTextField
          name="customer.businessCity"
          rules={defaultRulesOptional}
          label="City"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <SelectState name="customer.businessState" required={false} />
      </Grid>
      <Grid item xs={12} md={4}>
        <ControlledTextField
          name="customer.businessPostCode"
          {...defaultPostalCodeInputOptional}
        />
      </Grid>
    </Grid>
  )
}
