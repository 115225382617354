import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IStepStore } from '.'
import { PaymentMethod } from './interface'
import { createRenderAdminManualFlow } from './values'
interface IConfirm {
  term?: boolean
  errorTerm?: boolean
  returnable?: boolean
  errorReturnable?: boolean
}
interface IAdminManualStep extends IStepStore {
  confirmation: IConfirm
}
export const initialStateAdminManualFlowStep: IAdminManualStep = {
  current: 0,
  latestStep: 0,
  requestingGoTo: undefined,
  requestingSave: undefined,
  bankInfoCanBeSaved: false,
  requestBankInfo: false,
  canSave: undefined,
  public_key: '',
  confirmation: {
    term: undefined,
    errorTerm: undefined,
    returnable: undefined,
    errorReturnable: undefined,
  },
  paymentType: 'bankAccount',
}
const steps = createRenderAdminManualFlow()
const STEP_LENGTH = steps.length
const adminManualFlowStepsSlice = createSlice({
  name: 'adminManualFlowSteps',
  initialState: initialStateAdminManualFlowStep,
  reducers: {
    requestSaveBankInfo(state, { payload: val }) {
      state.requestingSaveBankInfo = val
    },
    setBankInfoCanBeSaved(state, { payload: val }) {
      state.bankInfoCanBeSaved = val
    },
    requestNext(state) {
      state.requestingGoTo = state.current + 1
    },
    updatepaymentType(
      state,
      { payload: paymentType }: PayloadAction<PaymentMethod>,
    ) {
      state.paymentType = paymentType
    },
    requestGoTo(state, { payload: to }: PayloadAction<number>) {
      if (to <= state.current) {
        state.current = to
        return
      }
      state.requestingGoTo = to
    },
    answerGoTo(state, { payload: canGoForth }: PayloadAction<boolean>) {
      if (canGoForth && state.current < STEP_LENGTH - 1) {
        state.current = state.requestingGoTo
        if (state.current > state.latestStep) {
          state.latestStep = state.current
        }
      }
      state.requestingGoTo = undefined
    },
    nextStep(state) {
      state.current += 1
    },
    backStep(state) {
      if (state.current > 0) {
        state.current -= 1
      }
    },
    requestSave(state, { payload: requestingSave }: PayloadAction<boolean>) {
      state.requestingSave = requestingSave
    },
    answerSave(state, { payload: canSave }: PayloadAction<boolean>) {
      state.canSave = canSave
      state.requestingSave = undefined
    },
    editGoTo(state, { payload: step }: PayloadAction<number>) {
      state.current = step
      state.latestStep = STEP_LENGTH - 1
    },
    exitStep(state) {
      state.current = 0
      state.latestStep = 0
      state.confirmation = null
      state.paymentType = 'bankAccount'
    },
    updateConfirmation: (
      state,
      { payload: confirmation }: PayloadAction<IConfirm>,
    ) => ({
      ...state,
      confirmation: { ...state.confirmation, ...confirmation },
    }),
    setPublicKey: (state, action: PayloadAction<string>) => {
      state.public_key = action.payload
    },
  },
})

export const {
  actions: stepActions,
  reducer: adminManualFlowStepsReducer,
  name,
} = adminManualFlowStepsSlice
export default adminManualFlowStepsReducer

export type AdminManualActionsType = typeof stepActions

export const useStepAdminManualFlowSlice = () => {
  return { actions: stepActions }
}
