import React from 'react'
import NumberFormat from 'react-number-format'
import { defaultPropsNumberFormat } from './BSBInput'

const PhoneInput = React.forwardRef(function PhoneInput(props: any, ref) {
  const { value, onChange, onBlur, ...rest } = props

  return (
    <NumberFormat
      getInputRef={ref}
      onValueChange={({ value }) => {
        return onChange(value ? value : null)
      }}
      format="## #### ####"
      {...{ value, onBlur }}
      {...defaultPropsNumberFormat}
      {...rest}
    />
  )
})
export default PhoneInput
