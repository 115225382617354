import { MutationFunction, QueryFunction } from 'react-query'

export function buildQueryKeys<T extends {}>(data: T) {
  return Object.keys(data).reduce(
    (a, key) => ({ ...a, [key]: key }),
    {} as Record<keyof T, string>,
  )
}

export type ResponseType<
  T extends Record<
    string,
    MutationFunction<any, any> | QueryFunction<any, any>
  >,
> = {
  [k in keyof T]: ReturnType<T[k]> extends PromiseLike<infer U>
    ? U
    : ReturnType<T[k]>
}
