import { format, toDate } from 'date-fns-tz'
import { toast, ToastPosition } from 'react-toastify'
import {
  CreateCreditCardDto,
  CreateReferenceDto,
  FulfillCustomerDto,
} from '../store/types'
import { ErrorResponse } from './../apis'
const messageCors = 'Server Error. Please try again later.'
export const onError: (
  error: ErrorResponse,
  variables?: unknown,
  context?: unknown | undefined,
  position?: ToastPosition,
) => Promise<unknown> | void = (
  error,
  variables,
  context,
  position = 'top-right',
) => {
  const errorMessage = error?.response?.data?.message
  if (!error?.response?.data?.message) {
    toast.error('Server Time-out. Please try again later', { position })
  } else if (error?.response?.status === 500) {
    toast.error(messageCors, { position })
  } else if (Array.isArray(errorMessage)) {
    errorMessage.map(err =>
      toast.error(err, {
        position,
      }),
    )
  } else {
    toast.error(errorMessage, {
      position,
    })
  }
}
const unloadCallback = event => {
  event.preventDefault()
  event.returnValue = 'You have made some changes to the contract'
}
export const onExitBrowser = (isDirty: boolean) => {
  if (isDirty) {
    window.addEventListener('beforeunload', unloadCallback)
    return () => window.removeEventListener('beforeunload', unloadCallback)
  }
}
export const convertCreditCard = creditCardInfo => {
  let { expiry, ...restCreditInfo } = creditCardInfo
  if (!expiry) {
    return restCreditInfo
  }
  const [month, year] =
    expiry && expiry.toString().includes('/')
      ? expiry.split('/')
      : [expiry.toString().slice(0, 2), expiry.toString().slice(2, 4)]
  const returnCreditInfo: CreateCreditCardDto = {
    cardName: restCreditInfo.cardName,
    cardNumber: restCreditInfo.cardNumber,
    cvv: restCreditInfo.cvv,
    exp_month: +month,
    exp_year: +year,
  }
  return returnCreditInfo
}
export function isEmptyObject(obj) {
  return Object.keys(obj).length === 0
}

export const isEmpty = (value: any) => [undefined, null, ''].includes(value)

export const isNotEmptyReference = (ref: CreateReferenceDto) =>
  Object.entries(ref).some(([key, value]) => {
    return !isEmpty(value) && key !== 'referencesType'
  })

export const clearEmptyBusinessDetails = (dto: FulfillCustomerDto) => {
  const BUSINESS_KEYS: (keyof FulfillCustomerDto)[] = [
    'businessABN',
    'businessAddress',
    'businessCity',
    'businessEmail',
    'businessName',
    'businessPhone',
    'businessPostCode',
    'businessState',
    'businessStreetAddress',
  ]
  BUSINESS_KEYS.forEach(key => {
    if (dto[key] === '') {
      delete dto[key]
    }
  })
}

export const convertToDate = (date: string) => {
  return date && format(toDate(date), 'dd/MM/yy')
}
export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function generateRandomKey(length: number = 4) {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let randomKey = ''
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length)
    randomKey += characters.charAt(randomIndex)
  }
  return randomKey
}
