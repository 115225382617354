import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { TextField } from '@mui/material'
import { isDate } from 'class-validator'
import React, { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { request } from 'src/apis/request'
import commonConstant from '../../../../constants/common.constant'
import { StepProps } from '../../../../store/slices/step/interface'
import { CreateContractDto } from '../../../../store/types'
import CurrencyInput from '../../../components/Input/CurrencyInput'
import { ControlledTextField } from '../components/ControlledInput'
import PaymentDayDate from '../components/PaymentDayDate'
import PaymentType from '../components/PaymentType'
import { CostRule } from '../defaultRules'

type Step2Props = StepProps & {
  isRemoteCustomerApplication?: boolean
}

export const Step2: FC<Step2Props> = ({
  actions,
  state,
  isRemoteCustomerApplication = false,
}) => {
  const dispatch = useDispatch()
  const { contractId } = useParams<{ contractId?: string }>()
  const id = parseInt(contractId, 10)
  const isEditing = !!id
  const { requestingGoTo } = state
  const {
    trigger,
    control,
    formState: { errors },
    getValues,
  } = useFormContext<CreateContractDto>()
  React.useEffect(() => {
    if (!requestingGoTo) {
      return
    }
    const paymentType = getValues('paymentType')
    const listValidators = [
      'startDate',
      'product.cost',
      'retailPrice',
      'paymentType',
      'registrationFee',
    ]
    if (
      !isRemoteCustomerApplication &&
      paymentType !== commonConstant.PAYMENT_TYPE.DAILY
    ) {
      listValidators.push('start')
    }
    //@ts-ignore
    trigger(listValidators).then(async canGoNext => {
      try {
        if (canGoNext) {
          const res = await request.get(`/payment/credential`)
          dispatch(actions.setPublicKey(res.data.publicKey))
        }
      } catch (error) {
        const position = 'top-right'
        toast.error('Server Error. Please try again later.', { position })
      } finally {
        dispatch(actions.answerGoTo(canGoNext))
      }
    })
  }, [actions, dispatch, getValues, requestingGoTo, trigger])

  return (
    <>
      <ControlledTextField
        name="product.cost"
        rules={CostRule}
        label="Cost"
        autoFocus
        InputProps={{ inputComponent: CurrencyInput }}
        disabled={isEditing}
      />
      <ControlledTextField
        name="retailPrice"
        rules={CostRule}
        label="Retail Price"
        InputProps={{ inputComponent: CurrencyInput }}
        disabled={isEditing}
      />
      <ControlledTextField
        name="registrationFee"
        rules={CostRule}
        label="Registration fee"
        InputProps={{ inputComponent: CurrencyInput }}
        disabled={isEditing}
      />

      <Controller
        name="startDate"
        control={control}
        rules={{
          required: 'Required',
          validate: v => isDate(v) || 'Invalid Date',
        }}
        render={({ field: { value, onChange, onBlur } }) => (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              {...{ value, onChange, onBlur }}
              label="Contract date DD/MM/YYYY"
              inputFormat={'dd/MM/yyyy'}
              mask={'__/__/____'}
              renderInput={params => (
                <TextField
                  {...params}
                  error={!!errors['startDate']}
                  margin="normal"
                  fullWidth
                  name={'startDate'}
                />
              )}
            />
          </LocalizationProvider>
        )}
      />
      <PaymentType disabled={isEditing} />
      {isRemoteCustomerApplication ? null : <PaymentDayDate />}
    </>
  )
}
