import React, { FC, useEffect } from 'react'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { API } from '../apis'
import { ConfigResponses, ConfigsQueryKeys } from '../apis/endpoints/configs'
import { useAppSelector } from '../store/hooks'
import { useConfigsSlice } from '../store/slices/configs'

export const LoadConfigs: FC = () => {
  const { actions } = useConfigsSlice()
  const isAuthorized = useAppSelector(state => state.auth?.authorized)
  const dispatch = useDispatch()
  const { data, refetch } = useQuery<ConfigResponses['get']>(
    [ConfigsQueryKeys.get],
    API.Configs.get,
    {
      enabled: false,
      onSuccess: () => {
        console.log('CONFIGS LOADED')
      },
    },
  )

  useEffect(() => {
    refetch()
  }, [refetch, isAuthorized])

  useEffect(() => {
    data?.data && dispatch(actions.setState(data.data))
  }, [actions, data?.data, dispatch])

  return <React.Fragment />
}
