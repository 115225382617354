import EditIcon from '@mui/icons-material/Edit'
import {
  Box,
  Card,
  CardContent,
  Paper,
  styled,
  TableRow,
  Theme,
} from '@mui/material'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import Typography from '@mui/material/Typography'
import { SxProps } from '@mui/system'
import React, { ReactNode } from 'react'

export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    // color: theme.palette.text.secondary,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '0.9rem',
    border: 'none',
  },
  paddingRight: '21px',
  paddingLeft: '21px',
}))

export const BorderRight = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '1px',
  top: 0,
  bottom: 0,
  right: 0,
  backgroundColor: theme.palette.divider,
}))

export const StyleExpandTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: '0.9rem',
    border: 'none',
    borderBottom: '1px solid #dee7fa',
  },
}))

// JUST ICON IN TABLE
export const ItemEdit = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  color: theme.palette.primary.dark,
  boxShadow: 'none',
  backgroundColor: 'transparent',
}))
export const StyleEditIcon = styled(EditIcon)(() => ({
  fontSize: '1.15rem',
}))
export const ItemDropdown = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'center',
  backgroundColor: theme.palette.primary.dark,
  boxShadow: 'none',
  color: 'white',
  borderRadius: '50px',
}))

//TABLE ROW
export const StyledTableRow = styled(TableRow)(() => ({
  // backgroundColor: theme.palette.action.hover,
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  cursor: 'pointer',
  '&:last-child td': {},
}))

export const StyledExpandTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,

  // hide last border
  '& td, & th': {
    borderTop: 0,
  },
}))
export const StyledBoxTableRow = styled('span')(() => ({
  boxShadow: 'inset 0px -1px 0px #DEE7FA, inset 0px 1px 0px #DEE7FA',
}))

//JUST SOME DETAIL IN EXPAND
export const StyleStack = styled('div')(({ theme }) => ({
  display: 'grid',
  cursor: 'pointer',
  gridTemplateColumns: 'repeat(5, 1fr)',
  gridGap: theme.spacing(2),
}))

export const ItemInfoCard = styled(Paper)(() => ({
  boxShadow: 'none',
}))

export const StyleCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: 'none',
  border: '1px solid',
  borderColor: theme.palette.secondary.light,
}))
export const StyleCardContent = styled(CardContent)(() => ({
  paddingTop: 12,
  '&:last-child': {
    paddingBottom: 15,
  },
}))
export const StyleLabel = styled('div')(({ theme }) => ({
  ...theme.typography.body2,
  fontWeight: 'bold',
  display: 'inline-block',
  marginRight: 4,
}))

export const StyleText = styled('span')(({ theme }) => ({
  fontWeight: 'normal',
  color: theme.palette.text.secondary,
}))

export const StyleGreenCost = styled('span')(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.success.main,
}))

export const StyledId = styled(Typography)(() => ({}))

export interface ITableHeadName {
  name: string
  sx: SxProps<Theme>
  borderRight?: ReactNode
}
const createTableHead = (
  name: string = '',
  sx: SxProps<Theme> = { fontSize: '0.9rem', color: 'text.secondary' },
  borderRight?: ReactNode,
) => {
  return { name, sx, borderRight }
}

export const TypographyPending: React.FC<{ value: string }> = ({ value }) => {
  if (value) {
    return (
      <Typography variant="body2" sx={{ fontWeight: 'normal' }}>
        {value}
      </Typography>
    )
  }
  return (
    <Typography
      variant="body2"
      sx={{ fontWeight: 'normal', color: 'primary.dark' }}
    >
      Pending
    </Typography>
  )
}

export const tableHeadName: ITableHeadName[] = [
  createTableHead(
    'Name',
    {
      color: 'text.primary',
      fontSize: '1.2rem',
      position: 'sticky',
      left: 0,
      zIndex: 1,
      backgroundColor: 'white',
    },
    <BorderRight />,
  ),
  createTableHead('Contact Details'),
  createTableHead('Trailer Model'),
  createTableHead('Start Date'),
  createTableHead('Renewal Date'),
  createTableHead('Payment Status'),
  createTableHead('Total Paid'),
  createTableHead('Contract Status'),
  createTableHead(),
]
