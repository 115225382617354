import { configureStore } from '@reduxjs/toolkit'
import theme from '../styles/theme/slice'
import auth from './slices/auth'
import configs from './slices/configs'
import pagination from './slices/pagination'
import step from './slices/step'
import adminApprovalFlowSteps from './slices/step/admin-approval'
import adminManualFlowSteps from './slices/step/admin-manual'
import customerFlowSteps from './slices/step/customer-flow'

export const store = configureStore({
  reducer: {
    theme,
    auth,
    step,
    configs,
    customerFlowSteps,
    adminApprovalFlowSteps,
    adminManualFlowSteps,
    pagination,
  },
  devTools:
    /* istanbul ignore next line */
    process.env.NODE_ENV !== 'production' || process.env.PUBLIC_URL.length > 0,
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
