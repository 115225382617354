import { Grid } from '@mui/material'
import React, { FC, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { CustomerFlowProps } from '../../../../../store/slices/step/interface'
import { FulfillCustomerDto } from '../../../../../store/types'
import { ControlledTextField } from '../../components/ControlledInput'
import SelectDateOfBirth from '../../components/DateOfBirth'
import SelectGender from '../../components/SelectGender'
import SelectState from '../../components/SelectState'
import {
  defaultPostalCodeInput,
  defaultRules,
  defaultRulesOptional,
  phoneRule,
} from '../../defaultRules'
import ReadOnlyInput from './../../components/ReadOnlyInput'

export const Step0CustomerFlow: FC<CustomerFlowProps> = ({
  actions,
  state,
}) => {
  const dispatch = useDispatch()
  const { requestingGoTo } = state
  const { trigger } = useFormContext<FulfillCustomerDto>()

  useEffect(() => {
    if (!requestingGoTo) {
      return
    }

    trigger([
      'driversLicense',
      'phone',
      'streetAddress',
      'homeAddress',
      'city',
      'state',
      'postCode',
      'gender',
      'dateOfBirth',
    ]).then(canGoNext => {
      dispatch(actions.answerGoTo(canGoNext))
    })
  }, [actions, dispatch, requestingGoTo, trigger])
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <SelectGender name="gender" />
      </Grid>
      <Grid item xs={12} md={6}>
        <SelectDateOfBirth name="dateOfBirth" />
      </Grid>

      <Grid item xs={12}>
        <ControlledTextField
          name="driversLicense"
          rules={defaultRules}
          label="Drivers license number/National ID"
        />
      </Grid>

      <Grid item xs={12}>
        <ReadOnlyInput name="emailAddress" />
      </Grid>

      <Grid item xs={12}>
        <ControlledTextField name="phone" {...phoneRule} />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField
          name="streetAddress"
          rules={defaultRules}
          label="Street address"
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField
          name="homeAddress"
          label="Apt, suite, etc (Optional)"
          rules={defaultRulesOptional}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <ControlledTextField name="city" rules={defaultRules} label="City" />
      </Grid>
      <Grid item xs={12} md={4}>
        <SelectState name="state" />
      </Grid>
      <Grid item xs={12} md={4}>
        <ControlledTextField name="postCode" {...defaultPostalCodeInput} />
      </Grid>
    </Grid>
  )
}
