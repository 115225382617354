import {
  Box,
  Breakpoint,
  Container,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React from 'react'
import { useHistory } from 'react-router'

const logo = require('./../../pages/Auth/assets/logo.png')

interface IHalfLayout {
  paddingTopRightHandSide?: number
  title?: string
  container?: {
    style: React.CSSProperties
    maxWidth: Breakpoint | false
  }
}

const HalfLayout: React.FC<IHalfLayout> = props => {
  const history = useHistory()
  const theme = useTheme()
  const matchXs = useMediaQuery(theme.breakpoints.only('xs'))
  return (
    <SurverWrapper>
      <LeftCol>
        {/* just hidden back home button  */}
        {!matchXs && (
          <>
            <Logo onDoubleClick={() => history.push('/')}>
              <img src={logo.default} alt="logo" />
            </Logo>
            <MainLeftTitle>{props.title}</MainLeftTitle>
          </>
        )}
      </LeftCol>
      <RightCol>
        <Container
          component="main"
          maxWidth={props?.container?.maxWidth ?? 'sm'}
          style={props?.container?.style}
        >
          <Box
            sx={{
              flex: 1,
              marginTop: props?.paddingTopRightHandSide ?? 10,
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'hidden',
            }}
          >
            {props.children}
          </Box>
        </Container>
      </RightCol>
    </SurverWrapper>
  )
}

export default HalfLayout

const MainLeftTitle = styled('h1')`
  font-weight: bold;
  font-size: 50px;
  line-height: 60px;
  color: #ffffff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 76px;
`

const Logo = styled('div')(() => ({
  position: 'absolute',
  top: '32px',
  left: '76px',
}))

const SurverWrapper = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '50% 50%',
  overflowY: 'hidden',
  height: '100vh',
  [theme.breakpoints.only('xs')]: {
    gridTemplateColumns: '100%',
    gridTemplateRows: '0% 100%',
    height: '100%',
    minHeight: '-webkit-fill-available',
  },
}))

const LeftCol = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.primary.main,
  [theme.breakpoints.only('xs')]: {
    // display: 'none',
  },
}))
const RightCol = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  overflowY: 'hidden',
}))
