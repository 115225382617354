/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import CssBaseline from '@mui/material/CssBaseline'
import * as React from 'react'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import * as ReactDOM from 'react-dom'
import { HelmetProvider } from 'react-helmet-async'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
// Use consistent styling
import { App } from './app'
// Initialize languages
import './locales/i18n'
import reportWebVitals from './reportWebVitals'
import { store } from './store'
import { ThemeProvider } from './styles/theme/ThemeProvider'
import './styles/toastify.css'

const MOUNT_NODE = document.getElementById('root') as HTMLElement

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider>
      <HelmetProvider>
        <React.StrictMode>
          <CssBaseline />
          <App />
          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            className="toast-container"
          />
        </React.StrictMode>
      </HelmetProvider>
    </ThemeProvider>
  </Provider>,
  MOUNT_NODE,
)

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(['./locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  })
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
