import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from '@mui/material'
import React, { FC, useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { AdminApprovalFlowProps } from '../../../../../store/slices/step/interface'
import { ApproveContractDto } from '../../../../../store/types'
import { boxCheckboxSx } from '../../admin-manual/steps/Step8'
import { ControlledTextField } from '../../components/ControlledInput'
import { defaultRules, defaultRulesOptional } from '../../defaultRules'

interface CheckboxAdminApprovalFlowProps extends AdminApprovalFlowProps {
  isManual: boolean
}

export const Step2AdminApprovalFlow: FC<CheckboxAdminApprovalFlowProps> = ({
  actions,
  state,
  isManual,
}) => {
  const dispatch = useDispatch()
  const { requestingGoTo } = state

  const { control, trigger } = useFormContext<ApproveContractDto>()

  useEffect(() => {
    if (!requestingGoTo) {
      return
    }

    trigger([
      'product.trailerType',
      'product.registrationNumber',
      'product.modelNumber',
      'product.serialNumber',
      'product.contractNumber',
      'product.trNumber',
      'product.trailerLength',
      'product.trailerWidth',
    ]).then(canGoNext => dispatch(actions.answerGoTo(canGoNext)))
  }, [actions, dispatch, requestingGoTo, trigger])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ControlledTextField
          name="product.trailerType"
          rules={defaultRules}
          label="Trailer Type"
          autoFocus
        />
      </Grid>

      <Grid item xs={12}>
        <ControlledTextField
          name="product.registrationNumber"
          label="Registration Number"
        />
      </Grid>

      <Grid item xs={12}>
        <ControlledTextField
          name="product.modelNumber"
          rules={defaultRules}
          label="Model Number"
        />
      </Grid>

      <Grid item xs={12}>
        <ControlledTextField
          name="product.serialNumber"
          label="Serial Number"
        />
      </Grid>

      <Grid item xs={12}>
        <ControlledTextField
          name="product.contractNumber"
          rules={defaultRulesOptional}
          label="Original Contract Number (Optional)"
        />
      </Grid>

      <Grid item xs={12}>
        <ControlledTextField
          name="product.trNumber"
          rules={defaultRulesOptional}
          label="TR (policy) number"
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <ControlledTextField
          name="product.trailerLength"
          rules={defaultRules}
          label="Length"
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <ControlledTextField
          name="product.trailerWidth"
          rules={defaultRules}
          label="Width"
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="customOrder"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <Box sx={boxCheckboxSx}>
                <FormControl>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={value}
                          onChange={onChange}
                          name="term"
                          sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }}
                          disabled={!isManual}
                        />
                      }
                      label={
                        <Typography variant="body2" sx={{ ml: 1 }}>
                          This trailer is a special custom order.
                        </Typography>
                      }
                    />
                  </FormGroup>
                </FormControl>
              </Box>
            )
          }}
        />
      </Grid>
    </Grid>
  )
}
