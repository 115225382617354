import { Box, Typography } from '@mui/material'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useHistory, useParams } from 'react-router'
import { toast } from 'react-toastify'
import { API, ErrorResponse } from '../../../apis'
import { AuthResponses } from '../../../apis/endpoints/auth'
import { ChangePasswordDto } from '../../../store/types'
import { onError } from '../../../utils/helperFunction'
import { routerAuth } from '../../../utils/routerPath'
import { Button } from '../../components/Button'
import Password from '../../components/Password'

type FormValues = {
  password: string
  confirmPassword: string
}

const defaultValues: FormValues = {
  password: '',
  confirmPassword: '',
}

const ChangePassword = () => {
  const { token } = useParams<{ token: string }>()
  const history = useHistory()
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues,
  })

  const { mutate, isLoading } = useMutation<
    AuthResponses['changePassword'],
    ErrorResponse,
    ChangePasswordDto
  >(API.Auth.changePassword, {
    onSuccess: () => {
      toast('Password successfully updated!', { position: 'top-right' })
      history.push(`${routerAuth.login}`, { from: 'resetPass' })
    },
    onError,
  })

  const handleLogin = handleSubmit(({ password }) => {
    mutate({ password, token })
  })

  return (
    <form onSubmit={handleLogin}>
      <Typography variant="h2" textAlign="left">
        Reset your password
      </Typography>
      <Box component="div" sx={{ mt: 2 }}>
        <Controller
          control={control}
          name="password"
          rules={{
            required: 'Required',
            minLength: {
              value: 8,
              message: 'Password must have at least 8 characters',
            },
            pattern: {
              value: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*/,
              message:
                'Minimum of 1 upper case letter, 1 lower case letter, 1 number',
            },
          }}
          render={({ field: { value, onChange, onBlur } }) => (
            <Password
              {...{ value, onChange, onBlur }}
              error={!!errors.password}
            />
          )}
        />
        <Controller
          control={control}
          name="confirmPassword"
          rules={{
            required: 'Required',
            validate: {
              confirm: confirmPassword =>
                confirmPassword === watch('password') ||
                'The two passwords that you entered do not match!',
            },
          }}
          render={({ field: { value, onChange, onBlur } }) => (
            <Password
              {...{ value, onChange, onBlur }}
              label="Confirm password"
              error={!!errors.confirmPassword}
            />
          )}
        />
        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 3, minWidth: 41 }}
          loading={isLoading}
        >
          Reset Password
        </Button>
      </Box>
    </form>
  )
}

export default ChangePassword
