import React from 'react'
import NumberFormat from 'react-number-format'
export const defaultPropsNumberFormat = {
  decimalScale: 0,
  allowLeadingZeros: true,
  allowNegative: false,
}

const BSBInput = React.forwardRef(function BSBInput(props: any, ref) {
  const { value, onChange, onBlur, ...rest } = props

  return (
    <NumberFormat
      getInputRef={ref}
      onValueChange={({ value }) => {
        return onChange(value ? value : null)
      }}
      format="###-###"
      {...{ value, onBlur }}
      {...defaultPropsNumberFormat}
      {...rest}
    />
  )
})
export const AccountNumberInput = React.forwardRef(function AccountNumberInput(
  props: any,
  ref,
) {
  const { value, onChange, onBlur, ...rest } = props
  return (
    <NumberFormat
      getInputRef={ref}
      onValueChange={({ value }) => {
        return onChange(value ? value : null)
      }}
      format="### ### ###"
      {...{ value, onBlur }}
      {...defaultPropsNumberFormat}
      {...rest}
    />
  )
})

export const CardNumberInput = React.forwardRef(function CardNumberInput(
  props: any,
  ref,
) {
  const { value, onChange, onBlur, ...rest } = props

  return (
    <NumberFormat
      getInputRef={ref}
      onValueChange={({ value }) => {
        return onChange(value ? value : null)
      }}
      format="#### #### #### ####"
      {...{ value, onBlur }}
      {...defaultPropsNumberFormat}
      {...rest}
    />
  )
})
export const CVVInput = React.forwardRef(function CardNumberInput(
  props: any,
  ref,
) {
  const { value, onChange, onBlur, ...rest } = props

  return (
    <NumberFormat
      getInputRef={ref}
      onValueChange={({ value }) => {
        return onChange(value ? value : null)
      }}
      format="###"
      {...{ value, onBlur }}
      {...defaultPropsNumberFormat}
      {...rest}
    />
  )
})

export const ExpiryInput = React.forwardRef(function ExpiryInput(
  props: any,
  ref,
) {
  const { value, onChange, onBlur, ...rest } = props
  return (
    <NumberFormat
      getInputRef={ref}
      onValueChange={({ formattedValue }) => {
        onChange(formattedValue ? formattedValue : null)
      }}
      decimalScale={0}
      allowNegative={false}
      {...{ value, onBlur }}
      format="##/##"
      {...rest}
    />
  )
})

export default BSBInput
