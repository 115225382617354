import {
  CreateContractDto,
  CreateReferenceDto,
  ExtCreateCreditCardDto,
  FulfillCustomerDto,
} from '../../../store/types'

export const defaultReference: Omit<CreateReferenceDto, 'referencesType'> = {
  firstName: '',
  lastName: '',
  middleName: '',
  driversLicense: '',
  relationship: '',
  streetAddress: '',
  homeAddress: '',
  city: '',
  state: '',
  postCode: '',
  contactPerson: '',
  phone: '',
  businessName: '',
}

const creditCardInfo: ExtCreateCreditCardDto = {
  cardName: null,
  cardNumber: null,

  expiry: '',
  exp_month: null,
  exp_year: null,
  cvv: null,
  paymentMethodId: null,
}
export const bankAccountInfo = {
  bankAccountName: '',
  bankAccountNo: '',
  bsb: '',
  paymentMethod: '',
  setupIntentId: '',
  paymentMethodId: null,
}
export const defaultValuesCustomerFlow: FulfillCustomerDto = {
  homeAddress: '',
  businessName: '',
  businessABN: '',
  businessPhone: '',
  businessEmail: '',
  businessStreetAddress: '',
  businessAddress: '',
  businessCity: '',
  businessState: '',
  businessPostCode: '',
  bankAccountInfo,
  creditCardInfo: creditCardInfo,
  references: [
    { ...defaultReference, referencesType: 'MEMBER_FAMILY_ONE' },
    { ...defaultReference, referencesType: 'MEMBER_FAMILY_TWO' },
    { ...defaultReference, referencesType: 'FRIEND_ONE' },
    { ...defaultReference, referencesType: 'FRIEND_TWO' },
    { ...defaultReference, referencesType: 'EMPLOYER' },
  ],
  documents: [
    { docType: 'DRIVER_LICENSE_FRONT', docUrl: '' },
    { docType: 'DRIVER_LICENSE_BACK', docUrl: '' },
    { docType: 'MOTOR_VEHICLE_REGO', docUrl: '' },
    { docType: 'BANK_ACCOUNT', docUrl: '' },
    { docType: 'CREDIT_CARD', docUrl: '' },
    { docType: 'MEDICARE_CARD', docUrl: '' },
  ],
  streetAddress: '',
  city: '',
  state: '',
  postCode: '',
  phone: '',
  emailAddress: '',
  firstName: '',
  middleName: '',
  lastName: '',
  driversLicense: '',
  gender: 'U',
  dateOfBirth: null,
}

export const defaultValuesContract: CreateDto = {
  customer: {
    firstName: '',
    middleName: '',
    lastName: '',
    emailAddress: '',
  },

  /** @format date-time */
  paymentType: 'WEEKLY',
  product: {
    trailerType: '',
    registrationNumber: '',
    modelNumber: '',
    serialNumber: '',
    contractNumber: '',
    trNumber: '',
    trailerLength: '',
    trailerWidth: '',
    cost: null,
  },
  start: null,
  registrationFee: null,
  retailPrice: null,
  interestRate: 0.5,
  topupAmount: 0,
  startDate: new Date(),
}
export type CreateDto = Omit<CreateContractDto, 'startDate'> & {
  startDate: Date
}

export const defaultValuesAdminManual = {
  ...defaultValuesContract,
  customer: { ...defaultValuesCustomerFlow, ...defaultValuesContract.customer },
}
