import { ButtonBase, IconButton, styled as styledMui } from '@mui/material'
import styled from 'styled-components'

export const Container = styledMui('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'rgba(226, 233, 251, 0.25)',
})

export const DeleteButton = styledMui(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: 9,
  right: 11,
  zIndex: 1,
  color: theme.palette.primary.light,
  border: '1px solid',
  borderColor: theme.palette.primary.light,
  borderRadius: 0,
  padding: 1,
  [theme.breakpoints.only('xs')]: {
    padding: 3,
    top: 10,
    right: 10,
    marginRight: 0,
  },
}))

export const Thumbnail = styledMui(ButtonBase)(({ theme }) => ({
  backgroundColor: 'rgba(226, 233, 251, 0.25)',
  width: '100%',
  aspectRatio: '187 / 134',
  [theme.breakpoints.only('xs')]: {
    aspectRatio: '165 / 134',
  },
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: 'rgba(226, 233, 251, 0.25)',
  },
}))

export const Preview = styled('div')<{
  source: string
  matchXs?: boolean
}>`
  background: url(${props => props.source});
  width: 100%;
  aspect-ratio: ${props => (props.matchXs ? 165 / 134 : 187 / 134)};
  background-size: cover;
  background-position: center;
  @media screen and (max-width: 500px) {
    height: 134px;
    min-height: 134px;
  }
`

export const InfoBar = styledMui('div')`
  background: white;
  padding: 10px 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid rgba(226, 233, 251, 0.25);
`

export const Title = styledMui('span')(({ theme }) => ({
  fontWeight: 600,
  color: theme.palette.text.primary,
}))

export const Description = styledMui('span')(({ theme }) => ({
  fontWeight: 400,
  color: theme.palette.secondary.contrastText,
}))

export const LinkButton = styledMui(ButtonBase)(({ theme }) => ({
  color: theme.palette.primary.main,
  width: 'fit-content',
  fontWeight: 400,
  fontSize: '14px',
}))
