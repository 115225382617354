import { FormControl, MenuItem, TextField } from '@mui/material'
import React, { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import commonConstant from '../../../../constants/common.constant'

export function generateDaysOfMonth(
  daysInMonth: number = 31,
): { title: string; value: number }[] {
  const daysList: { title: string; value: number }[] = []

  for (let i = 1; i <= daysInMonth; i++) {
    let suffix = 'th'
    const j = i % 10,
      k = i % 100
    if (j === 1 && k !== 11) {
      suffix = 'st'
    } else if (j === 2 && k !== 12) {
      suffix = 'nd'
    } else if (j === 3 && k !== 13) {
      suffix = 'rd'
    }

    // Generating the value string in the format "1ST_OF_MONTH", "2ND_OF_MONTH", etc.
    // const valueSuffix =
    //   `${i}`.toUpperCase() + suffix.toUpperCase() + '_OF_MONTH'

    daysList.push({
      title: `The ${i}${suffix} day of the month`,
      value: i,
    })
  }

  return daysList
}

export const getList = (
  paymentType: ValueOf<typeof commonConstant.PAYMENT_TYPE>,
) => {
  switch (paymentType) {
    case commonConstant.PAYMENT_TYPE.WEEKLY:
    case commonConstant.PAYMENT_TYPE.FORTNIGHT:
      return [
        {
          title: 'Sunday',
          value: 0,
        },
        {
          title: 'Monday',
          value: 1,
        },
        {
          title: 'Tuesday',
          value: 2,
        },
        {
          title: 'Wednesday',
          value: 3,
        },
        {
          title: 'Thursday',
          value: 4,
        },
        {
          title: 'Friday',
          value: 5,
        },
        {
          title: 'Saturday',
          value: 6,
        },
      ]
    case commonConstant.PAYMENT_TYPE.MONTHLY:
      return generateDaysOfMonth(28)
    default:
      return []
  }
}

const PaymentDayDate: React.FC = () => {
  const { control, watch } = useFormContext()
  const paymentType = watch('paymentType')
  const arrPaymentType = useMemo(() => {
    return getList(paymentType)
  }, [paymentType])

  if (paymentType === commonConstant.PAYMENT_TYPE.DAILY) {
    return null
  }

  return (
    <Controller
      name="start"
      control={control}
      rules={{
        validate: value => {
          if (paymentType === commonConstant.PAYMENT_TYPE.DAILY) {
            return true
          }
          if (isNaN(value)) {
            return 'Required'
          }
          return true
        },
      }}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { error },
      }) => {
        return (
          <FormControl fullWidth sx={{ marginTop: 2 }}>
            <TextField
              select
              id="demo-simple-select"
              {...{ value, onChange, onBlur }}
              label="When"
              error={!!error}
              InputLabelProps={{ shrink: true }}
            >
              {arrPaymentType.map(item => {
                return (
                  <MenuItem key={item.title} value={item.value}>
                    {item.title}
                  </MenuItem>
                )
              })}
            </TextField>
          </FormControl>
        )
      }}
    />
  )
}

export default PaymentDayDate
