import {
  IconButton,
  Paper,
  StandardTextFieldProps,
  styled,
  TextField,
} from '@mui/material'
import React from 'react'
import { Button } from '../../components/Button'

export const StyleIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  color: 'white',
  position: 'absolute',
  right: '56px',
  top: '32px',
  marginRight: '0',
}))
export const CommenceButton = styled(Button)(({ theme }) => ({
  marginRight: '27px',
  padding: '11px 50px',
  backgroundColor: theme.palette.primary.dark,
}))
export const StylePaper = styled(Paper)(() => ({
  backgroundColor: 'rgba(226, 233, 251, 0.25)',
  border: '1px solid rgba(83, 95, 120, 0.2)',
  position: 'relative',
  borderRadius: 16,
}))
export const TitleFamily = styled('p')(() => ({
  fontSize: '1.15rem',
  marginTop: '1rem',
  marginBottom: 0,
  fontWeight: 'bold',
}))
const StyleText2 = (textFieldProps: StandardTextFieldProps) => {
  return (
    <TextField
      {...textFieldProps}
      InputProps={{
        readOnly: true,
      }}
      // disabled
      fullWidth
      variant="outlined"
      value={textFieldProps.value || ''}
    />
  )
}

export const StyleText = styled(StyleText2)(({ theme }) => ({
  zIndex: 1,
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#DDE6FF',
  },

  '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent !important',
    color: 'black',
  },

  '& .MuiInputBase-input-MuiOutlinedInput-input': {
    color: theme.palette.text.primary,
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: 'inherit',
  },

  '&.MuiOutlinedInput-root.Mui-focused': {
    borderColor: 'black !important',
  },
}))
