import { Box } from '@mui/material'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { toast } from 'react-toastify'
import { API, ErrorResponse } from '../../../apis'
import {
  ContractsQueryKeys,
  ContractsResponses,
} from '../../../apis/endpoints/contracts'
import { useAppSelector } from '../../../store/hooks'
import { useStepSlice } from '../../../store/slices/step'
import { createRenderSteps } from '../../../store/slices/step/values'
import { CreateContractDto, IdParam } from '../../../store/types'
import { Button } from '../../components/Button'
import StepBar from '../../components/StepBar'
import HalfLayout from '../../layout/HalfLayout'
import { onError, onExitBrowser } from './../../../utils/helperFunction'
import TitleMenu from './../../components/TitleMenu'
import {
  BoxExitButton,
  ExitButton,
  SaveAndExitButton,
} from './components/ExitButton'
import ExitEditModal from './components/ExitEditModal'
import ExitModal from './components/ExitModal'
import { BottomBar } from './components/Styles'
import { response2UpdateDto } from './convertResponse'
import { CreateDto, defaultValuesContract } from './defaultValue'

const AddContract = () => {
  const history = useHistory()
  const { contractId } = useParams<{ contractId?: string }>()
  const dispatch = useDispatch()
  const { actions } = useStepSlice()
  const state = useAppSelector(state => state.step)
  const step = useAppSelector(state => state.step.current)
  const canSave = useAppSelector(state => state.step.canSave)
  const requestingGoTo = useAppSelector(state => state.step.requestingGoTo)
  const validating = requestingGoTo !== undefined
  const latestStep = useAppSelector(state => state.step.latestStep)
  const STEPS = createRenderSteps({ actions, state })

  const [openModal, setOpenModal] = useState({
    exit: false,
    saveAndExit: false,
  })

  const id = parseInt(contractId, 10)
  const { data } = useQuery<
    ContractsResponses['getById'],
    ErrorResponse,
    ContractsResponses['getById'],
    [string, IdParam]
  >([ContractsQueryKeys.getById, { id }], API.Contracts.getById, {
    enabled: !!id,
    retry: false,
  })

  // TODO: disable all fields why loading contract data

  const formMethods = useForm<CreateDto>({
    defaultValues: defaultValuesContract,
    mode: 'onSubmit',
  })
  const {
    handleSubmit,
    reset,
    formState: { dirtyFields },
  } = formMethods
  const isDirty = Object.keys(dirtyFields).length > 0
  useEffect(() => {
    if (!data?.data) {
      return
    }

    const updateDto = response2UpdateDto(data.data)
    reset({
      ...updateDto,
      customOrder: updateDto.customOrder,
      startDate: new Date(updateDto.startDate),
    })
  }, [data?.data, reset])

  React.useEffect(() => {
    onExitBrowser(isDirty)
  }, [isDirty])

  const { mutate: create, isLoading: creating } = useMutation<
    ContractsResponses['createAdminFlowContract'],
    ErrorResponse,
    CreateContractDto
  >(API.Contracts.createAdminFlowContract, {
    onSuccess: () => {
      toast('Create contract successfully')
      handleExit()
    },
    onError,
  })

  const { mutate: update, isLoading: updating } = useMutation<
    ContractsResponses['updateAdminFlow'],
    ErrorResponse,
    CreateContractDto & IdParam
  >(API.Contracts.updateAdminFlow, {
    onSuccess: () => {
      toast('Update contract successfully')
      handleExit()
    },
    onError,
  })

  const handleSubmitForm = handleSubmit((contractDto: CreateDto) => {
    if (!id) {
      create({
        ...contractDto,
        customer: {
          ...contractDto.customer,
          emailAddress: contractDto.customer.emailAddress.toLowerCase(),
        },
        startDate: new Date(contractDto.startDate.toUTCString()).toISOString(),
      })
    } else {
      update({
        ...contractDto,
        customer: {
          ...contractDto.customer,
          emailAddress: contractDto.customer.emailAddress.toLowerCase(),
        },
        product: {
          ...contractDto.product,
        },
        startDate: new Date(contractDto.startDate.toUTCString()).toISOString(),
        id,
      })
    }
  })

  const handleSubmitForm1 = handleSubmit((contractDto: CreateDto) => {
    contractDto.isSendEmailNotification = true
    if (!id) {
      create({
        ...contractDto,
        customer: {
          ...contractDto.customer,
          emailAddress: contractDto.customer.emailAddress.toLowerCase(),
        },
        startDate: new Date(contractDto.startDate.toUTCString()).toISOString(),
      })
    } else {
      update({
        ...contractDto,
        customer: {
          ...contractDto.customer,
          emailAddress: contractDto.customer.emailAddress.toLowerCase(),
        },
        product: {
          ...contractDto.product,
        },
        startDate: new Date(contractDto.startDate.toUTCString()).toISOString(),
        id,
      })
    }
  })

  const handleExit = () => {
    dispatch(actions.exitStep())
    id ? history.push(`/contract/${id}`) : history.push('/')
  }
  const handleSaveAndExit = () => {
    handleSubmitForm()
  }

  const onClickNext = () => {
    dispatch(actions.requestNext())
    dispatch(actions.requestSave(false))
  }

  useEffect(() => {
    if (canSave === true) {
      setOpenModal(prev => ({ ...prev, saveAndExit: true }))
    }
  }, [canSave])
  const onClickExit = () => {
    if (isDirty) {
      setOpenModal(prev => ({ ...prev, exit: true }))
    } else {
      handleExit()
    }
  }

  const onClickExportPDF = () => {
    const header = document.getElementById('trailer-buyout-pdf-header')
    header.style.display = 'block'
    const d = document.getElementById('trailer-buyout-pdf')
    html2canvas(d).then(canvas => {
      // @ts-ignore
      var img = canvas.toDataURL('image/png')
      var doc = new jsPDF('portrait', 'pt', 'A4')

      // @ts-ignore
      var width = doc.internal.pageSize.getWidth()
      var height = doc.internal.pageSize.getHeight()

      let widthRatio = width / canvas.width
      let heightRatio = height / canvas.height

      let ratio = widthRatio > heightRatio ? heightRatio : widthRatio

      doc.addImage(
        img,
        'PNG',
        50,
        50,
        canvas.width * ratio - 100,
        canvas.height * ratio - 100,
      )
      doc.save('trailer-buy-out-schedule.pdf')
      header.style.display = 'none'
    })
  }

  const handleKeyUp = event => {
    if (event.key === 'Enter' && step !== STEPS.length - 1) {
      onClickNext()
    }
  }
  return (
    <FormProvider {...formMethods}>
      <HalfLayout
        title={
          STEPS[step]?.leftTitle
            ? STEPS[step].leftTitle
            : `Add our customer ${STEPS[step].title.toLowerCase()}`
        }
      >
        <BoxExitButton>
          {step === 1 ? (
            <ExitButton onClick={onClickExportPDF} type="button">
              Export PDF
            </ExitButton>
          ) : (
            ''
          )}
          <ExitButton onClick={onClickExit} type="button">
            Exit
          </ExitButton>
          <SaveAndExitButton
            // disabled={id? : lastestStep !== STEPS.length - 1}
            loading={validating}
            sx={{ backgroundColor: 'success.main', color: 'white' }}
            onClick={handleSaveAndExit}
          >
            Save &amp; Exit
          </SaveAndExitButton>
        </BoxExitButton>

        <Box
          component="form"
          onSubmit={handleSubmitForm}
          display="flex"
          flex={1}
          flexDirection="column"
        >
          <TitleMenu
            steps={STEPS}
            current={step}
            latestStep={latestStep}
            onGoTo={index => dispatch(actions.requestGoTo(index))}
          />

          <Box
            onKeyUp={handleKeyUp}
            component="div"
            flex={1}
            px={3}
            sx={{ maxHeight: '70vh', overflowY: 'auto' }}
          >
            {STEPS[step]?.component}
          </Box>
          <BottomBar>
            <StepBar current={step} step={STEPS} />
            <Box
              display="flex"
              flex={1}
              justifyContent="space-between"
              mt={2}
              px={6}
            >
              {step !== 0 ? (
                <Button
                  variant="outlined"
                  onClick={() => dispatch(actions.backStep())}
                >
                  Back
                </Button>
              ) : (
                <div />
              )}
              {step !== STEPS.length - 1 ? (
                <Button
                  variant="contained"
                  loading={validating}
                  onClick={onClickNext}
                >
                  Next
                </Button>
              ) : (
                <Button
                  variant="contained"
                  loading={creating || updating}
                  onClick={handleSubmitForm1}
                >
                  Send Application
                </Button>
              )}
            </Box>
          </BottomBar>
        </Box>
      </HalfLayout>
      <ExitModal
        open={openModal.exit}
        onExit={handleExit}
        handleClose={() => setOpenModal(prev => ({ ...prev, exit: false }))}
      />
      <ExitEditModal
        open={openModal.saveAndExit}
        onExit={handleExit}
        handleClose={() =>
          setOpenModal(prev => ({ ...prev, saveAndExit: false }))
        }
        onSubmit={handleSaveAndExit}
        loading={updating || creating}
      />
    </FormProvider>
  )
}

export default AddContract
