import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import * as React from 'react'
const imgSuccess = require('./../assets/success.png')

export default function SentSuccess() {
  return (
    <Container
      component="main"
      maxWidth="sm"
      sx={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          textAlign: 'center',
        }}
      >
        <img src={imgSuccess.default} alt="success" />
        <Typography component="h1" variant="h2" sx={{ mt: 2, mb: 1 }}>
          Your application has been submitted
        </Typography>
        <Typography
          variant="body2"
          sx={{ fontWeight: 'normal', color: 'text.secondary' }}
        >
          Your application has been sent to the Rent then Buy staff for their
          approval.
        </Typography>
      </Box>
    </Container>
  )
}
