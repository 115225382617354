import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from '@mui/material'
import React, { FC, useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { StepProps } from '../../../../store/slices/step/interface'
import { CreateContractDto } from '../../../../store/types'
import { boxCheckboxSx } from '../admin-manual/steps/Step8'
import { ControlledTextField } from '../components/ControlledInput'
import { defaultRules, defaultRulesOptional } from '../defaultRules'

export const Step1: FC<StepProps> = ({ actions, state }) => {
  const dispatch = useDispatch()
  const { requestingGoTo } = state

  const { control, trigger, watch } = useFormContext<CreateContractDto>()
  useEffect(() => {
    if (!requestingGoTo) {
      return
    }

    trigger([
      'product.trailerType',
      'product.registrationNumber',
      'product.modelNumber',
      'product.serialNumber',
      'product.contractNumber',
      'product.trNumber',
      'product.trailerLength',
      'product.trailerWidth',
      'customOrder',
    ]).then(canGoNext => dispatch(actions.answerGoTo(canGoNext)))
  }, [actions, dispatch, requestingGoTo, trigger])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ControlledTextField
          name="product.trailerType"
          rules={defaultRules}
          label="Trailer Type"
          autoFocus
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField
          name="product.registrationNumber"
          label="Registration Number"
          autoFocus
        />
      </Grid>

      <Grid item xs={12}>
        <ControlledTextField
          name="product.modelNumber"
          rules={defaultRules}
          label="Model Number"
        />
      </Grid>

      <Grid item xs={12}>
        <ControlledTextField
          name="product.serialNumber"
          label="Serial Number"
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField
          name="product.contractNumber"
          rules={defaultRulesOptional}
          label="Original Contract Number (Optional)"
        />
      </Grid>

      <Grid item xs={12}>
        <ControlledTextField
          name="product.trNumber"
          rules={defaultRules}
          label="TR (policy) number"
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <ControlledTextField
          name="product.trailerLength"
          rules={defaultRules}
          label="Length"
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <ControlledTextField
          name="product.trailerWidth"
          rules={defaultRules}
          label="Width"
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="customOrder"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <Box sx={boxCheckboxSx}>
                <FormControl>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={value || watch('customOrder')}
                          onChange={onChange}
                          name="customOrder"
                          sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }}
                        />
                      }
                      label={
                        <Typography variant="body2" sx={{ ml: 1 }}>
                          This trailer is a special custom order.
                        </Typography>
                      }
                    />
                  </FormGroup>
                </FormControl>
              </Box>
            )
          }}
        />
      </Grid>
    </Grid>
  )
}
