import secureLocalStorage from 'react-secure-storage'
import commonConstant from '../constants/common.constant'
import { AccessTokenResponse } from '../store/types'

export const KEYS = {
  ACCESS_TOKEN: 'access_token',
  REFRESH_TOKEN: 'refresh_token',
  HALF_CONTRACT_ID: 'half_contract_id',
  HALF_CONTRACT_NAME: 'half_contract_name',
  HALF_CONTRACT_SCORE: 'half_contract_score',
}

export type Tokens = Pick<
  AccessTokenResponse,
  'accessToken' | 'refreshToken' | 'qrcode'
>
export type ContractType = Pick<
  {
    contractId: any
    name: string
    riskScore?: any
  },
  'contractId' | 'name' | 'riskScore'
>

export const isJsonString = str => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export const getFromSecureLocalStorage = key => {
  const value: any = secureLocalStorage.getItem(key)
  return isJsonString(value) ? JSON.parse(value) : value
}

export const setTokens = ({ accessToken, refreshToken }: Tokens) => {
  localStorage.setItem(KEYS.ACCESS_TOKEN, accessToken)
  localStorage.setItem(KEYS.REFRESH_TOKEN, refreshToken)
}

export const getTokens = (): Tokens => {
  const accessToken = localStorage.getItem(KEYS.ACCESS_TOKEN)
  const refreshToken = localStorage.getItem(KEYS.REFRESH_TOKEN)
  return { accessToken, refreshToken }
}

export const clearTokens = (): void => {
  localStorage.removeItem(KEYS.ACCESS_TOKEN)
  localStorage.removeItem(KEYS.REFRESH_TOKEN)
  secureLocalStorage.removeItem(commonConstant.LOCAL_STORAGE_KEY.USER)
}

export const setHalfContract = ({
  contractId,
  name,
  riskScore = 0,
}: ContractType) => {
  localStorage.setItem(KEYS.HALF_CONTRACT_ID, contractId)
  localStorage.setItem(KEYS.HALF_CONTRACT_NAME, name)
  localStorage.setItem(KEYS.HALF_CONTRACT_SCORE, riskScore)
}

export const getHalfContract = (): ContractType => {
  const contractId = localStorage.getItem(KEYS.HALF_CONTRACT_ID)
  const name = localStorage.getItem(KEYS.HALF_CONTRACT_NAME)
  const riskScore = localStorage.getItem(KEYS.HALF_CONTRACT_SCORE)
  return { contractId, name, riskScore }
}

export const clearHalfContract = (): void => {
  localStorage.removeItem(KEYS.HALF_CONTRACT_ID)
  localStorage.removeItem(KEYS.HALF_CONTRACT_NAME)
  localStorage.removeItem(KEYS.HALF_CONTRACT_SCORE)
}
