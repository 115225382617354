import { AxiosError } from 'axios'
import { Auth } from './endpoints/auth'
import { Configs } from './endpoints/configs'
import { Contracts } from './endpoints/contracts'
import { Customers } from './endpoints/customer'
import { Medias } from './endpoints/media'
import { Payment } from './endpoints/payment'

export type ErrorResponse = AxiosError<{
  error?: string
  message: string
  statusCode: number
}>

export const API = {
  Auth,
  Contracts,
  Customers,
  Medias,
  Payment,
  Configs,
}
