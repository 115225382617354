import { Grid } from '@mui/material'
import React, { FC, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../../../store/hooks'
import { AdminManualFlowProps } from '../../../../../store/slices/step/interface'
import { ManualContractDto } from '../../../../../store/types'
import { ControlledTextField } from '../../components/ControlledInput'
import SelectDateOfBirth from '../../components/DateOfBirth'
import SelectGender from '../../components/SelectGender'
import SelectState from '../../components/SelectState'
import {
  defaultEmailRules,
  defaultPostalCodeInput,
  defaultRules,
  defaultRulesOptional,
  nameRules,
  phoneRule,
} from '../../defaultRules'

export const Step0AdminManualFlow: FC<AdminManualFlowProps> = ({ actions }) => {
  const dispatch = useDispatch()
  const requestingGoTo = useAppSelector(
    state => state.adminManualFlowSteps.requestingGoTo,
  )

  const { trigger } = useFormContext<ManualContractDto>()

  useEffect(() => {
    if (!requestingGoTo) {
      return
    }

    trigger([
      'customer.emailAddress',
      'customer.firstName',
      'customer.lastName',
      'customer.phone',
      'customer.driversLicense',
      'customer.middleName',
      'customer.streetAddress',
      'customer.homeAddress',
      'customer.city',
      'customer.state',
      'customer.postCode',
      'customer.gender',
      'customer.dateOfBirth',
    ]).then(canGoNext => dispatch(actions.answerGoTo(canGoNext)))
  }, [actions, dispatch, requestingGoTo, trigger])
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <ControlledTextField
            name="customer.firstName"
            rules={nameRules}
            label="First Name"
            autoFocus
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <ControlledTextField
            name="customer.middleName"
            rules={nameRules}
            label="Middle Name"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <ControlledTextField
            rules={nameRules}
            name="customer.lastName"
            label="Last Name"
          />
        </Grid>
        <Grid item xs={12} md={6}></Grid>

        <Grid item xs={12} md={6}>
          <SelectGender name="customer.gender" />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectDateOfBirth name="customer.dateOfBirth" />
        </Grid>

        <Grid item xs={12}>
          <ControlledTextField
            name="customer.emailAddress"
            rules={defaultEmailRules}
            label="Email address"
          />
        </Grid>

        <Grid item xs={12}>
          <ControlledTextField
            name="customer.driversLicense"
            rules={defaultRules}
            label="Drivers license number/National ID"
          />
        </Grid>

        <Grid item xs={12}>
          <ControlledTextField name="customer.phone" {...phoneRule} />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            name="customer.streetAddress"
            rules={defaultRules}
            label="Street address"
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            name="customer.homeAddress"
            label="Apt, suite, etc (Optional)"
            rules={defaultRulesOptional}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ControlledTextField
            name="customer.city"
            rules={defaultRules}
            label="City"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <SelectState name="customer.state" />
        </Grid>
        <Grid item xs={12} md={4}>
          <ControlledTextField
            name="customer.postCode"
            {...defaultPostalCodeInput}
          />
        </Grid>
      </Grid>
    </div>
  )
}
