export const routerAuth = {
  login: '/auth/login',
  forgotPassword: '/auth/forgot-password',
  changePassword: '/auth/change-password',
}
export const routerAdminFlow = {
  addContract: '/contract/add',
  customerFlow: '/contract/customer-flow',
  sentSuccess: '/contract/sent-success',
  contract: '/contract',
  adminApproval: '/contract/admin-approval',
  adminManual: '/contract/admin-manual',
  verifyAccount: '/verify-account',
  notFound: '/not-found',
}
