import React from 'react'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'
import { useAppSelector } from '../../../store/hooks'
import { routerAuth } from '../../../utils/routerPath'
import HalfLayout from '../../layout/HalfLayout'
import ChangePassword from './ChangePassword'
import ForgotPassword from './ForgotPassword'
import Login from './Login'

const Auth = () => {
  const history = useHistory()
  const isAuthorized = useAppSelector(state => state.auth?.authorized)

  React.useEffect(() => {
    if (history.location.pathname.includes(routerAuth.changePassword)) {
    } else if (isAuthorized && history.location.pathname.includes('/auth')) {
      history.push('/')
    }
  }, [isAuthorized, history])

  return (
    <HalfLayout paddingTopRightHandSide={15}>
      <Switch>
        <Route path={`${routerAuth.login}`} component={Login} />
        <Route
          exact
          path={`${routerAuth.forgotPassword}`}
          component={ForgotPassword}
        />
        <Route
          path={`${routerAuth.changePassword}/:token`}
          component={ChangePassword}
        />
        <Redirect to={`${routerAuth.login}`} />
      </Switch>
    </HalfLayout>
  )
}

export default Auth
