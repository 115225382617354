import { Grid } from '@mui/material'
import React from 'react'
import {
  ButtonContained,
  ButtonOutline,
  DialogActionsStyle,
  DialogContentStyle,
  DialogTitleStyle,
  SuccessDialogStyle,
} from './Styles'

const EditModal: React.FC<{
  open: boolean
  handleClose: () => void
  onSuccess: (arg) => void
  loading: boolean
}> = ({ open, handleClose, loading, onSuccess }) => {
  return (
    <SuccessDialogStyle open={open} onClose={handleClose}>
      <DialogTitleStyle>Edit contract</DialogTitleStyle>
      <DialogContentStyle>
        Clicking the confirm will Edit the contract
      </DialogContentStyle>
      <DialogActionsStyle disableSpacing={true}>
        <Grid container columnSpacing={{ xs: 1, md: 2 }}>
          <ButtonOutline onClick={handleClose}>Go Back</ButtonOutline>
          <ButtonContained onClick={onSuccess} loading={loading}>
            Confirm
          </ButtonContained>
        </Grid>
      </DialogActionsStyle>
    </SuccessDialogStyle>
  )
}

export default EditModal
