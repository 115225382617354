import {
  ContractResponse,
  CreateDocumentDto,
  CreateReferenceDto,
  DocumentEntity,
  ExpiryAdminManualDto,
  ReferencesEntity,
} from '../../../../store/types'
import { defaultReference } from '../defaultValue'

export const joinExpiry = (exp_month: number, exp_year: number): string => {
  if (!exp_month || !exp_year) {
    return null
  }
  const monthString = exp_month >= 10 ? `${exp_month}` : `0${exp_month}`
  return `${monthString}/${exp_year}`
}

export const response2AdminApproval = (
  data: ContractResponse,
): ExpiryAdminManualDto => {
  if (!data || !data?.customer) {
    return
  }
  const {
    customOrder,
    interestRate,
    customer: {
      homeAddress,
      streetAddress,
      city,
      state,
      postCode,
      phone,
      emailAddress,
      firstName,
      middleName,
      lastName,
      driversLicense,
      businessName,
      businessABN,
      businessPhone,
      businessEmail,
      businessStreetAddress,
      businessAddress,
      businessCity,
      businessState,
      businessPostCode,
      creditCards: [creditCardEntity],
      bankAccounts: [bankAccountEntity],
      documents,
      references,
      riskScore,
      defaults,
      recentApplications,
      judgement,
      bankrupt,
      gender,
      dateOfBirth,
      riskScoreUrl,
    },
    product: {
      trailerType,
      modelNumber,
      serialNumber,
      registrationNumber,
      contractNumber,
      trNumber,
      trailerLength,
      trailerWidth,
      cost,
    },
    contractVersion,
    registrationFee,
    topupAmount,
    start,
  } = data
  const { bankAccountNo, bankAccountName, bsb, paymentMethod, setupIntentId } =
    bankAccountEntity || {
      bankAccountNo: '',
      bankAccountName: '',
      bsb: '',
      paymentMethod: '',
      setupIntentId: '',
    }
  const { cardName, cardNumber, cvv, exp_month, exp_year } =
    creditCardEntity || {
      cardName: '',
      cardNumber: '',
      cvv: '',
      exp_month: null,
      exp_year: null,
    }
  const { retailPrice, startDate, paymentType } = contractVersion

  const adminApprovalConvert: ExpiryAdminManualDto = {
    customOrder,
    interestRate,
    customer: {
      emailAddress,
      firstName,
      driversLicense,
      middleName,
      lastName,
      phone,
      streetAddress,
      homeAddress,
      city,
      state,
      postCode,
      businessName,
      businessABN,
      businessPhone,
      businessEmail,
      businessStreetAddress,
      businessAddress,
      businessCity,
      businessState,
      businessPostCode,
      references: convertReferences(references),
      documents: convertDocuments(documents),
      riskScore,
      riskScoreUrl,
      defaults,
      recentApplications,
      judgement,
      bankrupt,
      gender,
      dateOfBirth,
      bankAccountInfo: {
        bankAccountNo: bankAccountEntity
          ? '**********' + bankAccountNo
          : bankAccountNo,
        bankAccountName,
        bsb,
        paymentMethod,
        setupIntentId,
        paymentMethodId: bankAccountEntity
          ? bankAccountEntity.paymentMethodId
          : null,
      },
      creditCardInfo: {
        cardNumber: '**** **** **** ' + cardNumber,
        cardName,
        cvv: creditCardEntity ? '***' : cvv,
        exp_year,
        exp_month,
        expiry: joinExpiry(exp_month, exp_year),
        paymentMethodId: creditCardEntity
          ? creditCardEntity.paymentMethodId
          : null,
      },
    },
    product: {
      trailerType,
      modelNumber,
      serialNumber,
      registrationNumber,
      contractNumber,
      trNumber,
      trailerLength,
      trailerWidth,
      cost,
    },

    startDate: new Date(startDate),
    paymentType,
    registrationFee,
    retailPrice,
    topupAmount: topupAmount ?? 0,
    start,
  }
  return adminApprovalConvert
}

const convertReferences = (
  references: ReferencesEntity[],
): CreateReferenceDto[] => {
  const familyOne = resolveRef(references, 'MEMBER_FAMILY_ONE')
  const familyTwo = resolveRef(references, 'MEMBER_FAMILY_TWO')
  const friendOne = resolveRef(references, 'FRIEND_ONE')
  const friendTwo = resolveRef(references, 'FRIEND_TWO')
  const employer = resolveRef(references, 'EMPLOYER')
  return [familyOne, familyTwo, friendOne, friendTwo, employer]
}

const resolveRef = (
  references: ReferencesEntity[],
  referencesType: CreateReferenceDto['referencesType'],
): CreateReferenceDto => {
  const reference = references.find(
    ref => ref.referencesType === referencesType,
  )
  if (!reference) {
    return { ...defaultReference, referencesType }
  }
  const {
    id: _,
    customerId: __,
    firstName,
    lastName,
    middleName,
    driversLicense,
    streetAddress,
    city,
    state,
    postCode,
    ...rest
  } = reference
  return {
    ...rest,
    firstName: firstName || '',
    lastName: lastName || '',
    middleName: middleName || '',
    driversLicense: driversLicense || '',
    streetAddress: streetAddress || '',
    city: city || '',
    state: state || '',
    postCode: postCode || '',
    referencesType,
  }
}

const convertDocuments = (documents: DocumentEntity[]): CreateDocumentDto[] =>
  documents.map(doc => {
    const { id: _, customerId: __, ...rest } = doc
    return rest
  })
