import {
  Backdrop,
  ButtonProps,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Paper,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import { styled as styledMui } from '@mui/material/styles'
import { TransitionProps } from '@mui/material/transitions'
import React, { ForwardRefExoticComponent } from 'react'
import styled from 'styled-components'
import { Button, ButtonLoadingProps } from '../../../components/Button'
const imgSuccess: { default: string } = require('./../assets/success.png')
const imgPause: { default: string } = require('./../assets/Pause.svg')
const imgPlay: { default: string } = require('./../assets/Play.svg')
const imgCancel: { default: string } = require('./../assets/Cancel.svg')

export const BottomBar = styledMui('div')(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: '17px',
  backgroundColor: theme.palette.background.paper,
  zIndex: 100,
}))

export const Transition: ForwardRefExoticComponent<any> = React.forwardRef(
  function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="down" ref={ref} {...props} />
  },
)
type PropsSuccessDialog = DialogProps & {
  open: boolean
  onClose: () => void
}

export const SuccessDialogStyle: React.FC<PropsSuccessDialog> = ({
  open,
  onClose,
  ...props
}) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Dialog
      TransitionComponent={Transition}
      keepMounted
      PaperProps={{ sx: { width: 480 } }}
      maxWidth={false}
      fullScreen={fullScreen}
      BackdropComponent={styledMui(Backdrop, {
        name: 'MuiModal',
        slot: 'Backdrop',
        overridesResolver: (props, styles) => {
          return styles.backdrop
        },
      })({ zIndex: -1, backgroundColor: 'rgba(0, 0, 0, 0.8)' })}
      aria-describedby="alert-dialog-slide-description"
      {...{ open, onClose }}
      {...props}
    >
      {props.children}
    </Dialog>
  )
}
export const DialogTitleStyle = styledMui(DialogTitle)(({ theme }) => ({
  fontSize: '32px',
  paddingTop: theme.spacing(3),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  lineHeight: 'initial',
}))

export const DialogContentStyle: React.FC = props => {
  return (
    <DialogContent sx={{ paddingBottom: 0, paddingLeft: 4, paddingRight: 4 }}>
      <DialogContentText
        id="alert-dialog-slide-description"
        sx={{ fontWeight: 'normal' }}
      >
        {props.children}
      </DialogContentText>
    </DialogContent>
  )
}

export const ButtonOutline: React.FC<ButtonProps> = ({
  children,
  ...props
}) => (
  <Grid item xs={6}>
    <Button
      variant="outlined"
      sx={{
        minWidth: '100%',
        color: 'primary.dark',
        borderColor: 'primary.dark',
      }}
      {...props}
    >
      {children}
    </Button>
  </Grid>
)
export const ButtonContained: React.FC<ButtonLoadingProps> = ({
  children,
  ...props
}) => (
  <Grid item xs={6}>
    <Button
      variant="contained"
      sx={{ minWidth: '100%', backgroundColor: 'primary.dark' }}
      autoFocus
      {...props}
    >
      {children}
    </Button>
  </Grid>
)
export const DialogActionsStyle = styledMui(DialogActions)({
  justifyContent: 'normal',
  display: 'block',
  padding: '36px 32px',
})
export const ImgSuccess = () => (
  <Paper sx={{ boxShadow: 'none', padding: '36px 32px 0 32px' }}>
    <img src={imgSuccess.default} alt="success" />
  </Paper>
)
export const ImgPause = () => (
  <Paper sx={{ boxShadow: 'none', padding: '36px 32px 0 32px' }}>
    <img src={imgPause.default} alt="pause" />
  </Paper>
)

export const ImgPlay = () => (
  <Paper sx={{ boxShadow: 'none', padding: '36px 32px 0 32px' }}>
    <img src={imgPlay.default} alt="pause" />
  </Paper>
)

export const ImgCancel = () => (
  <Paper sx={{ boxShadow: 'none', padding: '36px 32px 0 32px' }}>
    <img src={imgCancel.default} alt="cancel" />
  </Paper>
)

export const Whitespace = styled('div')<{
  height: number
}>`
  background: transparent;
  width: 100%;
  height: ${props => `${props.height}px`};
`
