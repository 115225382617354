import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import {
  FormControl,
  FormControlProps,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material'
import React from 'react'

type Props = Omit<FormControlProps, 'onChange' | 'onBlur'> & {
  value?: unknown
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  helperText?: string
  label?: string
}

const Password: React.FC<Props> = ({
  value,
  onChange,
  onBlur,
  error,
  label,
  // helperText,
  ...restProps
}) => {
  const [showPassword, setShowPassword] = React.useState(false)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault()
  }

  return (
    <FormControl
      {...restProps}
      error={error}
      margin="normal"
      fullWidth
      variant="outlined"
    >
      <InputLabel htmlFor="outlined-adornment-password">
        {label ? label : 'Password'}
      </InputLabel>
      <OutlinedInput
        id="outlined-adornment-password"
        type={showPassword ? 'text' : 'password'}
        {...{ value, onChange, onBlur }}
        autoComplete="password"
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        label={label ? label : 'Password'}
      />
      {/* <FormHelperText>{helperText}</FormHelperText> */}
    </FormControl>
  )
}

export default Password
