import { Grid } from '@mui/material'
import React from 'react'
import {
  ButtonContained,
  ButtonOutline,
  DialogActionsStyle,
  DialogContentStyle,
  DialogTitleStyle,
  SuccessDialogStyle,
} from './Styles'

const ExitEditModal: React.FC<{
  open: boolean
  handleClose: () => void
  onExit: () => void
  onSubmit: () => void
  loading: boolean
}> = ({ open, handleClose, onExit, onSubmit, loading }) => {
  return (
    <SuccessDialogStyle open={open} onClose={handleClose}>
      <DialogTitleStyle>
        You have made some changes to the contract
      </DialogTitleStyle>
      <DialogContentStyle>
        An email will be sent to the customer with new information
      </DialogContentStyle>
      <DialogActionsStyle disableSpacing={true}>
        <Grid container columnSpacing={{ xs: 1, md: 2 }}>
          <ButtonOutline onClick={onExit}>Discard changes</ButtonOutline>
          <ButtonContained onClick={onSubmit} loading={loading}>
            Send new contract
          </ButtonContained>
        </Grid>
      </DialogActionsStyle>
    </SuccessDialogStyle>
  )
}

export default ExitEditModal
