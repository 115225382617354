import React from 'react'
import NumberFormat from 'react-number-format'

const PostCodeInput = React.forwardRef(function PostCodeInput(props: any, ref) {
  const { value, onChange, onBlur, ...rest } = props

  return (
    <NumberFormat
      getInputRef={ref}
      required={true}
      value={value}
      type="text"
      onValueChange={({ value }) => {
        onChange(value ? value : null)
      }}
      onBlur={onBlur}
      thousandSeparator={true}
      format="####"
      allowLeadingZeros={true}
      {...rest}
    />
  )
})

export default PostCodeInput
