export const DETAIL_CONTACT_TAB_TYPE = {
  PAYMENTS: 'payments',
  CONTRACT_DETAILS: 'contract-details',
  NOTES: 'notes',
} as const

const commonConstant = {
  LOCAL_STORAGE_KEY: {
    USER: 'USER',
  } as const,
  DETAIL_CONTACT_TAB: [
    {
      type: DETAIL_CONTACT_TAB_TYPE.PAYMENTS,
      label: 'Payments',
    },
    {
      type: DETAIL_CONTACT_TAB_TYPE.CONTRACT_DETAILS,
      label: 'Contract details',
    },
    {
      type: DETAIL_CONTACT_TAB_TYPE.NOTES,
      label: 'Notes',
    },
  ],
  DATE_FORMAT: {
    MMM_DD_YYYY: 'MMM DD, YYYY' /* Apr 12, 2023 */,
    HH_MM_A_MMM_DD_YYYY: 'hh:mm A MMM DD, YYYY' /* Apr 12, 2023 */,
  } as const,
  ROW_PER_PAGE_OPTIONS: [10, 20, 50, 100, 200],
  /* millisecond */
  STALE_TIME: {
    MIN_1: 1000 * 60,
    SEC_20: 1000 * 20,
  } as const,
  PAYMENT_TYPE: {
    DAILY: 'DAILY',
    WEEKLY: 'WEEKLY',
    FORTNIGHT: 'FORTNIGHT',
    MONTHLY: 'MONTHLY',
  } as const,
}

export default commonConstant
