import { Grid } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useElements, useStripe } from '@stripe/react-stripe-js'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { API, ErrorResponse } from '../../../../../apis'
import { PaymentResponses } from '../../../../../apis/endpoints/payment'
import { useAppSelector } from '../../../../../store/hooks'
import { CustomerFlowProps } from '../../../../../store/slices/step/interface'
import { ExtFulfillCustomerDto } from '../../../../../store/types'
import { onError } from '../../../../../utils/helperFunction'
import {
  bankAccountNumberProps,
  bsbInputProps,
  cardNameProps,
  cardNumberProps,
  cvvProps,
  expiryProps,
} from '../../bankInputProps'
import { ControlledTextField } from '../../components/ControlledInput'
import { AntTab, AntTabs, TabPanel } from '../../components/PaymentTab'
import { defaultRules } from '../../defaultRules'

enum TabEnum {
  Bank = 0,
  Card = 1,
}

export const Step2CustomerFlow: FC<CustomerFlowProps> = ({
  actions,
  state,
}) => {
  const dispatch = useDispatch()
  const { requestingGoTo } = state
  const paymentMethod = useAppSelector(
    state => state.customerFlowSteps.paymentMethod,
  )
  const [client_secret, set_client_secret] = useState<string>('')
  const stripe = useStripe()
  const elements = useElements()

  const handleSubmit = async (client_secret: string) => {
    if (!stripe || !elements) {
      return
    }

    const bankAccountName = getValues('bankAccountInfo.bankAccountName')
    const email = getValues('emailAddress')
    const bsb_number = getValues('bankAccountInfo.bsb').toString()
    const account_number = getValues('bankAccountInfo.bankAccountNo').toString()

    const result = await stripe.confirmAuBecsDebitSetup(`${client_secret}`, {
      payment_method: {
        au_becs_debit: {
          bsb_number,
          account_number,
        },
        billing_details: {
          name: bankAccountName,
          email: email,
        },
      },
    })

    if (result.error) {
      const position = 'top-right'
      toast.error(result.error.message, { position })
      dispatch(actions.answerGoTo(false))
    } else {
      const paymentMethod = result.setupIntent.payment_method
      const setupIntentId = result.setupIntent.id
      setValue('bankAccountInfo.paymentMethod', paymentMethod.toString())
      setValue('bankAccountInfo.setupIntentId', setupIntentId)
      // eslint-disable-next-line no-console
      paymentMethod && setupIntentId && dispatch(actions.answerGoTo(true))
    }
  }

  const { mutate: setUpIntent } = useMutation<
    PaymentResponses['setUpIntent'],
    ErrorResponse
  >(API.Payment.setUpIntent, {
    onSuccess: data => {
      set_client_secret(data.data.client_secret)
    },
    onError,
  })

  const handleChange = (event: React.SyntheticEvent, newValue: TabEnum) => {
    dispatch(
      actions.updatePaymentMethod(newValue ? 'creditCard' : 'bankAccount'),
    )
  }
  const tab = useMemo(
    () => (paymentMethod === 'bankAccount' ? TabEnum.Bank : TabEnum.Card),
    [paymentMethod],
  )

  const { trigger, getValues, setValue } =
    useFormContext<ExtFulfillCustomerDto>()
  const theme = useTheme()

  useEffect(() => {
    if (tab === TabEnum.Bank) {
      setUpIntent()
    }
  }, [tab, setUpIntent])

  useEffect(() => {
    if (!requestingGoTo) {
      return
    }
    if (tab === TabEnum.Bank) {
      trigger([
        'bankAccountInfo.bankAccountName',
        'bankAccountInfo.bankAccountNo',
        'bankAccountInfo.bsb',
      ]).then(() => {
        handleSubmit(client_secret)
      })
    } else {
      trigger([
        'creditCardInfo.cardName',
        'creditCardInfo.cardNumber',
        'creditCardInfo.cvv',
        'creditCardInfo.expiry',
      ]).then(canGoNext => dispatch(actions.answerGoTo(canGoNext)))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions, dispatch, requestingGoTo, tab, trigger])
  return (
    <>
      <AntTabs value={tab} onChange={handleChange} aria-label="ant example">
        <AntTab label="Bank details" />
        {/* <AntTab label="Card details" /> */}
      </AntTabs>
      <TabPanel value={tab} index={TabEnum.Bank} dir={theme.direction}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ControlledTextField
              name="bankAccountInfo.bankAccountName"
              rules={defaultRules}
              label="Account name"
              autoFocus
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              name="bankAccountInfo.bsb"
              {...bsbInputProps}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              name="bankAccountInfo.bankAccountNo"
              {...bankAccountNumberProps}
            />
          </Grid>
          <Grid item xs={12} style={{ display: 'none' }}>
            <ControlledTextField
              name="bankAccountInfo.paymentMethod"
              rules={defaultRules}
              label="Payment Method"
              autoFocus
            />
          </Grid>
          <Grid item xs={12} style={{ display: 'none' }}>
            <ControlledTextField
              name="bankAccountInfo.setupIntentId"
              rules={defaultRules}
              label="setupIntentId"
              autoFocus
            />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={tab} index={TabEnum.Card} dir={theme.direction}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ControlledTextField
              name="creditCardInfo.cardName"
              {...cardNameProps}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              name="creditCardInfo.cardNumber"
              {...cardNumberProps}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ControlledTextField
              name="creditCardInfo.expiry"
              {...expiryProps}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ControlledTextField
              name="creditCardInfo.cvv"
              {...cvvProps(false)}
            />
          </Grid>
        </Grid>
      </TabPanel>
    </>
  )
}
