import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom'
import commonConstant from '../constants/common.constant'
import { useAppSelector } from '../store/hooks'
import { lazyLoad } from '../utils/loadable'
import { getFromSecureLocalStorage } from '../utils/localStorage'
import { routerAdminFlow, routerAuth } from '../utils/routerPath'
import { LoadConfigs } from './LoadConfigs'
import Auth from './pages/Auth'
import AddContract from './pages/Contract'
import AdminManualFlow from './pages/Contract/admin-manual/index'
import SentSuccess from './pages/Contract/customer-flow/SentSuccess'
import { HomePage } from './pages/HomePage/Loadable'
import { NotFoundPage } from './pages/NotFoundPage'
const CustomerFlow = lazyLoad(
  () => import('./../app/pages/Contract/customer-flow'),
)
const DetailSendContract = lazyLoad(() => import('./pages/DetailContract'))
const AdminAprovalFlow = lazyLoad(
  () => import('./pages/Contract/admin-approval/index'),
)
const VerifyAccount = lazyLoad(() => import('./pages/Auth/VerifyAccount'))

const queryClient = new QueryClient()

export function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <BrowserRouter>
        <Switch>
          <Route path="/auth" component={Auth} />

          <Route
            path={`${routerAdminFlow.customerFlow}/:id`}
            component={CustomerFlow}
          />
          <Route
            path={`${routerAdminFlow.notFound}`}
            component={NotFoundPage}
          />
          <Route
            path={`${routerAdminFlow.sentSuccess}`}
            component={SentSuccess}
          />
          <Route path="/" component={PrivateRoutes} />
          <Route component={NotFoundPage} />
          <Redirect to="/" />
        </Switch>
      </BrowserRouter>
    </QueryClientProvider>
  )
}

const PrivateRoutes = () => {
  const user = getFromSecureLocalStorage(commonConstant.LOCAL_STORAGE_KEY.USER)
  const isAuthorized = useAppSelector(state => state.auth?.authorized)
  const history = useHistory()
  const location = useLocation()
  React.useEffect(() => {
    if (!isAuthorized) {
      if (history.location.pathname !== '/') {
        history.push(
          `${routerAuth.login}?redirectUrl=${history.location.pathname}`,
        )
      } else {
        history.push(`${routerAuth.login}`)
      }
    }
  }, [history, isAuthorized])

  if (!isAuthorized) {
    return null
  }
  if (
    user &&
    !user?.is2FAVerified &&
    location.pathname !== routerAdminFlow.verifyAccount
  ) {
    return <Redirect to={{ pathname: routerAdminFlow.verifyAccount }} />
  }

  return (
    <>
      <LoadConfigs />
      <Switch>
        <Route
          path={`${routerAdminFlow.adminApproval}/:id`}
          component={AdminAprovalFlow}
        />
        <Route
          path={`${routerAdminFlow.verifyAccount}`}
          component={VerifyAccount}
        />
        <Route
          path={`${routerAdminFlow.adminManual}/:contractId`}
          component={AdminManualFlow}
        />
        <Route
          path={`${routerAdminFlow.adminManual}`}
          component={AdminManualFlow}
        />

        <Route
          path={`${routerAdminFlow.addContract}/:contractId?`}
          component={AddContract}
        />

        <Route
          path={`${routerAdminFlow.contract}/:contractId`}
          component={DetailSendContract}
        />

        <Route exact path="/" component={HomePage} />
        <Redirect to="/" />
      </Switch>
    </>
  )
}
