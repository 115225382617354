import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ContractEntity } from '../../types'
import { PaymentMethod } from './interface'
import { createRenderCustomerFlow } from './values'
interface IConfirm {
  term?: boolean
  errorTerm?: boolean
  returnable?: boolean
  errorReturnable?: boolean
}

export interface ICustomerStepStore {
  current: number
  latestStep: number
  requestingGoTo: number
  confirmation?: IConfirm
  paymentMethod: PaymentMethod
  contract?: ContractEntity
  public_key: string
}
const STEP = createRenderCustomerFlow().length
const customerFlowStepsSlice = createSlice({
  name: 'customerFlowSteps',
  initialState: {
    // steps: STEPS_CUSTOMER_FLOW,
    public_key: '',
    current: 0,
    latestStep: 0,
    requestingGoTo: undefined,
    confirmation: {
      term: undefined,
      errorTerm: undefined,
      returnable: undefined,
      errorReturnable: undefined,
    },
    paymentMethod: 'bankAccount',
  } as ICustomerStepStore,
  reducers: {
    setContract(state, { payload: contract }: PayloadAction<ContractEntity>) {
      state.contract = contract
    },
    updateConfirmation: (
      state,
      { payload: confirmation }: PayloadAction<IConfirm>,
    ) => ({
      ...state,
      confirmation: { ...state.confirmation, ...confirmation },
    }),
    requestNext(state) {
      state.requestingGoTo = state.current + 1
    },
    updatePaymentMethod(
      state,
      { payload: paymentType }: PayloadAction<PaymentMethod>,
    ) {
      state.paymentMethod = paymentType
    },

    requestGoTo(state, { payload: to }: PayloadAction<number>) {
      if (to <= state.current) {
        state.current = to
        return
      }
      state.requestingGoTo = to
    },
    answerGoTo(state, { payload: canGoForth }: PayloadAction<boolean>) {
      if (canGoForth && state.current < STEP - 1) {
        state.current = state.requestingGoTo
        if (state.current > state.latestStep) {
          state.latestStep = state.current
        }
      }
      state.requestingGoTo = undefined
    },

    nextStep(state) {
      state.current += 1
    },
    backStep(state) {
      if (state.current > 0) {
        state.current -= 1
      }
    },

    exitStep(state) {
      state.current = 0
      state.latestStep = 0
      state.confirmation = null
      state.paymentMethod = 'bankAccount'
    },
    setPublicKey: (state, action: PayloadAction<string>) => {
      state.public_key = action.payload
    },
  },
})

export const {
  actions: stepActions,
  reducer: customerFlowStepsReducer,
  name,
} = customerFlowStepsSlice
export default customerFlowStepsReducer

export type CustomerStepsActionsType = typeof stepActions

export const useStepCustomerFlowSlice = () => {
  return { actions: stepActions }
}
