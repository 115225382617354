import * as React from 'react'
import styled from 'styled-components'
import { StyleConstants } from '../../../styles/StyleConstants'
import { PageWrapper } from '../../components/PageWrapper'
import { Logo } from './Logo'
import { Nav } from './Nav'

export const NavBar: React.FC<{ notFound?: boolean }> = ({
  notFound = false,
}) => {
  return (
    <Wrapper>
      <PageWrapper>
        <Logo />
        {!notFound && <Nav />}
      </PageWrapper>
    </Wrapper>
  )
}

export const Wrapper = styled.header`
  height: ${StyleConstants.NAV_BAR_HEIGHT};
  display: flex;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20;

  @supports (backdrop-filter: blur(10px)) {
    backdrop-filter: blur(10px);
    background-color: ${p =>
      p.theme.background.replace(
        /rgba?(\(\s*\d+\s*,\s*\d+\s*,\s*\d+)(?:\s*,.+?)?\)/,
        'rgba$1,0.75)',
      )};
  }

  ${PageWrapper} {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 60px;
  }
`
