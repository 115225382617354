/* eslint-disable react/prop-types */
import { Grid, useMediaQuery, useTheme } from '@mui/material'
import React, { FC, useEffect, useRef } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { FulfillCustomerDto } from 'src/store/types'
import { DocumentTitles } from '../../../../../utils/values'
import { UploadComponent } from '../../../../components/Upload'
import TitleIdentification from '../../components/TitleIdentification'
const scrollToRef = ref => {
  ref?.current?.scrollIntoView()
}
export const Step4CustomerFlow: FC<{
  actions: any
  state: { requestingGoTo: any }
}> = ({ actions, state }) => {
  const dispatch = useDispatch()
  const { requestingGoTo } = state
  const theme = useTheme()
  const matchXs = useMediaQuery(theme.breakpoints.only('xs'))
  const {
    trigger,
    control,
    formState: { errors },
  } = useFormContext<FulfillCustomerDto>()
  const myRef = useRef(null)
  useEffect(() => {
    scrollToRef(myRef)
  }, [])
  useEffect(() => {
    if (!requestingGoTo) {
      return
    }
    trigger(['documents']).then(canGoNext =>
      dispatch(actions.answerGoTo(canGoNext)),
    )
  }, [actions, dispatch, requestingGoTo, trigger])

  const { fields: documents } = useFieldArray({ control, name: 'documents' })

  return (
    <Grid container spacing={2} sx={{ mb: matchXs ? 5 : 'auto' }} ref={myRef}>
      <Grid item xs={12}>
        <TitleIdentification />
      </Grid>

      {documents.map((doc, index) => (
        <Grid item xs={6} md={6} lg={4} key={index}>
          <Controller
            name={`documents.${index}.docUrl` as 'documents.0.docUrl'}
            control={control}
            rules={{ required: 'Required' }}
            render={({ field: { value, onChange } }) => (
              <UploadComponent
                errors={errors}
                name={`documents.${index}.docUrl`}
                title={DocumentTitles[doc.docType]}
                {...{ value, onChange }}
              />
            )}
          />
        </Grid>
      ))}
    </Grid>
  )
}
