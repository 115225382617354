import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PaymentMethod } from './interface'
import { createRenderSteps, IStep } from './values'

export interface IStepStore {
  current: number
  latestStep: number
  steps?: IStep[]
  requestingGoTo?: number
  requestingSave?: boolean
  requestingSaveBankInfo?: boolean
  bankInfoCanBeSaved?: boolean
  requestBankInfo
  canSave?: boolean
  stepsLength?: number
  paymentType?: PaymentMethod
  public_key?: string
}
const stepLength = createRenderSteps().length
const stepSlice = createSlice({
  name: 'step',
  initialState: {
    current: 0,
    latestStep: 0,
    requestingGoTo: undefined,
    requestingSave: undefined,
    canSave: undefined,
    public_key: '',
  } as IStepStore,
  reducers: {
    requestNext(state) {
      state.requestingGoTo = state.current + 1
    },
    requestGoTo(state, { payload: to }: PayloadAction<number | boolean>) {
      if (typeof to === 'number') {
        if (to <= state.current) {
          state.current = to
          return
        }
        state.requestingGoTo = to
      } else if (typeof to === 'boolean') {
        state.requestingSave = to
      }
    },

    answerGoTo(state, { payload: canGoForth }: PayloadAction<boolean>) {
      if (!!state.requestingSave) {
        state.canSave = canGoForth
        state.requestingSave = undefined
        state.requestingGoTo = undefined
      } else {
        if (canGoForth && state.current < stepLength) {
          state.current = state.requestingGoTo
          if (state.current > state.latestStep) {
            state.latestStep = state.current
          }
        }
        state.requestingGoTo = undefined
      }
    },
    nextStep(state) {
      state.current += 1
    },
    backStep(state) {
      if (state.current > 0) {
        state.current -= 1
      }
    },
    requestSave(state, { payload: requestingSave }: PayloadAction<boolean>) {
      state.requestingSave = requestingSave
      state.requestingGoTo = state.current + 1
    },
    answerSave(state, { payload: canSave }: PayloadAction<boolean>) {
      state.canSave = canSave
      state.requestingSave = undefined
    },
    editGoTo(state, { payload: step }: PayloadAction<number>) {
      state.current = step
      state.latestStep = stepLength - 1
    },
    exitStep(state) {
      state.current = 0
      state.latestStep = 0
      state.requestingSave = undefined
      state.canSave = false
    },
    setPublicKey(state, action: PayloadAction<string>) {
      state.public_key = action.payload
    },
  },
})

export const { actions: stepActions, reducer: stepReducer, name } = stepSlice
export default stepReducer

export type StepsActionsType = typeof stepActions

export const useStepSlice = () => {
  return { actions: stepActions }
}
