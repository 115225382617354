import React from 'react'
import NumberFormat from 'react-number-format'
import { defaultPropsNumberFormat } from './BSBInput'

const AbnInput = React.forwardRef(function AbnInput(props: any, ref) {
  const { value, onChange, onBlur, ...rest } = props

  return (
    <NumberFormat
      getInputRef={ref}
      onValueChange={({ value }) => {
        onChange(value ? value : null)
      }}
      format="## ### ### ###"
      allowLeadingZeros={true}
      {...defaultPropsNumberFormat}
      {...{ value, onBlur }}
      {...rest}
    />
  )
})

export default AbnInput
