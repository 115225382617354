import { useQuery, UseQueryOptions } from 'react-query'
import { API, ErrorResponse } from '..'
import { TokenContractParam } from '../../store/types'
import { CustomerResponses, CustomersQueryKeys } from '../endpoints/customer'

export const useContractByTokenQuery = (
  id: string,
  options?: UseQueryOptions<
    CustomerResponses['getTokenForCustomerFlow'],
    ErrorResponse
  >,
) =>
  useQuery<
    CustomerResponses['getTokenForCustomerFlow'],
    ErrorResponse,
    CustomerResponses['getTokenForCustomerFlow'],
    [string, TokenContractParam]
  >(
    [CustomersQueryKeys.getTokenForCustomerFlow, { token: id }],
    API.Customers.getTokenForCustomerFlow,
    options,
  )
