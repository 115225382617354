import React, { ReactNode } from 'react'
import { FieldNamesMarkedBoolean } from 'react-hook-form'
import { Step0AdminApprovalFlow } from '../../../app/pages/Contract/admin-approval/steps/Step0'
import { Step1AdminApprovalFlow } from '../../../app/pages/Contract/admin-approval/steps/Step1'
import { Step2AdminApprovalFlow } from '../../../app/pages/Contract/admin-approval/steps/Step2'
import { Step4AdminApprovalFlow } from '../../../app/pages/Contract/admin-approval/steps/Step4'
import { Step5AdminApprovalFlow } from '../../../app/pages/Contract/admin-approval/steps/Step5'
import { Step6AdminApprovalFlow } from '../../../app/pages/Contract/admin-approval/steps/Step6'
import { Step7AdminApprovalFlow } from '../../../app/pages/Contract/admin-approval/steps/Step7'
import { Step8AdminApprovalFlow } from '../../../app/pages/Contract/admin-approval/steps/Step8'
import { Step0AdminManualFlow } from '../../../app/pages/Contract/admin-manual/steps/Step0'
import { Step6AdminManualFlow } from '../../../app/pages/Contract/admin-manual/steps/Step6'
import { WrapperStripeAdminManual } from '../../../app/pages/Contract/admin-manual/steps/stripe'
import { Step0CustomerFlow } from '../../../app/pages/Contract/customer-flow/steps/Step0'
import { Step1CustomerFlow } from '../../../app/pages/Contract/customer-flow/steps/Step1'
import { Step3CustomerFlow } from '../../../app/pages/Contract/customer-flow/steps/Step3'
import { Step4CustomerFlow } from '../../../app/pages/Contract/customer-flow/steps/Step4'
import { Step5CustomerFlow } from '../../../app/pages/Contract/customer-flow/steps/Step5'
import { Step6CustomerFlow } from '../../../app/pages/Contract/customer-flow/steps/Step6'
import { WrapperStripeCustomer } from '../../../app/pages/Contract/customer-flow/steps/stripe'
import { Step0 } from '../../../app/pages/Contract/steps/Step0'
import { Step1 } from '../../../app/pages/Contract/steps/Step1'
import { Step2 } from '../../../app/pages/Contract/steps/Step2'
import { ApproveContractDto, HalfContractDto } from '../../types'
import { Step8AdminManualFlow } from './../../../app/pages/Contract/admin-manual/steps/Step8'
import {
  AdminApprovalFlowProps,
  AdminManualFlowProps,
  CustomerFlowProps,
  StepProps,
} from './interface'

export type IStep = {
  title: string
  leftTitle?: string
  component?: ReactNode // TODO: remove this line after refactor every STEPS values
}

export const createRenderSteps = (props?: StepProps): IStep[] => [
  {
    component: props && <Step2 isRemoteCustomerApplication {...props} />,
    title: 'Payment terms',
  },
  {
    component: props && <Step8AdminApprovalFlow {...props} isManual={false} />,
    title: 'Trailer Buy out Schedule',
    leftTitle: 'Review customers trailer buyout schedule',
  },
  {
    component: props && <Step0 {...props} />,
    title: 'Customer details',
    leftTitle: 'Add our customers personal details',
  },
  { component: props && <Step1 {...props} />, title: 'Product details' },
]

export const createRenderCustomerFlow = (
  props?: CustomerFlowProps,
): IStep[] => [
  {
    component: props && <Step0CustomerFlow {...props} />,
    title: 'Personal details',
  },
  {
    component: props && <Step1CustomerFlow {...props} />,
    title: 'Business details',
  },
  {
    component: props && <WrapperStripeCustomer {...props} />,
    title: 'Payment details',
  },
  {
    component: props && <Step3CustomerFlow {...props} />,
    title: 'References',
    leftTitle: 'Add your next of kin and employers details',
  },
  {
    component: props && <Step4CustomerFlow {...props} />,
    title: 'Identification documentation',
  },
  {
    component: props && <Step5CustomerFlow {...props} />,
    title: 'Trailer Buy out Schedule',
    leftTitle: 'Review customers trailer buyout schedule',
  },
  {
    component: props && <Step6CustomerFlow {...props} />,
    title: 'Confirmation',
    leftTitle: 'Confirm you have read the terms and conditions',
  },
]

export const createRenderAdminApprovalFlow = (
  props?: AdminApprovalFlowProps & {
    isPending?: boolean
  },
): IStep[] => [
  {
    component: props && <Step0AdminApprovalFlow {...props} />,
    title: 'Personal details',
  },
  {
    component: props && <Step1AdminApprovalFlow {...props} />,
    title: 'Business details',
  },
  {
    component: props && <Step2AdminApprovalFlow {...props} isManual={false} />,
    title: 'Product details',
  },
  {
    component: props && <Step2 {...props} />,
    title: 'Payment terms',
  },
  {
    component: props && <Step4AdminApprovalFlow {...props} />,
    title: 'Payment details',
  },
  {
    component: props && <Step5AdminApprovalFlow {...props} />,
    title: 'References',
    leftTitle: 'Add our customers  next of kin and employers details',
  },
  {
    component: props && <Step6AdminApprovalFlow {...props} />,
    title: 'Identification documentation',
  },
  {
    component: props && <Step7AdminApprovalFlow {...props} />,
    title: 'Credit score',
    leftTitle: 'This is our customers credit score',
  },
  {
    component: props && <Step8AdminApprovalFlow {...props} isManual={false} />,
    title: 'Trailer Buy out Schedule',
    leftTitle: 'Review customers trailer buyout schedule',
  },
]

export const createRenderAdminManualFlow = (
  props?: AdminManualFlowProps & {
    isPending?: boolean
  },
): IStep[] => [
  {
    component: props && <Step2 {...props} />,
    title: 'Payment terms',
  },
  {
    component: props && <Step8AdminApprovalFlow {...props} isManual={true} />,
    title: 'Trailer Buy out Schedule',
    leftTitle: 'Review customers trailer buyout schedule',
  },
  {
    component: props && <Step0AdminManualFlow {...props} />,
    title: 'Personal details',
  },
  {
    component: props && <Step1AdminApprovalFlow {...props} />,
    title: 'Business details',
  },

  {
    component: props && <Step2AdminApprovalFlow {...props} isManual={true} />,
    title: 'Product details',
  },
  {
    component: props && <WrapperStripeAdminManual {...props} />,
    title: 'Payment details',
  },
  {
    component: props && <Step5AdminApprovalFlow {...props} />,
    title: 'References',
    leftTitle: 'Add your next of kin and employers details',
  },
  {
    component: props && <Step6AdminManualFlow {...props} />,
    title: 'Identification documentation',
  },
  {
    component: props && <Step7AdminApprovalFlow {...props} />,
    title: 'Credit score',
    leftTitle: 'This is our customers credit score',
  },

  {
    component: props && <Step8AdminManualFlow {...props} />,
    title: 'Confirmation',
    leftTitle: 'Confirm our customers have read the terms and conditions',
  },
]

export const isCreditCardDirty = (
  creditCardDirtyFields?: FieldNamesMarkedBoolean<
    ApproveContractDto['customer']['creditCardInfo']
  >,
): boolean =>
  Object.keys(creditCardDirtyFields || {}).filter(key => key !== 'cvv').length >
  0

export const isBankAccountDirty = (
  bankAccountDirtyFields?: FieldNamesMarkedBoolean<
    ApproveContractDto['customer']['bankAccountInfo']
  >,
): boolean => Object.keys(bankAccountDirtyFields || {}).length > 0

export const setFormValues = (result, setValue) => {
  setValue('customer.riskScore', result.riskScore)
  setValue('customer.bankrupt', result.bankrupt)
  setValue('customer.recentApplications', result.recentApplications)
  setValue('customer.defaults', result.defaults)
  setValue('customer.judgement', result.judgement)
  setValue('customer.riskScoreUrl', result.riskScoreUrl)
}

const customers = [
  'city',
  'state',
  'dateOfBirth',
  'gender',
  'postCode',
  'homeAddress',
  'streetAddress',
  'lastName',
  'firstName',
  'middleName',
  'driversLicense',
]
const products = ['cost']
const retail = 'retailPrice'
const keys = [...customers, ...products, retail]

const KeyOf = (formData: any) => {
  let retail = {} as { retailPrice?: number }
  if (formData?.retailPrice) {
    retail = {
      retailPrice: formData.retailPrice,
    }
  }
  return Object.keys(
    { ...formData?.customer, ...formData?.product, ...retail } || {},
  ).filter(k => keys.includes(k))
}

export const isChangeFormDirty = (
  formDirtyFields?: FieldNamesMarkedBoolean<HalfContractDto>,
): boolean => {
  const formData = formDirtyFields as any
  return KeyOf(formData).length > 0
}
