import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextFieldProps,
} from '@mui/material'
import React from 'react'
import { Controller, get, useFormContext } from 'react-hook-form'

type Props = TextFieldProps & {
  name?: string
  required?: boolean
}

const SelectState: React.FC<Props> = ({ name = 'state', required = true }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: required === true ? true : false }}
      render={({ field: { value, onChange, onBlur } }) => {
        return (
          <>
            <FormControl
              fullWidth
              sx={{ marginTop: 2 }}
              error={!!get(errors, name)}
            >
              <InputLabel id="demo-simple-select-label">State</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="State"
                {...{ value: value || '', onChange, onBlur }}
              >
                {STATE_VALUES.map((state, index) => (
                  <MenuItem key={index} value={state}>
                    {state}
                  </MenuItem>
                ))}
                <MenuItem value={undefined}>Clear</MenuItem>
              </Select>
            </FormControl>
            {/* <ErrorMessage
              errors={errors}
              name={name}
              render={({ message }) =>
                message &&
                message !== 'Required' && (
                  <FormHelperText sx={{ mt: 2 }}>{message}</FormHelperText>
                )
              }
            /> */}
          </>
        )
      }}
    />
  )
}

export default SelectState

const STATE_VALUES: string[] = [
  'New South Wales',
  'Victoria',
  'Queensland',
  'Tasmania',
  'Wester Australia',
  'Southern Australia',
]
