import { Box, styled, TextField, Typography } from '@mui/material'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage'
import { API, ErrorResponse } from '../../../apis'
import { AuthResponses } from '../../../apis/endpoints/auth'
import commonConstant from '../../../constants/common.constant'
import { useAuthSlice } from '../../../store/slices/auth'
import { LoginDto } from '../../../store/types'
import { onError } from '../../../utils/helperFunction'
import { setTokens } from '../../../utils/localStorage'
import { routerAdminFlow } from '../../../utils/routerPath'
import { Button } from '../../components/Button'
import Password from './../../components/Password'
// const redirectUrl: string = '?redirectUrl='
const Login = () => {
  const history = useHistory()
  const { actions } = useAuthSlice()
  const dispatch = useDispatch()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginDto>({
    defaultValues: {
      username: '',
      password: '',
    },
  })
  const { mutate, isLoading } = useMutation<
    AuthResponses['login'],
    ErrorResponse,
    LoginDto
  >(API.Auth.login, {
    onSuccess: ({ data }) => {
      setTokens(data)
      secureLocalStorage.setItem(
        commonConstant.LOCAL_STORAGE_KEY.USER,
        JSON.stringify({ is2FAVerified: false, qrcode: data?.qrcode }),
      )
      dispatch(actions.signIn())

      try {
        //@ts-ignore
        if (history.location?.state?.from !== 'resetPass') {
          history.goBack()
        } else {
          history.replace(routerAdminFlow.verifyAccount)
        }
      } catch (e) {
        history.replace(routerAdminFlow.verifyAccount)
      }
      // if (history.location.search.includes(redirectUrl)) {
      //   const redirectTo = history.location.search.split(redirectUrl)[1]
      //   history.push(redirectTo)
      //   //  history.goBack()
      // } else {
      //   history.push('/')
      // }
    },
    onError,
  })
  const handleLogin = handleSubmit(({ username, password }) => {
    mutate({ username: username.toLowerCase(), password })
  })

  return (
    <form onSubmit={handleLogin}>
      <Typography variant="h2" textAlign="left">
        Sign in to your account
      </Typography>
      <Box
        component="div"
        sx={{ mt: 2 }}
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
      >
        <Controller
          control={control}
          name="username"
          rules={{ required: 'Required' }}
          render={({ field: { value, onChange, onBlur } }) => (
            <TextField
              {...{ value, onChange, onBlur }}
              error={!!errors.username}
              margin="normal"
              fullWidth
              label="Login"
              name="username"
              autoComplete="username"
              autoFocus
            />
          )}
        />

        <Controller
          control={control}
          name="password"
          rules={{ required: 'Required' }}
          render={({ field: { value, onChange, onBlur } }) => (
            <Password
              {...{ value, onChange, onBlur }}
              error={!!errors.password}
            />
          )}
        />

        <LinkButton
          variant="text"
          onClick={() => history.push('forgot-password')}
        >
          Forgot Password
        </LinkButton>

        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 3, minWidth: 41 }}
          disabled={isLoading}
          loading={isLoading}
        >
          Sign In
        </Button>
      </Box>
    </form>
  )
}

export default Login

export const LinkButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
}))
