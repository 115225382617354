import moment from 'moment'
import commonConstant from '../constants/common.constant'

export const formatCurrency = value => {
  const numberValue = typeof value === 'number' ? value : parseFloat(value)
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(numberValue)
}

export const formatDate = ({
  date,
  originalFormat,
  designateFormat,
}: {
  date: Date | string
  originalFormat?: string
  designateFormat: ValueOf<typeof commonConstant.DATE_FORMAT>
}) => {
  let formattedDate = '--'
  try {
    if (originalFormat) {
      formattedDate = moment(date, originalFormat).format(designateFormat)
    } else {
      formattedDate = moment(date).format(designateFormat)
    }
  } catch (err) {
    /* Do nothing */
  }
  return formattedDate
}

export const formatToNumber = (target, fallbackValue = 0): number => {
  try {
    return isNaN(+target) ? fallbackValue : target ? target : fallbackValue
  } catch (err) {
    return fallbackValue
  }
}

export const capitalizeString = (s: string) =>
  s ? s.charAt(0).toUpperCase() + s.slice(1).toLowerCase() : ''
