import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import commonConstant from '../../../../constants/common.constant'

const arrPaymentType = [
  { value: commonConstant.PAYMENT_TYPE.DAILY, title: 'Daily payments' },
  { value: commonConstant.PAYMENT_TYPE.WEEKLY, title: 'Weekly payments' },
  {
    value: commonConstant.PAYMENT_TYPE.FORTNIGHT,
    title: 'Fortnightly payments',
  },
  { value: commonConstant.PAYMENT_TYPE.MONTHLY, title: 'Monthly payments' },
]

interface PaymentTypeProps {
  disabled?: boolean
}

const PaymentType: React.FC<PaymentTypeProps> = ({ disabled = false }) => {
  const { control, setValue } = useFormContext()
  return (
    <Controller
      name="paymentType"
      control={control}
      rules={{
        required: 'Required',
      }}
      render={({ field: { value, onChange, onBlur } }) => {
        return (
          <FormControl fullWidth sx={{ marginTop: 2 }}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              {...{ value, onBlur }}
              onChange={(e: SelectChangeEvent<any>) => {
                if (value !== e.target.value) {
                  setValue('start', null)
                }
                onChange(e)
              }}
              disabled={disabled}
            >
              {arrPaymentType.map(item => {
                return (
                  <MenuItem key={item.title} value={item.value}>
                    {item.title}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        )
      }}
    />
  )
}

export default PaymentType
