import { AxiosResponse } from 'axios'
import { MutationFunction, QueryFunction } from 'react-query'
import {
  BankPublishKey,
  IdParam,
  PaymentSuspenseDto,
  SetUpIntent,
  SuspenseAccountBalanceResponse,
  VerifyCardDto,
} from '../../store/types'
import { buildQueryKeys } from '../queryUltils'
import { request } from '../request'

export const PAYMENT_URL = '/payment'

export type PaymentResponses = {
  verifyCard: AxiosResponse<string>
  credential: AxiosResponse<BankPublishKey>
  setUpIntent: AxiosResponse<SetUpIntent>
  getSuspenseAccountBalance: AxiosResponse<SuspenseAccountBalanceResponse>
  addNewPayment: AxiosResponse<void>
  addCashPayment: AxiosResponse<void>
}

type PaymentEndPoints = {
  verifyCard: MutationFunction<PaymentResponses['verifyCard'], VerifyCardDto>
  credential: MutationFunction<PaymentResponses['credential']>
  setUpIntent: MutationFunction<PaymentResponses['setUpIntent']>
  getSuspenseAccountBalance: QueryFunction<
    PaymentResponses['getSuspenseAccountBalance'],
    [string, IdParam]
  >
  addNewPayment: MutationFunction<
    PaymentResponses['addNewPayment'],
    Omit<PaymentSuspenseDto, 'invoiceId'>
  >
  addCashPayment: MutationFunction<
    PaymentResponses['addNewPayment'],
    Omit<PaymentSuspenseDto, 'paymentMethodId'>
  >
}

export const Payment: PaymentEndPoints = {
  verifyCard: payload => request.post(`${PAYMENT_URL}/verify-card`, payload),
  credential: () => request.get(`${PAYMENT_URL}/credential`),
  setUpIntent: () => request.get(`${PAYMENT_URL}/setup-intent`),
  getSuspenseAccountBalance: ({ queryKey: [, { id }] }) =>
    request.get(`${PAYMENT_URL}/suspense`, { params: { contractId: id } }),
  addNewPayment: payload => request.post(`${PAYMENT_URL}/suspense`, payload),
  addCashPayment: payload => request.post(`${PAYMENT_URL}/suspense`, payload),
}

export const PaymentQueryKeys = buildQueryKeys(Payment)
