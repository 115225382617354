import { Grid } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useElements, useStripe } from '@stripe/react-stripe-js'
import React, { FC, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { API, ErrorResponse } from '../../../../../apis'
import { PaymentResponses } from '../../../../../apis/endpoints/payment'
import { AdminManualFlowProps } from '../../../../../store/slices/step/interface'
import { isCreditCardDirty } from '../../../../../store/slices/step/values'
import { ExpiryAdminManualDto } from '../../../../../store/types'
import { onError } from '../../../../../utils/helperFunction'
import {
  bankAccountNumberProps,
  bsbInputProps,
  cardNameProps,
  cardNumberProps,
  cvvMaskedProps,
  cvvProps,
  expiryProps,
  maskedCardNumberProps,
} from '../../bankInputProps'
import { ControlledTextField } from '../../components/ControlledInput'
import { AntTab, AntTabs, TabPanel } from '../../components/PaymentTab'
import { defaultRules } from '../../defaultRules'

enum TabEnum {
  Bank = 0,
  Card = 1,
}

export const Step4AdminManualFlow: FC<AdminManualFlowProps> = ({
  actions,
  state,
}) => {
  const dispatch = useDispatch()
  const [client_secret, set_client_secret] = useState<string>('')
  const stripe = useStripe()
  const elements = useElements()
  const {
    requestingGoTo,
    paymentType,
    requestingSave,
    requestingSaveBankInfo,
  } = state
  const history = useHistory()

  const {
    trigger,
    watch,
    formState: { dirtyFields },
    getValues,
    setValue,
  } = useFormContext<ExpiryAdminManualDto>()
  const cvvFieldsProps = cvvProps(
    !isCreditCardDirty(dirtyFields.customer?.creditCardInfo),
  )

  const handleSubmit = async (client_secret: string) => {
    return new Promise<void>(async (resolve, reject) => {
      if (!stripe || !elements) {
        return
      }

      const bankAccountName = getValues(
        'customer.bankAccountInfo.bankAccountName',
      )
      const email = getValues('customer.emailAddress')
      const bsb_number = getValues('customer.bankAccountInfo.bsb').toString()
      const account_number = getValues(
        'customer.bankAccountInfo.bankAccountNo',
      ).toString()

      const result = await stripe.confirmAuBecsDebitSetup(`${client_secret}`, {
        payment_method: {
          au_becs_debit: {
            bsb_number,
            account_number,
          },
          billing_details: {
            name: bankAccountName,
            email: email,
          },
        },
      })

      if (result.error) {
        const position = 'top-right'
        toast.error(result.error.message, { position })
        dispatch(actions.answerGoTo(false))
        reject(result.error)
      } else {
        const paymentMethod = result.setupIntent.payment_method
        const setupIntentId = result.setupIntent.id
        setValue(
          'customer.bankAccountInfo.paymentMethod',
          paymentMethod.toString(),
        )
        setValue('customer.bankAccountInfo.setupIntentId', setupIntentId)
        if (requestingSaveBankInfo) {
          dispatch(actions.setBankInfoCanBeSaved(true))
        }
        resolve()
      }
    })
  }

  const { mutate: setUpIntent } = useMutation<
    PaymentResponses['setUpIntent'],
    ErrorResponse
  >(API.Payment.setUpIntent, {
    onSuccess: data => {
      set_client_secret(data.data.client_secret)
    },
    onError,
  })

  const tab = React.useMemo(() => {
    if (paymentType === 'bankAccount') {
      return TabEnum.Bank
    } else {
      return TabEnum.Card
    }
  }, [paymentType])
  useEffect(() => {
    const { creditCardInfo } = getValues('customer')

    if (creditCardInfo && creditCardInfo.exp_month && creditCardInfo.exp_year) {
      const month = creditCardInfo.exp_month
      setValue(
        'customer.creditCardInfo.expiry',
        `${month < 10 ? `0${month}` : month}/${creditCardInfo.exp_year}`,
      )
    }
    // payment type was update from detail page
    // dispatch(
    //   actions.updatepaymentType(
    //     watch('customer.creditCardInfo.cardName')
    //       ? 'creditCard'
    //       : 'bankAccount',
    //   ),
    // )
  }, [actions, dispatch, watch, setValue, getValues])
  const handleChange = (event: React.SyntheticEvent, newValue: TabEnum) => {
    dispatch(actions.updatepaymentType(newValue ? 'creditCard' : 'bankAccount'))
  }
  const theme = useTheme()

  useEffect(() => {
    if (!requestingGoTo && !requestingSaveBankInfo) {
      return
    }
    const cardNo = getValues('customer.creditCardInfo.cardNumber')
    const accountNo = getValues('customer.bankAccountInfo.bankAccountNo')
    if (cardNo && cardNo.includes('*') && paymentType !== 'bankAccount') {
      trigger([
        'customer.creditCardInfo.cardName',
        'customer.creditCardInfo.expiry',
      ]).then(canGoNext => dispatch(actions.answerGoTo(canGoNext)))
    } else if (
      accountNo &&
      accountNo.includes('*') &&
      paymentType === 'bankAccount'
    ) {
      trigger([
        'customer.bankAccountInfo.bsb',
        'customer.bankAccountInfo.bankAccountName',
      ]).then(async canGoNext => {
        if (requestingSaveBankInfo) {
          dispatch(actions.answerGoTo(canGoNext))
          dispatch(actions.setBankInfoCanBeSaved(true))
        } else {
          dispatch(actions.answerGoTo(canGoNext))
        }
      })
    } else if (tab === TabEnum.Bank) {
      trigger([
        'customer.bankAccountInfo.bankAccountName',
        'customer.bankAccountInfo.bankAccountNo',
        'customer.bankAccountInfo.bsb',
      ]).then(async () => {
        try {
          await handleSubmit(client_secret)
          if (requestingSaveBankInfo) {
            dispatch(actions.setBankInfoCanBeSaved(true))
          } else {
            dispatch(actions.answerGoTo(true))
          }
        } catch (error) {
          dispatch(actions.answerGoTo(false))
        }
      })
    } else {
      trigger([
        'customer.creditCardInfo.cardName',
        'customer.creditCardInfo.cardNumber',
        'customer.creditCardInfo.cvv',
        'customer.creditCardInfo.expiry',
      ]).then(canGoNext => dispatch(actions.answerGoTo(canGoNext)))
    }
  }, [
    actions,
    dispatch,
    requestingGoTo,
    tab,
    trigger,
    requestingSave,
    requestingSaveBankInfo,
  ])

  useEffect(() => {
    if (tab === TabEnum.Bank) {
      setUpIntent()
    }
  }, [tab, setUpIntent])
  return (
    <>
      <AntTabs value={tab} onChange={handleChange} aria-label="ant example">
        <AntTab label="Bank details" />
        <AntTab label="Card details" />
      </AntTabs>
      <TabPanel value={tab} index={0} dir={theme.direction}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ControlledTextField
              name="customer.bankAccountInfo.bankAccountName"
              rules={defaultRules}
              label="Account name"
              autoFocus
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              name="customer.bankAccountInfo.bsb"
              {...bsbInputProps}
            />
          </Grid>
          <Grid item xs={12}>
            {getValues('customer.bankAccountInfo.paymentMethodId') ? (
              <ControlledTextField
                name="customer.bankAccountInfo.bankAccountNo"
                label="Account Number"
              />
            ) : (
              <ControlledTextField
                name="customer.bankAccountInfo.bankAccountNo"
                {...bankAccountNumberProps}
              />
            )}
          </Grid>
          <Grid item xs={12} style={{ display: 'none' }}>
            <ControlledTextField
              name="customer.bankAccountInfo.paymentMethod"
              rules={defaultRules}
              label="Payment Method"
              autoFocus
            />
          </Grid>
          <Grid item xs={12} style={{ display: 'none' }}>
            <ControlledTextField
              name="customer.bankAccountInfo.setupIntentId"
              rules={defaultRules}
              label="setupIntentId"
              autoFocus
            />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={tab} index={1} dir={theme.direction}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ControlledTextField
              name="customer.creditCardInfo.cardName"
              {...cardNameProps}
            />
          </Grid>
          {getValues('customer.creditCardInfo.paymentMethodId') ? (
            <Grid item xs={12}>
              <ControlledTextField
                name="customer.creditCardInfo.cardNumber"
                {...maskedCardNumberProps}
              />
            </Grid>
          ) : (
            <Grid item xs={12}>
              <ControlledTextField
                name="customer.creditCardInfo.cardNumber"
                {...cardNumberProps}
              />
            </Grid>
          )}

          <Grid item xs={12} md={6}>
            <ControlledTextField
              name="customer.creditCardInfo.expiry"
              {...expiryProps}
            />
          </Grid>
          {getValues('customer.creditCardInfo.paymentMethodId') ? (
            <Grid item xs={12} md={6}>
              <ControlledTextField
                name="customer.creditCardInfo.cvv"
                {...cvvMaskedProps}
              />
            </Grid>
          ) : (
            <Grid item xs={12} md={6}>
              <ControlledTextField
                name="customer.creditCardInfo.cvv"
                {...cvvFieldsProps}
              />
            </Grid>
          )}
        </Grid>
      </TabPanel>
    </>
  )
}
