import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress'
import { makeStyles } from '@mui/styles'
import * as React from 'react'

const useStyles = makeStyles({
  text: {
    fontSize: '50px',
    lineHeight: '60px',
    fontStyle: 'normal',
    fontWeight: 'bold',
  },
  textTitle: {
    width: '148px',
    height: '17px',
    fontSize: '14px',
    lineHeight: '17px',
    fontStyle: 'normal',
    fontWeight: 600,
    color: '#313131',
    textAlign: 'center',
  },
  textTitleInfo: {
    width: '113px',
    height: '28px',
    fontSize: '12px',
    lineHeight: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    color: '#313131',
    textAlign: 'center',
  },
  circle: {
    strokeLinecap: 'round',
  },
})

interface IProps {
  title?: string
  isBoolean: boolean
  isPending: boolean
}

const CircularProgressWithLabel = (props: CircularProgressProps & IProps) => {
  const classes = useStyles()
  const Variant = () =>
    props.isPending ? (
      <Box display="grid">
        <Box display="grid" justifyContent={'center'}>
          <Box gridColumn="span 12">
            <CircularProgress />
          </Box>
        </Box>
        <Box display="grid" justifyItems={'center'}>
          <Box gridColumn="span 12">
            <Typography className={classes.textTitle}>
              Checking information
            </Typography>
          </Box>
          <Box gridColumn="span 12">
            <Typography className={classes.textTitleInfo}>
              This can take up to a minute.
            </Typography>
          </Box>
        </Box>
      </Box>
    ) : (
      <Typography
        variant="caption"
        component="div"
        className={classes.text}
        sx={{ color: `${props.isBoolean ? '#1EAD54' : '#F00023'}` }}
      >{`${Math.round(Number.parseInt(props.title))}`}</Typography>
    )
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <Box sx={{ position: 'relative' }}>
        <CircularProgress
          variant="determinate"
          sx={{
            color: '#DDDDDD',
          }}
          {...props}
          value={100}
        />
        <CircularProgress
          variant="determinate"
          color={props.isBoolean ? 'success' : 'primary'}
          {...props}
          value={props.isPending ? 0 : props.value}
          sx={{
            position: 'absolute',
            left: 0,
          }}
          classes={{
            circle: classes.circle,
          }}
        />
      </Box>
      {props.title && (
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Variant />
        </Box>
      )}
    </Box>
  )
}

export default CircularProgressWithLabel
