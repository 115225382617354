import { ContractResponse, CreateContractDto } from '../../../store/types'
export const response2UpdateDto = (
  data: ContractResponse,
): CreateContractDto => {
  const {
    customer: { firstName, middleName, lastName, emailAddress },
    startDate,
    paymentType,
    retailPrice,
    interestRate,
    customOrder,
    registrationFee,
    product: {
      cost,
      trailerType,
      modelNumber,
      serialNumber,
      registrationNumber,
      contractNumber,
      trNumber,
      trailerLength,
      trailerWidth,
    },
    topupAmount,
  } = data

  const updateDto: CreateContractDto = {
    customer: {
      firstName,
      middleName,
      lastName,
      emailAddress,
    },
    product: {
      trailerType,
      modelNumber,
      serialNumber,
      registrationNumber,
      contractNumber,
      trNumber,
      trailerLength,
      trailerWidth,
      cost,
    },
    startDate,
    paymentType,
    retailPrice,
    interestRate,
    registrationFee,
    customOrder,
    topupAmount: topupAmount ?? 0,
  }

  return updateDto
}
