import { TextFieldProps } from '@mui/material'
import { ControllerProps } from 'react-hook-form'
import BSBInput, {
  AccountNumberInput,
  CardNumberInput,
  CVVInput,
  ExpiryInput,
} from './../../components/Input/BSBInput'
// export const bsbInputProps = {
//   rules: {
//     required: 'Required',
//     minLength: { value: 6, message: 'Exceed 6 digits' },
//     pattern: {
//       value: /^[a-zA-Z0-9]{6,}$/,
//       message: 'Min 6 digits',
//     },
//   },
//   label: 'BSB',
//   InputProps: { inputComponent: BSBInput },
// }
// export const bankAccountNumberProps = {
//   rules: {
//     required: 'Required',
//     maxLength: { value: 9, message: 'Exceed 9 digits' },
//     pattern: {
//       value: /^[a-zA-Z0-9]{8,}$/,
//       message: 'Min 8 digits',
//     },
//   },
//   label: 'Account number',
//   InputProps: { inputComponent: AccountNumberInput },
// }
// export const cardNameProps = {
//   rules: {
//     required: 'Required',
//     maxLength: { value: 250, message: 'Exceed 250 digits' },
//   },
//   label: 'Name on card',
// }
// export const cardNumberProps = {
//   rules: {
//     required: 'Required',
//     maxLength: { value: 16, message: 'Exceed 16 digits' },
//     pattern: {
//       value: /^[a-zA-Z0-9]{16,}$/,
//       message: 'Min 16 digits',
//     },
//   },
//   InputProps: { inputComponent: CardNumberInput },
//   label: 'Card number',
// }
// export const expiryProps = {
//   rules: {
//     required: 'Required',
//     pattern: {
//       value: /^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/,
//       message: 'Invalid expiry',
//     },
//   },
//   label: 'Expiry',
//   placeholder: '01/26',
//   InputProps: { inputComponent: ExpiryInput },
// }
// export const cvvProps = {
//   rules: {
//     required: 'Required',
//     maxLength: { value: 3, message: 'Max 3 characters' },
//     pattern: {
//       value: /^[a-zA-Z0-9]{3,}$/,
//       message: 'Min 3 digits',
//     },
//   },
//   label: 'CVV',
//   InputProps: { inputComponent: CVVInput },
// }

//New rule
type TextInputProps = TextFieldProps & {
  rules?: ControllerProps['rules']
}
export const bsbInputProps: TextInputProps = {
  rules: {
    required: true,
    minLength: 6,
    pattern: /^[a-zA-Z0-9]{6,}$/,
  },
  label: 'BSB',
  InputProps: { inputComponent: BSBInput },
}
export const bankAccountNumberProps: TextInputProps = {
  rules: {
    required: true,
    maxLength: 9,
    pattern: /^[a-zA-Z0-9]{5,}$/,
  },
  label: 'Account number',
  InputProps: { inputComponent: AccountNumberInput },
}
export const cardNameProps: TextInputProps = {
  rules: {
    required: true,
    maxLength: 250,
  },
  label: 'Name on card',
}
export const cardNumberProps: TextInputProps = {
  rules: {
    required: true,
    maxLength: 16,
    pattern: /^[a-zA-Z0-9]{16,}$/,
  },
  InputProps: { inputComponent: CardNumberInput },
  label: 'Card number',
}
export const maskedCardNumberProps: TextInputProps = {
  rules: {
    required: true,
    maxLength: 16,
  },
  label: 'Card number',
}
export const expiryProps: TextInputProps = {
  rules: {
    required: true,
    pattern: /^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/,
  },
  label: 'Expiry',
  placeholder: '01/26',
  InputProps: { inputComponent: ExpiryInput },
}
export const cvvProps = (optional?: boolean): TextInputProps => ({
  rules: optional
    ? { required: false }
    : {
        required: true,
        maxLength: 3,
        pattern: /^[a-zA-Z0-9]{3,}$/,
      },
  label: 'CVV',
  InputProps: { inputComponent: CVVInput },
})

export const cvvMaskedProps = (optional?: boolean): TextInputProps => ({
  rules: optional
    ? { required: false }
    : {
        required: true,
        maxLength: 3,
        pattern: /^[a-zA-Z0-9]{3,}$/,
      },
  label: 'CVV',
  InputProps: { inputComponent: CVVInput },
})
