import React from 'react'
import NumberFormat from 'react-number-format'

const CurrencyInput = React.forwardRef(function CurrencyInput(props: any, ref) {
  const { value, onChange, onBlur, ...rest } = props

  return (
    <NumberFormat
      getInputRef={ref}
      value={value}
      onValueChange={({ value }) => {
        onChange(value ? +value : null)
      }}
      onBlur={onBlur}
      thousandSeparator
      prefix="$"
      allowNegative={false}
      decimalScale={2}
      {...rest}
    />
  )
})

export default CurrencyInput
