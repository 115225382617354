import styled from 'styled-components/macro'

export const PageWrapper = styled.div`
  height: inherit;
  width: 100%;
  margin: 0 auto;
  padding-top: 20px;
  padding: 85px 60px 110px 60px;
  box-sizing: border-box;
`
