import axios from 'axios'
import { toast } from 'react-toastify'
import { AccessTokenResponse } from '../store/types'
import { clearTokens, getTokens, setTokens } from '../utils/localStorage'

export const request = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  timeout: 10000,
})

export const publicRequest = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  timeout: 10000,
})

const refreshAccessToken = async (): Promise<string | undefined> => {
  const { refreshToken } = getTokens()
  try {
    if (!refreshToken) {
      return
    }
    const { data } = await request.put<AccessTokenResponse>('/auth/refresh', {
      refreshToken,
    })
    if (!data) {
      return
    }
    setTokens(data)

    return data.accessToken
  } catch (err) {
    return
  }
}

request.interceptors.request.use(configs => {
  const { accessToken } = getTokens()

  return {
    ...configs,
    headers: {
      ...(configs.headers || {}),
      ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
    },
  }
})

request.interceptors.response.use(
  res => res,
  async error => {
    const originalRequest = error.config
    if (
      [401, 403].includes(error?.response?.status) &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true
      const accessToken = await refreshAccessToken()
      if (!accessToken) {
        clearTokens()
        window.location.href = '/auth/login'
        toast.error('Session expired. Please login again.')
        return
      }
      axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
      return request(originalRequest)
    }

    return Promise.reject(error)
  },
)
