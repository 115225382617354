import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { TextField, TextFieldProps } from '@mui/material'
import { differenceInCalendarYears, isDate } from 'date-fns'
import React from 'react'
import { Controller, get, useFormContext } from 'react-hook-form'

type Props = TextFieldProps & {
  name?: string
  required?: boolean
}

const SelectDateOfBirth: React.FC<Props> = ({
  name = 'dateOfBirth',
  required = true,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()
  const handleDOB = (d: any) => {
    const dob = new Date(d)
    const age = differenceInCalendarYears(new Date(), dob)
    return age >= 18 ? true : 'You must be over 18 to register'
  }
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: required === true ? true : false,
        validate: v => (isDate(new Date(v)) && handleDOB(v)) || 'Invalid Date',
      }}
      render={({ field: { value, onChange, onBlur } }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            {...{ value: value || '', onChange, onBlur }}
            label="Date of birth DD/MM/YYYY"
            inputFormat={'dd/MM/yyyy'}
            mask={'__/__/____'}
            renderInput={params => (
              <TextField
                {...params}
                error={!!get(errors, name)}
                margin="normal"
                fullWidth
                name={name}
              />
            )}
          />
        </LocalizationProvider>
      )}
    />
  )
}

export default SelectDateOfBirth
