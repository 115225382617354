import LinearProgress from '@mui/material/LinearProgress'
import React from 'react'
import styled from 'styled-components/macro'
import { IStep } from '../../../store/slices/step/values'

const BorderLinearProgress = styled(LinearProgress)(() => ({
  backgroundColor: '#C4C4C4',
}))

const StyleBox = styled('div')(() => ({
  width: '100%',
  flexGrow: 1,
  alignSelf: 'stretch',
}))
const calculateProgress = (current: number, step: IStep[]): number =>
  ((current + 1) * 100) / step.length
const StepBar = ({ current, step }: { current: number; step: IStep[] }) => {
  return (
    <StyleBox>
      <BorderLinearProgress
        variant="determinate"
        value={calculateProgress(current, step)}
      />
    </StyleBox>
  )
}

export default StepBar
