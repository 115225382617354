import { Grid } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import React, { FC, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { AdminApprovalFlowProps } from '../../../../../store/slices/step/interface'
import { isCreditCardDirty } from '../../../../../store/slices/step/values'
import { ExpiryAdminApprovalDto } from '../../../../../store/types'
import {
  bankAccountNumberProps,
  bsbInputProps,
  cardNameProps,
  cardNumberProps,
  cvvMaskedProps,
  cvvProps,
  expiryProps,
  maskedCardNumberProps,
} from '../../bankInputProps'
import { ControlledTextField } from '../../components/ControlledInput'
import { defaultRules } from '../../defaultRules'
import { AntTab, AntTabs, TabPanel } from './../../components/PaymentTab'

enum TabEnum {
  Bank = 0,
  Card = 1,
}

export const Step4AdminApprovalFlow: FC<AdminApprovalFlowProps> = ({
  actions,
  state,
}) => {
  const dispatch = useDispatch()
  const { requestingGoTo, paymentType } = state

  const {
    trigger,
    watch,
    getValues,
    formState: { dirtyFields },
  } = useFormContext<ExpiryAdminApprovalDto>()
  const cvvFieldProps = cvvProps(
    !isCreditCardDirty(dirtyFields.customer?.creditCardInfo),
  )

  const tab = React.useMemo(
    () => (paymentType === 'bankAccount' ? TabEnum.Bank : TabEnum.Card),
    [paymentType],
  )

  useEffect(() => {
    dispatch(
      actions.updatepaymentType(
        watch('customer.creditCardInfo.cardName')
          ? 'creditCard'
          : 'bankAccount',
      ),
    )
  }, [actions, dispatch, watch])

  const handleChange = (event: React.SyntheticEvent, newValue: TabEnum) => {
    dispatch(actions.updatepaymentType(newValue ? 'creditCard' : 'bankAccount'))
  }
  const theme = useTheme()

  useEffect(() => {
    if (!requestingGoTo) {
      return
    }
    if (getValues('customer.creditCardInfo.paymentMethodId')) {
      trigger([
        'customer.creditCardInfo.cardName',
        'customer.creditCardInfo.expiry',
      ]).then(canGoNext => dispatch(actions.answerGoTo(canGoNext)))
    } else if (getValues('customer.bankAccountInfo.paymentMethodId')) {
      trigger([
        'customer.bankAccountInfo.bsb',
        'customer.bankAccountInfo.bankAccountName',
      ]).then(canGoNext => dispatch(actions.answerGoTo(canGoNext)))
    } else if (tab === TabEnum.Bank) {
      trigger([
        'customer.bankAccountInfo.bankAccountName',
        'customer.bankAccountInfo.bankAccountNo',
        'customer.bankAccountInfo.bsb',
      ]).then(canGoNext => {
        dispatch(actions.answerGoTo(canGoNext))
      })
    } else {
      trigger([
        'customer.creditCardInfo.cardName',
        'customer.creditCardInfo.cardNumber',
        'customer.creditCardInfo.cvv',
        'customer.creditCardInfo.expiry',
      ]).then(canGoNext => dispatch(actions.answerGoTo(canGoNext)))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions, dispatch, requestingGoTo, tab, trigger])

  return (
    <>
      <AntTabs value={tab} onChange={handleChange} aria-label="ant example">
        <AntTab label="Bank details" />
        {/* <AntTab label="Card details" /> */}
      </AntTabs>
      <TabPanel value={tab} index={0} dir={theme.direction}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ControlledTextField
              name="customer.bankAccountInfo.bankAccountName"
              rules={defaultRules}
              label="Account name"
              autoFocus
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              name="customer.bankAccountInfo.bsb"
              {...bsbInputProps}
            />
          </Grid>
          <Grid item xs={12}>
            {getValues('customer.bankAccountInfo.paymentMethodId') ? (
              <ControlledTextField
                name="customer.bankAccountInfo.bankAccountNo"
                label="Account Number"
              />
            ) : (
              <ControlledTextField
                name="customer.bankAccountInfo.bankAccountNo"
                {...bankAccountNumberProps}
              />
            )}
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={tab} index={1} dir={theme.direction}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ControlledTextField
              name="customer.creditCardInfo.cardName"
              {...cardNameProps}
            />
          </Grid>
          {getValues('customer.creditCardInfo.paymentMethodId') ? (
            <Grid item xs={12}>
              <ControlledTextField
                name="customer.creditCardInfo.cardNumber"
                {...maskedCardNumberProps}
              />
            </Grid>
          ) : (
            <Grid item xs={12}>
              <ControlledTextField
                name="customer.creditCardInfo.cardNumber"
                {...cardNumberProps}
              />
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <ControlledTextField
              name="customer.creditCardInfo.expiry"
              {...expiryProps}
            />
          </Grid>
          {getValues('customer.creditCardInfo.paymentMethodId') ? (
            <Grid item xs={12} md={6}>
              <ControlledTextField
                name="customer.creditCardInfo.cvv"
                {...cvvMaskedProps}
              />
            </Grid>
          ) : (
            <Grid item xs={12} md={6}>
              <ControlledTextField
                name="customer.creditCardInfo.cvv"
                {...cvvFieldProps}
              />
            </Grid>
          )}
        </Grid>
      </TabPanel>
    </>
  )
}
