import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { Menu, MenuItem, Paper, styled, Typography } from '@mui/material'
import React from 'react'
import { IStep } from '../../../store/slices/step/values'

const StyleMenu = styled(Menu)(() => ({
  '& .MuiPaper-root': {
    minWidth: 340,
    border: '1px solid #ECEFF1',
    boxShadow: '0px 0px 5px rgba(112, 129, 163, 0.102109)',
  },
}))

const StyleTypography = styled(Typography)(() => ({
  width: 'fit-content',
  margin: '0 auto',
  cursor: 'pointer',
}))
interface ITitleMenu {
  steps: IStep[]
  onGoTo: (index: number) => void
  current: number
  latestStep: number
}

const TitleMenu: React.FC<ITitleMenu> = ({
  steps,
  onGoTo,
  current = 0,
  latestStep = 0,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <>
      <StyleTypography
        variant="h2"
        onClick={handleClick}
        aria-expanded={open ? 'true' : undefined}
        textAlign="center"
        aria-controls="basic-menu"
      >
        {steps[current].title}
        <ArrowDropDownIcon fontSize="large" sx={{ marginBottom: -1 }} />
      </StyleTypography>
      <Paper sx={{ width: 340, maxWidth: '100%' }}>
        <StyleMenu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {steps.slice(0, latestStep + 1).map((step, index) => (
            <MenuItem key={index} onClick={() => onGoTo(index)}>
              {step.title}
            </MenuItem>
          ))}
        </StyleMenu>
      </Paper>
    </>
  )
}

export default TitleMenu
