const lightTheme = {
  primary: '#F00023',
  primaryLight: '#F00023',
  primaryDark: '#B80012',
  text: '#313131',
  textSecondary: '#535F78',
  background: '#FBFCFE',
  backgroundVariant: 'rgba(251,249,249,1)',
  backgroundLightBlue: '#DEE7FA',
  border: 'rgba(58,52,51,0.12)',
  borderLight: 'rgba(58,52,51,0.05)',
  fontFamily: 'SF ProDisplay',

  // more color detail
  colorErrorRed: '#F00023',
  colorGreen: '#1EAD54',
  colorOffBlue: '#535F78',
}

export type Theme = typeof lightTheme

export const themes = {
  light: lightTheme,
  //add theme if customer like
}
