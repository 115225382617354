import { AxiosResponse } from 'axios'
import { MutationFunction } from 'react-query'
import { GetPresignDto, UploadUrlResponse } from '../../store/types'
import { publicRequest, request } from '../request'

export type UploadDto = {
  url: string
  pathname: string
  file: any
}

export type GetPresignUrlDto = GetPresignDto & { file: File }

export type MediaResponses = {
  getUploadUrl: AxiosResponse<UploadUrlResponse>
  upload: AxiosResponse<void>
}

type MediaEndpoints = {
  getUploadUrl: MutationFunction<
    MediaResponses['getUploadUrl'],
    GetPresignUrlDto
  >
  upload: MutationFunction<MediaResponses['upload'], UploadDto>
  getImage?: any
  getSignedUrl?: any
}

export const Medias: MediaEndpoints = {
  getUploadUrl: ({ file: _use_later, ...payload }) =>
    request.post(`/media/get-presigned-url`, payload),
  upload: ({ url, file }) =>
    publicRequest.put(url, file, {
      headers: { 'Content-Type': file.type },
      timeout: 10000,
    }),
  getImage: (url: string) => request.get(url),
  getSignedUrl: ({ fileName }) => {
    fileName = fileName.replace('/document/', '')
    return request.post(`/media/get-signed-url`, { fileName })
  },
}
