import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IStepStore } from '.'
import { PaymentMethod } from './interface'
import { createRenderAdminApprovalFlow } from './values'

export const initialStateAdminApprovalFlowStep: IStepStore = {
  current: 0,
  latestStep: 0,
  requestingGoTo: undefined,
  requestingSave: undefined,
  bankInfoCanBeSaved: undefined,
  requestBankInfo: undefined,
  requestingSaveBankInfo: undefined,
  public_key: '',
  canSave: undefined,
  paymentType: 'bankAccount',
}
const STEP_ADMIN_APPROVAL = createRenderAdminApprovalFlow().length

const adminApprovalFlowStepsSlice = createSlice({
  name: 'adminApprovalFlowSteps',
  initialState: initialStateAdminApprovalFlowStep,
  reducers: {
    requestNext(state) {
      state.requestingGoTo = state.current + 1
    },
    requestGoTo(state, { payload: to }: PayloadAction<number>) {
      if (to <= state.current) {
        state.current = to
        return
      }
      state.requestingGoTo = to
    },
    updatepaymentType(
      state,
      { payload: paymentType }: PayloadAction<PaymentMethod>,
    ) {
      state.paymentType = paymentType
    },
    answerGoTo(state, { payload: canGoForth }: PayloadAction<boolean>) {
      if (canGoForth && state.current < STEP_ADMIN_APPROVAL - 1) {
        state.current = state.requestingGoTo
        if (state.current > state.latestStep) {
          state.latestStep = state.current
        }
      }
      state.requestingGoTo = undefined
    },
    nextStep(state) {
      state.current += 1
    },
    backStep(state) {
      if (state.current > 0) {
        state.current -= 1
      }
    },
    requestSave(state, { payload: requestingSave }: PayloadAction<boolean>) {
      state.requestingSave = requestingSave
    },
    answerSave(state, { payload: canSave }: PayloadAction<boolean>) {
      state.canSave = canSave
      state.requestingSave = undefined
    },
    editGoTo(state, { payload: step }: PayloadAction<number>) {
      state.current = step
      state.latestStep = STEP_ADMIN_APPROVAL - 1
    },
    exitStep(state) {
      state.current = 0
      state.latestStep = 0
      state.paymentType = 'bankAccount'
    },
    setPublicKey: (state, action: PayloadAction<string>) => {
      state.public_key = action.payload
    },
  },
})

export const {
  actions: stepActions,
  reducer: adminApprovalFlowStepsReducer,
  name,
} = adminApprovalFlowStepsSlice
export default adminApprovalFlowStepsReducer
export type AdminApprovalStepsActionsType = typeof stepActions

export const useStepAdminApprovalFlowSlice = () => {
  return { actions: stepActions }
}
