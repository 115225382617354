/* eslint-disable no-console */
import { Grid, useMediaQuery, useTheme } from '@mui/material'
import { addYears, format, parseISO } from 'date-fns'
import { default as React, FC, useMemo, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { useContractByTokenQuery } from '../../../../../apis/queries/contracts'
import { useAppSelector } from '../../../../../store/hooks'
import { CustomerFlowProps } from '../../../../../store/slices/step/interface'
import { CalculateUtil } from '../../../../../utils/calculator'
import TrailerBuyoutCustomer from '../../components/TrailerBuyoutCustomer'

const scrollToRef = ref => {
  ref?.current?.scrollIntoView()
}
export const Step5CustomerFlow: FC<CustomerFlowProps> = ({
  actions,
  state,
}) => {
  const myRef = useRef(null)
  React.useEffect(() => {
    scrollToRef(myRef)
  }, [])
  const configs = useAppSelector(state => state.configs)
  // const contract = useAppSelector(app => app.customerFlowSteps.contract)
  const { id } = useParams<{ id?: string }>()
  const { data } = useContractByTokenQuery(id, { enabled: false })
  const {
    paymentType,
    retailPrice,
    startDate: startDateString,
    registrationFee,
    topupAmount,
    overrideRentAmount,
  } = data?.data?.contract || {
    retailPrice: 0,
    paymentType: 'WEEKLY',
    registrationFee: 0,
    startDate: new Date().toISOString(),
  }
  const { interestRate } = configs
  const startD = parseISO(startDateString)
  const start = format(startD, 'dd/MM/yyyy')
  const end = format(addYears(startD, 1), 'dd/MM/yyyy')
  const schedule = useMemo(() => {
    const calculator = new CalculateUtil({ ...configs, interestRate })
    return calculator.getSchedule({
      retailPrice,
      registrationFee,
      interestRate,
    })
  }, [configs, interestRate, registrationFee, retailPrice])
  const theme = useTheme()
  const matchXs = useMediaQuery(theme.breakpoints.only('xs'))
  const dispatch = useDispatch()
  const { requestingGoTo } = state
  React.useEffect(() => {
    if (!requestingGoTo) {
      return
    }

    dispatch(actions.answerGoTo(true))
  }, [actions, dispatch, requestingGoTo])

  return (
    <Grid container spacing={matchXs ? 4 : 2} sx={{ mt: 0 }} ref={myRef}>
      <TrailerBuyoutCustomer
        buyoutSchedule={{
          schedule,
          start,
          end,
          paymentType,
          topupAmount: topupAmount ?? 0,
          overrideRentAmount,
        }}
      />
    </Grid>
  )
}
