import { Grid } from '@mui/material'
import React from 'react'
import {
  ButtonContained,
  ButtonOutline,
  DialogActionsStyle,
  DialogContentStyle,
  DialogTitleStyle,
  SuccessDialogStyle,
} from './Styles'

const ExitModal: React.FC<{
  open: boolean
  handleClose: () => void
  onExit: () => void
}> = ({ open, handleClose, onExit }) => {
  return (
    <SuccessDialogStyle open={open} onClose={handleClose}>
      <DialogTitleStyle>
        Are you sure you would like to exit the form
      </DialogTitleStyle>
      <DialogContentStyle>
        You will lose all progress you have made on the form
      </DialogContentStyle>
      <DialogActionsStyle disableSpacing={true}>
        <Grid container columnSpacing={{ xs: 1, md: 2 }}>
          <ButtonOutline onClick={onExit}>Exit form</ButtonOutline>
          <ButtonContained onClick={handleClose}>Continue form</ButtonContained>
        </Grid>
      </DialogActionsStyle>
    </SuccessDialogStyle>
  )
}

export default ExitModal
