import { Grid, GridSize, TextFieldProps, Typography } from '@mui/material'
import React, { FC, Fragment, useEffect } from 'react'
import { ControllerProps, useFormContext } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { AdminApprovalFlowProps } from '../../../../../store/slices/step/interface'
import { ApproveContractDto } from '../../../../../store/types'
import { ControlledTextField } from '../../components/ControlledInput'
import SelectState from '../../components/SelectState'
import {
  defaultPostalCodeInputOptional,
  defaultRules,
  defaultRulesOptional,
  nameRulesOptional,
  phoneRule,
  phoneRuleOption,
} from '../../defaultRules'
type IReferenceField = TextFieldProps & {
  name: string
  rules?: ControllerProps['rules']
  md?: boolean | GridSize
}
export type ListReferenceType = {
  name: string
  title: string
  field: IReferenceField[]
  required?: boolean
}
const sameFieldReference: IReferenceField[] = [
  {
    name: 'streetAddress',
    label: 'Street address',
  },
  {
    name: 'homeAddress',
    label: 'Apt, suite, etc (Optional)',
  },
  {
    name: 'city',
    label: 'City',
    md: 4,
  },
  {
    name: 'state',
    md: 4,
  },
  {
    name: 'postCode',
    md: 4,
    ...defaultPostalCodeInputOptional,
  },
]
const arrFieldReference: IReferenceField[] = [
  {
    name: 'firstName',
    rules: nameRulesOptional,
    label: 'First name',
    md: 6,
  },
  {
    name: 'lastName',
    rules: nameRulesOptional,
    label: 'Last name',
    md: 6,
  },
  {
    name: 'phone',
    label: 'Phone Number',
    ...phoneRuleOption,
  },
  {
    name: 'relationship',
    label: 'Relationship to applicant',
  },

  ...sameFieldReference,
]
const arrFieldEmployee: IReferenceField[] = [
  {
    name: 'businessName',
    rules: defaultRules,
    label: 'Business name',
    md: 6,
  },
  {
    name: 'contactPerson',
    rules: defaultRules,
    label: 'Contact person',
    md: 6,
  },
  {
    name: 'phone',
    ...phoneRule,
  },
  ...sameFieldReference,
]

export const defaultListReference: ListReferenceType[] = [
  {
    name: 'customer.references.0',
    title: '1st Family member of applicants',
    field: arrFieldReference,
    required: true,
  },
  {
    name: 'customer.references.1',
    title: '2nd Family member of applicants (Optional)',
    field: arrFieldReference,
    required: false,
  },
  {
    name: 'customer.references.2',
    title: '1st Friend of applicants',
    field: arrFieldReference,
    required: true,
  },
  {
    name: 'customer.references.3',
    title: '2nd Friend of applicants (Optional)',
    field: arrFieldReference,
    required: false,
  },
  {
    name: 'customer.references.4',
    title: 'Employer details',
    field: arrFieldEmployee,
    required: true,
  },
]

export const Step5AdminApprovalFlow: FC<AdminApprovalFlowProps> = ({
  actions,
  state,
}) => {
  const dispatch = useDispatch()
  const { requestingGoTo } = state

  const { trigger, watch } = useFormContext<ApproveContractDto>()
  const [listReference, setListReference] = React.useState<ListReferenceType[]>(
    [...defaultListReference],
  )
  const setRequireReference = ({ name, isRequired }) => {
    const newListReference = [...listReference]
    const index = newListReference.findIndex(i => i.name === name)
    newListReference[index].required = isRequired
    setListReference(newListReference)
  }
  useEffect(() => {
    if (!requestingGoTo) {
      return
    }

    const listFieldOptional: any[] = [listReference[1], listReference[3]]

    listFieldOptional.map(field => {
      const everyNameField = field.field.map(i => {
        return `${field.name}.${i.name}`
      })
      const valueEveryField = watch(everyNameField)

      //if all field is fill except homeAddress
      const firstCondition = valueEveryField.every((item, index) =>
        index === 5 ? true : !!item,
      )

      //if no field is fill
      const secondCondition = valueEveryField.every(item => !item)
      const totalCondition = firstCondition || secondCondition
      if (!totalCondition) {
        toast.error(`Please fill all of ${field.title}`)
      }
      setRequireReference({ name: field.name, isRequired: !totalCondition })
      return !totalCondition
    })
    trigger(['customer.references']).then(canGoNext => {
      dispatch(actions.answerGoTo(canGoNext))
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions, dispatch, requestingGoTo, trigger, watch])

  return (
    <>
      <Grid container spacing={2} sx={{ p: 0, pt: 3 }}>
        {listReference.map((mainReference, indexMainReference) => {
          return (
            <Fragment key={mainReference.title}>
              <Grid item xs={12} sx={{ mb: -1 }}>
                <Typography>{mainReference.title}</Typography>
              </Grid>
              {mainReference.field.map((reference, index) => {
                const {
                  md = 12,
                  name,
                  rules = defaultRulesOptional,
                  ...restReference
                } = reference
                const fieldName = `${mainReference.name}.${name}`
                if (name === 'state') {
                  return (
                    <Grid item xs={12} md={md} sx={{ pt: 0 }} key={index}>
                      <SelectState
                        name={fieldName}
                        required={mainReference?.required ?? false}
                      />
                    </Grid>
                  )
                }
                return (
                  <Grid item xs={12} md={md} sx={{ pt: 0 }} key={index}>
                    <ControlledTextField
                      name={fieldName}
                      autoFocus={indexMainReference === 0 && index === 0}
                      rules={
                        mainReference?.required &&
                        reference.name !== 'homeAddress'
                          ? { required: mainReference?.required, ...rules }
                          : { required: false, ...rules }
                      }
                      {...restReference}
                    />
                  </Grid>
                )
              })}
            </Fragment>
          )
        })}
      </Grid>
    </>
  )
}
