import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict'
import { useMemo } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { API, ErrorResponse } from '../../apis'
import {
  ContractsQueryKeys,
  ContractsResponses,
} from '../../apis/endpoints/contracts'
import {
  PaymentQueryKeys,
  PaymentResponses,
} from '../../apis/endpoints/payment'
import { useGetPaginationInfo } from '../../app/components/Dashboard'
import commonConstant from '../../constants/common.constant'
import { useAppSelector } from '../../store/hooks'
import {
  ContractResponse,
  GetPaymentsContractDto,
  IdParam,
  PaymentSuspenseDto,
  UpdateInterestRate,
} from '../../store/types'
import { CalculateUtil } from '../../utils/calculator'
import { onError } from '../../utils/helperFunction'
import { EstimateBuyoutDto } from './../../store/types/models'

export const useGetPaymentsContract = (contractId: number) => {
  const { page, itemsPerPage, search, type, status } = useGetPaginationInfo()
  return useQuery<
    ContractsResponses['getPaymentsContract'],
    ErrorResponse,
    ContractsResponses['getPaymentsContract'],
    [string, GetPaymentsContractDto & IdParam]
  >(
    [
      ContractsQueryKeys.getPaymentsContract,
      {
        limit: itemsPerPage,
        offset: page * itemsPerPage,
        id: contractId,
        search,
        type,
        status,
      },
    ],
    API.Contracts.getPaymentsContract,
    {
      retry: false,
      keepPreviousData: true,
    },
  )
}

export const useGetSuspenseAccountBalance = (contractId: number) => {
  return useQuery<
    PaymentResponses['getSuspenseAccountBalance'],
    ErrorResponse,
    PaymentResponses['getSuspenseAccountBalance'],
    [string, IdParam]
  >(
    [
      PaymentQueryKeys.getSuspenseAccountBalance,
      {
        id: contractId,
      },
    ],
    API.Payment.getSuspenseAccountBalance,
    {
      retry: false,
      keepPreviousData: true,
    },
  )
}

export const useGetCalculationBuyout = (
  contractId: number,
  interestRate: number,
) => {
  return useQuery<
    ContractsResponses['getCalculationBuyout'],
    ErrorResponse,
    ContractsResponses['getCalculationBuyout'],
    [string, IdParam & UpdateInterestRate]
  >(
    [
      ContractsQueryKeys.getCalculationBuyout,
      {
        id: contractId,
        interestRate,
      },
    ],
    API.Contracts.getCalculationBuyout,
    {
      enabled: !!contractId && !!interestRate,
      staleTime: commonConstant.STALE_TIME.SEC_20,
    },
  )
}

export const useGetEstimateBuyout = (payload: EstimateBuyoutDto) => {
  return useQuery<
    ContractsResponses['estimateBuyout'],
    ErrorResponse,
    ContractsResponses['estimateBuyout'],
    [string, EstimateBuyoutDto]
  >(
    [ContractsQueryKeys.getEstimateBuyout, payload],
    API.Contracts.getEstimateBuyout,
    {
      enabled: !!payload.registrationFee && !!payload.retailPrice,
      retry: false,
    },
  )
}

export const useInitiatePayment = () => {
  const queryClient = useQueryClient()
  return useMutation<
    ContractsResponses['activatePayment'],
    ErrorResponse,
    IdParam
  >(API.Contracts.activatePayment, {
    onError,
    onSuccess: () => {
      toast('Initiate payment successfully')
      queryClient.invalidateQueries('getById')
      queryClient.invalidateQueries({
        queryKey: [PaymentQueryKeys.getSuspenseAccountBalance],
      })
      queryClient.invalidateQueries({
        queryKey: [ContractsQueryKeys.getPaymentsContract],
      })
      queryClient.invalidateQueries({
        queryKey: [ContractsQueryKeys.getCalculationBuyout],
      })
    },
  })
}

export const useCommencePaymentSchedule = () => {
  const queryClient = useQueryClient()
  return useMutation<
    ContractsResponses['commencePaymentSchedule'],
    ErrorResponse,
    IdParam & { startDate?: number }
  >(API.Contracts.commencePaymentSchedule, {
    onError,
    onSuccess: (_, { startDate }) => {
      toast(
        startDate
          ? 'Commence Payment Schedule successfully'
          : 'Resume Payment successfully',
      )
      queryClient.invalidateQueries('getById')
      queryClient.invalidateQueries({
        queryKey: [PaymentQueryKeys.getSuspenseAccountBalance],
      })
      queryClient.invalidateQueries({
        queryKey: [ContractsQueryKeys.getPaymentsContract],
      })
      queryClient.invalidateQueries({
        queryKey: [ContractsQueryKeys.getCalculationBuyout],
      })
    },
  })
}

export const useCancelContract = () => {
  const queryClient = useQueryClient()
  return useMutation<
    ContractsResponses['cancelContract'],
    ErrorResponse,
    IdParam & { paymentMethodId?: string; isCashRefund?: boolean }
  >(API.Contracts.cancelContract, {
    onError,
    onSuccess: () => {
      toast('Contract cancelled successfully')
      queryClient.invalidateQueries('getById')
      queryClient.invalidateQueries({
        queryKey: [PaymentQueryKeys.getSuspenseAccountBalance],
      })
      queryClient.invalidateQueries({
        queryKey: [ContractsQueryKeys.getPaymentsContract],
      })
      queryClient.invalidateQueries({
        queryKey: [ContractsQueryKeys.getCalculationBuyout],
      })
    },
  })
}

export const useRetryPayment = () => {
  const queryClient = useQueryClient()
  return useMutation<
    ContractsResponses['retryPayment'],
    ErrorResponse,
    IdParam & { paymentId: string }
  >(API.Contracts.retryPayment, {
    onError,
    onSuccess: () => {
      toast('Retried payment successfully')
      queryClient.invalidateQueries('getById')
      queryClient.invalidateQueries({
        queryKey: [PaymentQueryKeys.getSuspenseAccountBalance],
      })
      queryClient.invalidateQueries({
        queryKey: [ContractsQueryKeys.getPaymentsContract],
      })
      queryClient.invalidateQueries({
        queryKey: [ContractsQueryKeys.getCalculationBuyout],
      })
    },
  })
}

export const useBuyout = () => {
  const queryClient = useQueryClient()
  return useMutation<
    ContractsResponses['buyout'],
    ErrorResponse,
    IdParam & { paymentMethodId?: string }
  >(API.Contracts.buyout, {
    onError,
    onSuccess: () => {
      toast('Buyout successfully')
      queryClient.invalidateQueries('getById')
      queryClient.invalidateQueries({
        queryKey: [PaymentQueryKeys.getSuspenseAccountBalance],
      })
      queryClient.invalidateQueries({
        queryKey: [ContractsQueryKeys.getPaymentsContract],
      })
      queryClient.invalidateQueries({
        queryKey: [ContractsQueryKeys.getCalculationBuyout],
      })
    },
  })
}

export const usePausePayment = () => {
  const queryClient = useQueryClient()
  return useMutation<
    ContractsResponses['pausePayment'],
    ErrorResponse,
    IdParam
  >(API.Contracts.pausePayment, {
    onError,
    onSuccess: () => {
      toast('Pause payment successfully')
      queryClient.invalidateQueries('getById')
      queryClient.invalidateQueries({
        queryKey: [PaymentQueryKeys.getSuspenseAccountBalance],
      })
      queryClient.invalidateQueries({
        queryKey: [ContractsQueryKeys.getPaymentsContract],
      })
      queryClient.invalidateQueries({
        queryKey: [ContractsQueryKeys.getCalculationBuyout],
      })
    },
  })
}

export const useGetSchedule = ({
  contract,
}: {
  contract: Partial<ContractResponse>
}) => {
  const configs = useAppSelector(state => state?.configs)
  const distanceYear = formatDistanceToNowStrict(
    new Date(contract?.paymentCommencedDate),
    {
      unit: 'year',
    },
  ).replace(/\D/g, '')
  const distanceYearConvert =
    parseInt(distanceYear) > 4 ? 4 : parseInt(distanceYear)
  const schedule = useMemo(() => {
    const calculator = new CalculateUtil({
      ...configs,
      interestRate: contract?.interestRate,
    })
    return calculator.getSchedule({
      retailPrice: contract?.retailPrice,
      registrationFee: contract?.registrationFee,
      interestRate: contract?.interestRate,
    })
  }, [
    configs,
    contract?.interestRate,
    contract?.registrationFee,
    contract?.retailPrice,
  ])
  return { schedule, distanceYearConvert }
}

export const useAddNewPayment = () => {
  const queryClient = useQueryClient()
  return useMutation<
    PaymentResponses['addNewPayment'],
    ErrorResponse,
    Omit<PaymentSuspenseDto, 'invoiceId'>
  >(API.Payment.addNewPayment, {
    onSuccess: () => {
      queryClient.invalidateQueries('getById')
      queryClient.invalidateQueries({
        queryKey: [PaymentQueryKeys.getSuspenseAccountBalance],
      })
      queryClient.invalidateQueries({
        queryKey: [ContractsQueryKeys.getPaymentsContract],
      })
      queryClient.invalidateQueries({
        queryKey: [ContractsQueryKeys.getCalculationBuyout],
      })
    },
    onError,
  })
}

export type AddGlobalCashPaymentPayload = Omit<
  PaymentSuspenseDto,
  'paymentMethodId'
>

export const useAddGlobalCashPayment = () => {
  const queryClient = useQueryClient()
  return useMutation<
    PaymentResponses['addCashPayment'],
    ErrorResponse,
    AddGlobalCashPaymentPayload
  >(API.Payment.addCashPayment, {
    onSuccess: () => {
      queryClient.invalidateQueries('getById')
      queryClient.invalidateQueries({
        queryKey: [PaymentQueryKeys.getSuspenseAccountBalance],
      })
      queryClient.invalidateQueries({
        queryKey: [ContractsQueryKeys.getPaymentsContract],
      })
      queryClient.invalidateQueries({
        queryKey: [ContractsQueryKeys.getCalculationBuyout],
      })
    },
    onError,
  })
}
