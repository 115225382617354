import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextFieldProps,
} from '@mui/material'
import React from 'react'
import { Controller, get, useFormContext } from 'react-hook-form'

type Props = TextFieldProps & {
  name?: string
  required?: boolean
}

const SelectGender: React.FC<Props> = ({
  name = 'gender',
  required = true,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: required === true ? true : false }}
      render={({ field: { value, onChange, onBlur } }) => {
        return (
          <>
            <FormControl
              fullWidth
              sx={{ marginTop: 2 }}
              error={!!get(errors, name)}
            >
              <InputLabel id="demo-simple-select-label">Gender</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Gender"
                {...{ value: value || '', onChange, onBlur }}
              >
                {GENDER_VALUES.map(item => (
                  <MenuItem key={item.title} value={item.value}>
                    {item.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        )
      }}
    />
  )
}

export default SelectGender

const GENDER_VALUES = [
  { value: 'M', title: 'Male' },
  { value: 'F', title: 'Female' },
  { value: 'U', title: 'Other' },
]
