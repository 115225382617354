import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  styled,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React from 'react'

export const StyleCardHeader = styled(CardHeader)(() => ({
  padding: '12px 17px',
}))

export const StyleCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.08)',
  border: '1px solid',
  paddingLeft: '3px',
  paddingRight: '3px',
  borderRadius: '8px',
  borderColor: theme.palette.secondary.light,
}))

export const StyleCardContent = styled(CardContent)(() => ({
  paddingTop: 12,
  '&:last-child': {
    paddingBottom: 15,
  },
}))

export const Item: React.FC<{ title: string; value: string }> = ({
  title,
  value,
}) => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <Grid container>
      <Grid item xs={12} lg={6}>
        <Typography
          variant="body1"
          sx={{
            fontWeight: '500',
            letterSpacing: 1.1,
          }}
        >
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12} lg={6}>
        <AmountText
          sx={{
            textAlign: matches ? 'left' : 'right',
            marginBottom: matches ? 2 : 0,
          }}
        >
          {value}
        </AmountText>
      </Grid>
    </Grid>
  )
}
export const AmountText = styled('div')(({ theme }) => ({
  color: theme.palette.text.secondary,
}))
export const StyleTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-input': {
    color: theme.palette.success.main,
    fontSize: 24,
    textAlign: 'center',
    fontWeight: 'bold',
    padding: 10,
  },
  '& .MuiOutlinedInput-notchedOutline legend': {
    textAlign: 'center',
    maxWidth: '100%',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    left: '51%',
    transform: 'translate(-50%, -9px) scale(0.75)',
  },
  '& .MuiInputLabel-root': {
    left: '51%',
    transform: 'translate(-50%, -9px) scale(0.75)',
  },
}))
