import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Box, TextField, Typography } from '@mui/material'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { API, ErrorResponse } from '../../../apis'
import { AuthResponses } from '../../../apis/endpoints/auth'
import { ResetPasswordDto } from '../../../store/types'
import { routerAuth } from '../../../utils/routerPath'
import { onError } from './../../../utils/helperFunction'
import { Button, ButtonExit as ButtonSmall } from './../../components/Button'

type FormValues = {
  email: string
}

const ForgotPassword = () => {
  const history = useHistory()
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      email: '',
    },
  })

  const handleLogin = handleSubmit(forgotPasswordDto => {
    mutate({ email: forgotPasswordDto.email.toLowerCase() })
  })
  const onBack = () => history.goBack()

  const { mutate, isLoading } = useMutation<
    AuthResponses['resetPassword'],
    ErrorResponse,
    ResetPasswordDto
  >(API.Auth.resetPassword, {
    onSuccess: () => {
      toast(
        'Instructions to reset your password have been sent to you. Please Check your email.',
        { position: 'top-right' },
      )
      history.push(routerAuth.login)
    },
    onError,
  })

  return (
    <>
      <ButtonExit onClick={onBack}>
        <ArrowBackIcon />
        <SpanBack>Back</SpanBack>
      </ButtonExit>

      <form onSubmit={handleLogin}>
        <Typography variant="h2" textAlign="left">
          Forgot password
        </Typography>
        <Box component="div" sx={{ mt: 1 }}>
          <NoteParagraph>
            Enter the email address you used to sign up and we’ll send you
            instructions to reset your password.{' '}
          </NoteParagraph>

          <Controller
            control={control}
            name="email"
            rules={{
              required: 'Required',
              pattern: {
                value:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: 'Invalid email',
              },
            }}
            render={({ field: { value, onChange, onBlur } }) => (
              <TextField
                {...{ value, onChange, onBlur }}
                error={!!errors.email}
                margin="normal"
                fullWidth
                label="Email"
                autoComplete="email"
                autoFocus
              />
            )}
          />

          <Button
            type="submit"
            variant="contained"
            sx={{ mt: 3, minWidth: 41 }}
            loading={isLoading}
          >
            Send Reset Instructions
          </Button>
        </Box>
      </form>
    </>
  )
}

export default ForgotPassword

const ButtonExit = styled(ButtonSmall)`
  position: absolute;
  top: 28px;
  left: 48px;
  display: flex;
  align-items: center;
`

const NoteParagraph = styled.div`
  width: 80%;
  font-size: 1rem;
  line-height: 1.2rem;
  color: ${p => p.theme.text};
  mix-blend-mode: normal;
  opacity: 0.8;
  margin-bottom: 1rem;
  margin-top: 24px;
`
const SpanBack = styled.span`
  margin-left: 5px;
  vertical-align: middle;
`
