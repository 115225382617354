import { Grid } from '@mui/material'
import React, { FC, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { StepProps } from '../../../../store/slices/step/interface'
import { CreateContractDto } from '../../../../store/types'
import { ControlledTextField } from '../components/ControlledInput'
import { defaultEmailRules, nameRules } from './../defaultRules'

export const Step0: FC<StepProps> = ({ actions, state }) => {
  const dispatch = useDispatch()
  const { requestingGoTo, requestingSave } = state

  const { trigger } = useFormContext<CreateContractDto>()
  useEffect(() => {
    if (!requestingGoTo) {
      return
    }
    trigger([
      'customer.emailAddress',
      'customer.firstName',
      'customer.middleName',
      'customer.lastName',
    ]).then(canGoNext => {
      dispatch(actions.answerGoTo(canGoNext))
    })
  }, [actions, dispatch, requestingGoTo, requestingSave, trigger])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <ControlledTextField
          name="customer.firstName"
          rules={nameRules}
          label="First Name"
          autoFocus
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <ControlledTextField
          rules={nameRules}
          name="customer.middleName"
          label="Middle Name"
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <ControlledTextField
          rules={nameRules}
          name="customer.lastName"
          label="Last Name"
        />
      </Grid>

      <Grid item xs={12} md={6}></Grid>

      <Grid item xs={12}>
        <ControlledTextField
          name="customer.emailAddress"
          rules={defaultEmailRules}
          label="Email"
        />
      </Grid>
    </Grid>
  )
}
