import {
  Divider,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { addYears, format } from 'date-fns'
import React, { FC, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { useLocation, useParams, useRouteMatch } from 'react-router'
import { API, ErrorResponse } from '../../../../../apis'
import {
  ContractsQueryKeys,
  ContractsResponses,
} from '../../../../../apis/endpoints/contracts'
import { useGetEstimateBuyout } from '../../../../../hooks/payments/usePayment'
import { useAppSelector } from '../../../../../store/hooks'
import {
  ApproveContractDto,
  BuyoutSchedule,
  ContractResponse,
  IdParam,
} from '../../../../../store/types'
import { CalculateUtil } from '../../../../../utils/calculator'
import { capitalizeString } from '../../../../../utils/format.util'
import { onError } from '../../../../../utils/helperFunction'
import CurrencyInput from '../../../../components/Input/CurrencyInput'
import TargetRateInput from '../../../../components/Input/TargetRateInput'
import { ControlledTextField } from '../../components/ControlledInput'
import {
  StyleCard,
  StyleCardContent,
  StyleCardHeader,
  StyleTextField,
} from '../../components/styleTrailerBuyOut'
import TrailerBuyoutCustomer from '../../components/TrailerBuyoutCustomer'

type MainBuyoutSchedule = BuyoutSchedule & { retailValue: number }
type ContractResponseWithId = ContractsResponses['getById']

export const Step8AdminApprovalFlow: FC<any> = ({ actions, state }) => {
  // Important note:
  // When the provided pathname pattern fits, this should return
  //  some data with the `isExact` property set to true,
  //  otherwise, this returns `null`.
  const adminManual = useRouteMatch('/contract/admin-manual/:contractId')
  const adminApproval = useRouteMatch('/contract/admin-approval/:contractId')

  const { pathname } = useLocation()
  const { contractId } = useParams<{ contractId: string }>()

  const id: IdParam = { id: parseInt(contractId) }

  // Important note:
  // The following are pathnames that denotes the contract creation process.
  // The said pathnames should not render the fetched contract data saved on the database.
  const isContractCreation =
    pathname.includes('/contract/add') ||
    pathname.includes('/contract/admin-manual') ||
    pathname.includes('/contract/admin-approval')

  const contractQuery = useQuery<
    ContractResponseWithId,
    ErrorResponse,
    ContractResponseWithId,
    [string, IdParam]
  >([ContractsQueryKeys.getById, id], API.Contracts.getById, {
    retry: false,
    enabled: !isContractCreation, // Disable the query during contract creation.
    onError: value => onError(value, null, null, 'top-center'),
  })

  const {
    watch,
    control,
    getValues,
    trigger,
    formState: { errors },
  } = useFormContext<ApproveContractDto>()
  const dispatch = useDispatch()

  const { requestingGoTo } = state
  React.useEffect(() => {
    if (!requestingGoTo) {
      return
    }
    trigger(['interestRate', 'topupAmount']).then(canGoNext =>
      dispatch(actions.answerGoTo(canGoNext)),
    )
  }, [actions, dispatch, requestingGoTo, trigger])
  const configs = useAppSelector(state => state?.configs)
  const formValues = getValues()
  const interestRate = watch('interestRate', 0)
  const registrationFee = watch('registrationFee', 0)
  const retailPrice = watch('retailPrice', 0)
  const startDate = new Date(formValues.startDate)
  const paymentType = formValues.paymentType
  const start = format(startDate, 'dd/MM/yyyy')
  const end = format(addYears(startDate, 1), 'dd/MM/yyyy')
  const theme = useTheme()
  const matchXs = useMediaQuery(theme.breakpoints.only('xs'))
  const schedule = useMemo(() => {
    const calculator = new CalculateUtil({ ...configs, interestRate })
    return calculator.getSchedule({
      retailPrice,
      registrationFee,
      interestRate,
    })
  }, [configs, interestRate, registrationFee, retailPrice])
  const { data: estimateBuyoutsData } = useGetEstimateBuyout({
    registrationFee,
    retailPrice,
    monthlyRent: schedule.firstYear.monthly,
    laterYearMonthlyRent: schedule.laterYear.monthly,
    deposit: schedule.firstPayment,
  })

  function getContractData(): ContractResponse {
    if (contractQuery.isError) {
      throw new Error('Error when geting contract data')
    }

    if (contractQuery.data?.data) {
      return contractQuery.data?.data
    }

    return null
  }

  function getBuyoutSchedule(): MainBuyoutSchedule {
    const isAdminFlow = [!adminManual?.isExact, !adminApproval?.isExact].some(
      match => match === true,
    )

    // Important note:
    // When any of the admin workflows are accessed by the user
    //  and when any of the contract creation workflows are accessed by the user...
    if (isAdminFlow && isContractCreation) {
      return null
    }

    const { buyoutSchedule } = getContractData()

    if (buyoutSchedule) {
      return { ...buyoutSchedule, retailValue: registrationFee + retailPrice }
    }

    return null
  }

  const scheduleData = getBuyoutSchedule() || schedule

  return (
    <div>
      <Grid container spacing={2} sx={{ mt: 0 }}>
        <Grid item xs={12}>
          <Controller
            name="interestRate"
            control={control}
            rules={{
              required: 'Required',
              min: { value: 0.01, message: 'Must be greater than 0' },
              max: 1,
            }}
            render={({ field: { value, onChange, onBlur } }) => (
              <StyleTextField
                {...{ value, onChange, onBlur }}
                error={!!errors['interestRate']}
                // @ts-ignore
                variant="outlined"
                autoFocus={true}
                margin="normal"
                label="Target rate"
                fullWidth
                name="interestRate"
                autoComplete={'interestRate'}
                InputProps={{ inputComponent: TargetRateInput }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} marginBottom={1}>
          <StyleCard>
            <StyleCardHeader
              titleTypographyProps={{ fontSize: matchXs ? '1.1rem' : '1.2rem' }}
              title="Top up amount"
            />
            <Divider variant="fullWidth" />
            <StyleCardContent>
              <Grid container>
                <Grid item xs={12} lg={6}>
                  <Stack justifyContent="center" sx={{ height: '100%' }}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: '500',
                        letterSpacing: 1.1,
                      }}
                    >
                      {capitalizeString(paymentType)} top up amount
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <ControlledTextField
                    name="topupAmount"
                    rules={{
                      required: true,
                      maxLength: 250,
                      validate: {
                        positiveNumber: value => value >= 0 || false,
                      },
                    }}
                    InputProps={{ inputComponent: CurrencyInput }}
                  />
                </Grid>
              </Grid>
            </StyleCardContent>
          </StyleCard>
        </Grid>
        <TrailerBuyoutCustomer
          buyoutSchedule={{
            schedule: scheduleData,
            start,
            end,
            paymentType,
            overrideRentAmount: contractQuery.data?.data?.overrideRentAmount,
          }}
          estimateBuyouts={estimateBuyoutsData?.data ?? []}
          showTopUpAmount={false}
        />
      </Grid>
    </div>
  )
}
