import { Box, Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'

const useStyles = makeStyles({
  textValue: {
    fontSize: '36px',
    lineHeight: '43px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  textTitle: {
    fontSize: '14px',
    lineHeight: '17px',
    fontStyle: 'normal',
    fontWeight: 600,
    color: '#313131',
    textAlign: 'center',
  },
  container: {
    width: '133px',
    height: '129px',
    display: 'flex',
    backgroundColor: '#EBF0FF',
    borderRadius: '7px',
    opacity: 0.8,
    padding: '0 20px',
  },
})

interface IProps {
  value: number | string
  title: string
  isBoolean: boolean | undefined
}

export default function ItemCredit(props: IProps) {
  const classes = useStyles()
  return (
    <Grid container direction="row" className={classes.container}>
      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        justifyContent="center"
        alignItems="flex-end"
        sx={{ height: '50%', display: 'flex' }}
      >
        <Box>
          <Typography
            className={classes.textValue}
            sx={{
              color: `${
                props.isBoolean
                  ? '#1EAD54'
                  : typeof props.isBoolean === 'boolean'
                  ? '#F00023'
                  : '#313131'
              }`,
            }}
          >
            {props.value}
          </Typography>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        justifyContent="center"
        alignItems="center"
        sx={{ height: '50%', display: 'flex' }}
      >
        <Box>
          <Typography className={classes.textTitle}>{props.title}</Typography>
        </Box>
      </Grid>
    </Grid>
  )
}
