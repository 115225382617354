import { Tab, Tabs, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import React from 'react'

export const AntTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    backgroundColor: 'transparent',
    display: 'none',
  },
  '& .MuiTabs-flexContainer': {
    border: '1px solid black',
    width: 'fit-content',
    padding: '2px 3px',
    borderRadius: 4,
    margin: '22px auto 17px auto',
  },
})

interface StyledTabProps {
  label: string
}
export const AntTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: 'none',
  width: 129,
  minHeight: 29,
  borderRadius: 4,
  padding: 0,
  [theme.breakpoints.up('sm')]: {
    minWidth: 0,
  },
  fontWeight: theme.typography.fontWeightRegular,
  '&:hover': {
    color: theme.palette.primary.dark,
    opacity: 1,
  },
  '&.Mui-selected': {
    color: 'white',
    backgroundColor: theme.palette.primary.dark,
    fontWeight: theme.typography.fontWeightMedium,
  },
}))
interface TabPanelProps {
  children?: React.ReactNode
  dir?: string
  index: number
  value: number
}

export const TabPanel = (props: TabPanelProps): JSX.Element => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Typography>{children}</Typography>}
    </div>
  )
}
