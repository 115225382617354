import {
  CreateContractDto,
  CreateReferenceDto,
  ExpiryAdminApprovalDto,
  ExpiryAdminManualDto,
  ExpiryManualCustomerDto,
  ExtCreateCreditCardDto,
  FulfillCustomerDto,
} from '../../../store/types'

export const dummieMember: Omit<CreateReferenceDto, 'referencesType'> = {
  firstName: 'string',
  lastName: 'string',
  middleName: 'string',
  driversLicense: 'string',
  relationship: 'string',
  streetAddress: '10 street',
  homeAddress: 'string',
  city: 'string',
  state: 'Victoria',
  postCode: '1234',
  contactPerson: 'string',
  phone: '04 3654 6545',
  businessName: 'string',
}
export const dummieCreditCardInfo: ExtCreateCreditCardDto = {
  cardName: 'string',
  cardNumber: '4242424242424242',

  exp_month: 12,
  exp_year: 22,
  expiry: '12/22',
  cvv: '123',
}
export const dummieBankAccountInfo = {
  bankAccountName: '',
  bankAccountNo: '',
  bsb: '',
  paymentMethod: '',
  setupIntentId: '',
}
export const CreateProductDto = {
  trailerType: 'string',
  modelNumber: 'string',
  serialNumber: 'string',
  contractNumber: 'string',
  trNumber: 'string',
  trailerLength: 'string',
  trailerWidth: 'string',

  /** @min 0 */
  cost: 110,
}
const ASSETS_HOST = process.env.REACT_APP_ASSETS_HOST

export const dummieValuesCustomerFlow: FulfillCustomerDto = {
  homeAddress: 'string',
  businessName: 'string',
  businessABN: '12345678911',
  businessPhone: '04 3654 6545',
  businessEmail: 'string@string.com',
  businessStreetAddress: 'string',
  businessAddress: 'string',
  businessCity: 'string',
  businessState: 'Victoria',
  businessPostCode: '1234',
  bankAccountInfo: {
    bankAccountName: 'string',
    bankAccountNo: '123456789',
    bsb: '012312',
    paymentMethod: 'string',
    setupIntentId: 'string',
  },
  creditCardInfo: dummieCreditCardInfo,
  references: [
    { ...dummieMember, referencesType: 'MEMBER_FAMILY_ONE' },
    { ...dummieMember, referencesType: 'MEMBER_FAMILY_TWO' },
    { ...dummieMember, referencesType: 'FRIEND_ONE' },
    { ...dummieMember, referencesType: 'FRIEND_TWO' },
    { ...dummieMember, referencesType: 'EMPLOYER' },
  ],
  documents: [
    {
      docType: 'DRIVER_LICENSE_FRONT',
      docUrl: ASSETS_HOST + '/document/KqsnJPwuzDHaaYceDD4La_lab%201-erd.pdf',
    },
    {
      docType: 'DRIVER_LICENSE_BACK',
      docUrl: ASSETS_HOST + '/document/KqsnJPwuzDHaaYceDD4La_lab%201-erd.pdf',
    },
    {
      docType: 'MOTOR_VEHICLE_REGO',
      docUrl: ASSETS_HOST + '/document/KqsnJPwuzDHaaYceDD4La_lab%201-erd.pdf',
    },
    {
      docType: 'BANK_ACCOUNT',
      docUrl: ASSETS_HOST + '/document/KqsnJPwuzDHaaYceDD4La_lab%201-erd.pdf',
    },
    {
      docType: 'CREDIT_CARD',
      docUrl: ASSETS_HOST + '/document/KqsnJPwuzDHaaYceDD4La_lab%201-erd.pdf',
    },
    {
      docType: 'MEDICARE_CARD',
      docUrl: ASSETS_HOST + '/document/KqsnJPwuzDHaaYceDD4La_lab%201-erd.pdf',
    },
  ],
  streetAddress: '10 street',
  city: 'string',
  state: 'Victoria',
  postCode: '1234',
  phone: '04 3654 6545',
  emailAddress: '',
  firstName: '',
  middleName: '',
  driversLicense: '',
  lastName: '',
  dateOfBirth: new Date(),
  gender: 'U',
}

export const createContractDto: Omit<CreateContractDto, 'startDate'> & {
  startDate: Date
} = {
  customer: {
    firstName: 'string',
    middleName: 'string',
    lastName: 'string',
    emailAddress: 'string@string.com',
  },

  /** @format date-time */
  startDate: new Date(),
  paymentType: 'FORTNIGHT',

  /** @min 0 */
  retailPrice: 123,
  interestRate: 0.5,
  registrationFee: 100,
  product: {
    trailerType: 'string',
    modelNumber: 'string',
    serialNumber: 'string',
    contractNumber: 'string',
    trNumber: 'string',
    trailerLength: 'string',
    trailerWidth: 'string',
    /** @min 0 */
    cost: 123,
  },
}

const dummieManualCustomerDto: ExpiryManualCustomerDto = {
  phone: '04 3654 6545',
  streetAddress: '10 street',
  homeAddress: 'string',
  city: 'string',
  state: 'Victoria',
  postCode: '1234',
  businessName: 'string',
  businessABN: '12345678912',
  businessPhone: '04 3654 6545',
  businessEmail: 'string@sdf.cop',
  businessStreetAddress: 'string',
  businessAddress: 'string',
  businessCity: 'string',
  businessState: 'Victoria',
  businessPostCode: '1234',
  bankAccountInfo: dummieBankAccountInfo,
  creditCardInfo: dummieCreditCardInfo,
  references: [
    { ...dummieMember, referencesType: 'MEMBER_FAMILY_ONE' },
    { ...dummieMember, referencesType: 'MEMBER_FAMILY_TWO' },
    { ...dummieMember, referencesType: 'FRIEND_ONE' },
    { ...dummieMember, referencesType: 'FRIEND_TWO' },
    { ...dummieMember, referencesType: 'EMPLOYER' },
  ],
  documents: [
    {
      docType: 'DRIVER_LICENSE_FRONT',
      docUrl: ASSETS_HOST + '/document/KqsnJPwuzDHaaYceDD4La_lab%201-erd.pdf',
    },
    {
      docType: 'DRIVER_LICENSE_BACK',
      docUrl: ASSETS_HOST + '/document/KqsnJPwuzDHaaYceDD4La_lab%201-erd.pdf',
    },
    {
      docType: 'MOTOR_VEHICLE_REGO',
      docUrl: ASSETS_HOST + '/document/KqsnJPwuzDHaaYceDD4La_lab%201-erd.pdf',
    },
    {
      docType: 'BANK_ACCOUNT',
      docUrl: ASSETS_HOST + '/document/KqsnJPwuzDHaaYceDD4La_lab%201-erd.pdf',
    },
    {
      docType: 'CREDIT_CARD',
      docUrl: ASSETS_HOST + '/document/KqsnJPwuzDHaaYceDD4La_lab%201-erd.pdf',
    },
    {
      docType: 'MEDICARE_CARD',
      docUrl: ASSETS_HOST + '/document/KqsnJPwuzDHaaYceDD4La_lab%201-erd.pdf',
    },
  ],
  emailAddress: 'string@ds.cp',
  firstName: 'string',
  middleName: 'string',
  lastName: 'string',
  driversLicense: 'string',
  gender: 'M',
  dateOfBirth: new Date(),
}

export const dummieManualContractDto: ExpiryAdminManualDto = {
  ...createContractDto,
  customer: { ...dummieManualCustomerDto },
}

export const dummieValueEditDetailContract: ExpiryAdminApprovalDto = {
  customer: dummieManualCustomerDto,
  product: { ...CreateProductDto },
  registrationFee: 100,
  startDate: new Date(),
  paymentType: 'WEEKLY',

  retailPrice: 100,
  interestRate: 0.5,
}
