import { AxiosResponse } from 'axios'
import { MutationFunction } from 'react-query'
import {
  AccessTokenResponse,
  ChangePasswordDto,
  LoginDto,
  ResetPasswordDto,
  Verify2FACodeDto,
} from '../../store/types'
import { buildQueryKeys, ResponseType } from '../queryUltils'
import { request } from '../request'

const BASE_URL = '/auth'

type AuthEndpoints = {
  login: MutationFunction<AxiosResponse<AccessTokenResponse>, LoginDto>
  resetPassword: MutationFunction<AxiosResponse<void>, ResetPasswordDto>
  changePassword: MutationFunction<AxiosResponse<void>, ChangePasswordDto>
  verify2FACode: MutationFunction<
    AxiosResponse<AccessTokenResponse>,
    Verify2FACodeDto
  >
}

export const Auth: AuthEndpoints = {
  login: payload => request.post(`${BASE_URL}/login`, payload),
  resetPassword: payload => request.post(`${BASE_URL}/reset-password`, payload),
  changePassword: payload =>
    request.put(`${BASE_URL}/change-password`, payload),
  verify2FACode: payload => request.put(`${BASE_URL}/verify-code`, payload),
}

export const AuthQueryKeys = buildQueryKeys(Auth)
export type AuthResponses = ResponseType<AuthEndpoints>
